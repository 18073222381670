import React from 'react';

export default function Usp() {
	const usp = [
		'Strong foundation of Bharatiya Vidya Bhavan with glorious past and some of the best-known educational institutions as members of the same family',
		'An educational Institute with global outlook but with Indian ethos',
		'State of the art infrastructure',
		'Committed to total development of students',
		'Development of managerial skills (for different functional disciplines) for effective decision making by resorting to globally used technologies',
		'Enhancement of critical thinking as well as lateral thinking',
		'Development of research skills',
		'All round personality development and holistic grooming',
		'Networking through participation in national seminars / conferences',
		'Best of faculty from academic as well as corporate world',
		'World class pedagogy (case study, gaming, simulation, immersion projects) and corporate workshops',
		'Immersion projects / internships',
		'International tour (for those who are interested)',
		'Placement training and support',
	];
	return (
		<section className='mb-5 py-5'>
			{/* <div className='mx-auto' style={{ maxWidth: '800px' }}>
					<ul className='fa-ul'>
						{usp.map((val, index) => {
							return (
								<li className='' key={index}>
									<i className='fas fa-check fa-li text-success'></i>
									<p>{val}</p>
								</li>
							);
						})}
					</ul>
				</div> */}
			<div className='container'>
				<h1 className='text-center mb-5'>Our USP</h1>
				<div
					style={{
						backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),url(${process.env.PUBLIC_URL}/media/home/achievements.jpg)`,
						padding: '5rem 0',
						position: 'relative',
					}}
				>
					<div className='mx-auto' style={{ maxWidth: '90%' }}>
						{/* <p
							className='text-center text-white mx-auto mb-5'
							style={{ maxWidth: '600px' }}
						>
							Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quis
							voluptas veritatis minus. Explicabo neque praesentium tempore
							assumenda iste recusandae sunt, aliquid officia nesciunt minima
							ratione sint asperiores culpa velit in.
						</p> */}
						<section className='open-book'>
							<div className='open-book-inside-padding'>
								<article>
									<ul className='fa-ul'>
										{usp.map((val, index) => {
											if (index < 8) {
												return (
													<li key={index}>
														<i className='fas fa-li fa-check text-success'></i>
														{val}
													</li>
												);
											} else {
												return null;
											}
										})}
									</ul>
									<ul className='fa-ul'>
										{usp.map((val, index) => {
											if (index > 7) {
												return (
													<li key={index}>
														<i className='fas fa-li fa-check text-success'></i>
														{val}
													</li>
												);
											} else {
												return null;
											}
										})}
									</ul>
								</article>
							</div>
						</section>
					</div>
				</div>
			</div>
		</section>
	);
}
