import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Blank from '../Blank';
import UnderConstruction from '../UnderConstruction';
import EActivities from './Enterpreneurship/EActivities';
import EContact from './Enterpreneurship/EContact';
import ECourses from './Enterpreneurship/ECourses';
import EGallery from './Enterpreneurship/EGallery';
import EKnowUs from './Enterpreneurship/EKnowUs';
import Enterpreneurship from './Enterpreneurship/Enterpreneurship';
import FActivities from './FinancialServices/FActivities';
import FContact from './FinancialServices/FContact';
import FCourses from './FinancialServices/FCourses';
import FGallery from './FinancialServices/FGallery';
import FinancialServices from './FinancialServices/FinancialServices';
import FKnowUs from './FinancialServices/FKnowUs';

export default function SchoolRoutes() {
	return (
		<>
			{/* <section className='mb-4' style={{ position: 'relative' }}>
				<img
					//src={`${process.env.PUBLIC_URL}/media/home/campus.png`}
					src="https://kmmias.org/media/home/campus.png"
					alt=''
					style={{
						width: '100%',
						maxHeight: '150px',
						objectFit: 'cover',
						objectPosition: 'center center',
						filter: 'brightness(.3)',
					}}
				/>
				<div
					className='text-center text-white'
					style={{
						position: 'absolute',
						top: '50%',
						left: '50%',
						transform: 'translate(-50%,-50%)',
					}}
				>
					<h1>Schools Of KIMMIAS</h1>
				</div>
			</section> */}
			{/* <section className='mb-5'>
				<div className='container'>
					<nav>
						<ol className='breadcrumb'>
							<li className={`breadcrumb-item text-uppercase`}>
								<Link to={`/`} className='text-info'>
									home
								</Link>
							</li>
							{makeBreadcrumb(location.pathname)}
						</ol>
					</nav>
				</div>
			</section> */}
			<Switch>
				<Redirect exact from='/schools' to='/schools/financial-services/know-us' />
				<Redirect exact from='/schools/financial-services' to='/schools/financial-services/know-us' />
				<Redirect exact from='/schools/entrepreneurship' to='/schools/entrepreneurship/know-us' />
				<Route exact path='/schools/financial-services' component={FinancialServices} />
				<Route exact path='/schools/entrepreneurship' component={Enterpreneurship} />
				<Route exact path='/schools/financial-services/know-us' component={FKnowUs} />
				<Route exact path='/schools/financial-services/courses' component={FCourses} />
				<Route exact path='/schools/financial-services/activities' component={FActivities} />
				<Route exact path='/schools/financial-services/contact' component={FContact} />
				<Route exact path='/schools/financial-services/gallery' component={FGallery} />
				<Route exact path='/schools/entrepreneurship' component={Enterpreneurship} />
				<Route exact path='/schools/entrepreneurship/know-us' component={EKnowUs} />
				<Route exact path='/schools/entrepreneurship/courses' component={ECourses} />
				<Route exact path='/schools/entrepreneurship/activities' component={EActivities} />
				<Route exact path='/schools/entrepreneurship/contact' component={EContact} />
				<Route exact path='/schools/entrepreneurship/gallery' component={EGallery} />
				<Route exact path='/schools/skills' component={Blank} />
				<Route exact path='/schools/finance' component={Blank} />
				<Route exact path='/schools/research' component={Blank} />
				<Route exact path='/schools/compliance-and-risk' component={Blank} />
			</Switch>
		</>
	);
}
