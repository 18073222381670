import React from 'react';
import { Link } from 'react-router-dom';

export default function Footer() {
	const links = [
		{
			label: 'Home',
			link: '/home',
		},
		{
			label: 'About Us',
			link: '/introduction/bvb',
		},
		{
			label: 'Schools',
			link: '/schools/financial-services',
		},
		{
			label: 'Programs',
			link: '/programs/aima_tie_up',
		},
		{
			label: 'Pedagogy',
			link: '/pedagogy/faculty',
		},
		{
			label: 'Resource',
			link: '/resource/infrastructure',
		},
		{
			label: 'Campus',
			link: '/campus/news',
		},
		{
			label: 'Contact Us',
			link: '/contact_us',
		},
		{
			label: 'Credits',
			link: '/credits',
		},
	];
	return (
		<section className='py-5 bg-dark'>
			<div className='container text-white'>
				<div className='row g-2'>
					<div className='col-sm-6 col-md-4'>
						<h6>Quick Links</h6>
						<ul className='list-unstyled'>
							{links.map((link, index) => {
								return (
									<li key={index}>
										<Link className='text-muted' to={`${link.link}`}>
											{link.label}
										</Link>
									</li>
								);
							})}
						</ul>
					</div>
					<div className='col-sm-6 col-md-4'>
						<h6>Contact Us</h6>
						<div className=''>
							<h6>
								<i className='fas fa-envelope me-2'></i>Email:
							</h6>
							<ul className='list-unstyled'>
								<li>
									<a className='text-muted' href='admissions@kmmias.org'>
										admissions@kmmias.org
									</a>
								</li>
							</ul>
						</div>
						<div className=''>
							<h6 className='mb-0'>
								<i className='fas fa-phone me-2'></i>Phone:
							</h6>
							<p className='text-muted mb-2'>(contact timing: 9am - 7pm)</p>
							<ul className='list-unstyled text-muted'>
								<li>9136664343</li>
								<li>9136665252</li>
								<li>9136665353</li>
							</ul>
						</div>
					</div>
					<div className='col-sm-6 col-md-4'>
						<h6>Address</h6>
						<p className='text-muted'>
							K M Munshi Institute of Advanced Studies Bharatiya Vidya Bhavan
							Navi Mumbai Kendra, Plot 3A, Sector-30, Vashi, Navi Mumbai –
							400703
						</p>
					</div>
				</div>
			</div>
		</section>
	);
}
