import React from 'react';
import { Link } from 'react-router-dom';
import Headings from '../../../utils/Headings';

export default function ApplicationForm() {
	return (
		<section className='my-5 py-5'>
			<div className='container'>
				<Headings className='mb-5'>Admission Process</Headings>
				<div className='mx-auto' style={{ maxWidth: '1000px' }}>
					<h4 className='mb-4'>
						Guidelines for candidates to fill an application form
					</h4>
					<ol className='mb-4'>
						<li>
							Student can fill the application form online through student login{' '}
							<Link
								to={{ pathname: 'https://admissions.kmmias.org' }}
								target='_blank'
								className='text-info'
							>
								on our website
							</Link>{' '}
							OR fill the soft copy of application form and send it to email id:{' '}
							<a href='mailto:admissions@kmmias.org' className='text-info'>
								admissions@kmmias.org
							</a>
							. <br />
							<a
								href='/media/brochure/brochure.pdf'
								download='brochure.pdf'
								className='text-info'
							>
								Click Here to download the soft copy of the application form
								<i className='fas fa-file-download ms-1'></i>
							</a>
						</li>
						<li>
							Please upload your passport size photograph. Format: jpg, .pdf,
							Size limit: 2MB maximum
						</li>
						<li>
							For applying into any program of BVB KMMIAS, the Application fee
							is Rs.600. Application fee has been waived off for the first
							academic year 2021.
						</li>
						<li>
							It is mandatory to fill your information in all the blank fields
							of the Application form, OR fill “NA” if any information is Not
							Applicable for you.
						</li>
						<li>
							You are required to upload your academic qualification documents
							and one identity proof document (pan card, aadhar card, driving
							license, passport, Voter ID) Format: jpg, .pdf, size limit 2MB
							maximum.
						</li>
						<li>
							If you are applying for PGDM(E) program then please upload all
							employment details for a minimum tenure of 5 years after
							graduation.
						</li>
						<li>
							For any clarifications on the application form OR admission
							process, Please feel free to us.
						</li>
					</ol>
					<div className=''>
						<p className='mb-0'>
							<i className='fas fa-envelope me-2'></i>Email:{' '}
							<a href='mailto:admissions@kmmias.org'>admissions@kmmias.org</a>
						</p>
						<p>
							<i className='fas fa-phone me-2'></i>Phone: 9136664343 /
							9136665252 / 9136665353
						</p>
					</div>
				</div>
			</div>
		</section>
	);
}
