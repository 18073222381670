import React, { Fragment, useEffect, useState } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

export default function Header() {
	const [sidenavWidth, setsidenavWidth] = useState('0');
	const [hideMainNav, sethideMainNav] = useState(false);
	const [schoolTypeImg, setschoolTypeImg] = useState('sof.png');
	const [subUrl, setSubUrl] = useState('');

	const location = useLocation();

	const navigation = [
		{
			label: 'Home',
			path: '/home',
		},
		{
			label: 'About Us',
			path: '/introduction',
			submenu: [
				{
					label: 'BVB',
					path: '/introduction/bvb',
					submenu: [
						{
							label: 'About Bhavans',
							path: '/introduction/bvb/about#about_bhavans',
						},
						{
							label: 'Our Founder',
							path: '/introduction/bvb/about#our_founder',
						},
						{
							label: 'Bhavans Ethos',
							path: '/introduction/bvb/about#bhavans_ethos',
						},
						{
							label: 'Bhavans Kendras',
							path: '/introduction/bvb/about#kendras',
						},
						{
							label: 'Awards and Recognition',
							path: '/introduction/bvb/about#awards',
						},
						{
							label: 'Years of growth',
							path: '/introduction/bvb/about#growth',
						},
						{
							label: 'Bhavans Pedigree',
							path: '/introduction/bvb/about#pedigree',
						},
						// {
						// 	label: 'News From Kendra',
						// 	path: '/introduction/bvb/news',
						// },
					],
				},
				{
					label: 'KMMIAS',
					path: '/introduction/kmmias',
					submenu: [
						{
							label: 'About KMMIAS',
							path: '/introduction/kmmias/about',
						},
						{
							label: 'A Message from our Hon. Director',
							path: '/introduction/kmmias/message_director',
						},
						{
							label: 'Team KMMIAS',
							path: '/introduction/kmmias/team',
						},
					],
				},
				{
					label: 'AIMA',
					path: '/introduction/aima',
				},
				{
					label: 'ISACA',
					path: '/introduction/isaca',
				},
				{
					label: 'RISE',
					path: '/introduction/rise',
				},{
					label: 'FFOI',
					path: '/introduction/ffoi',
				},
				{
					label: 'Our USP',
					path: '/introduction/usp',
				},
			],
		},
		{
			label: 'Schools',
			path: '/schools',
			submenu: [ {
				label: 'School of Financial Services',
				path: '/schools/financial-services'
			},{
				label: 'School of Entrepreneurship',
				path: '/schools/entrepreneurship'
			},{
				label: 'School of Skills',
				path: '/schools/skills'
			},{
				label: 'School of Finance',
				path: '/schools/finance'
			},{
				label: 'School of Research',
				path: '/schools/research'
			},{
				label: 'School of Compliance and Risk',
				path: '/schools/compliance-and-risk'
			}]
		},
		{
			label: 'Programs',
			path: '/programs',
			submenu: [
				// {
				// 	label: 'AIMA Tie-up',
				// 	path: '/programs/aima_tie_up',
				// },
				// {
				// 	label: 'PGDM',
				// 	path: '/programs/pgdm_main',
				// },
				// {
				// 	label: 'PGDM (executive)',
				// 	path: '/programs/pgdm_executive',
				// },
				{
					label: 'PGDM-ERP',
					path: '/programs/pgdm_erp',
					icon: ''
				},
				{
					label: 'Skill Based Programs',
					path: '/programs/sbp',
				},
				{
					label: 'Short Term Programs',
					path: '/programs/stp',
				},
				{
					label: 'PGP Business Analytics',
					path: '/programs/pgpba',
				},
				{
					label: 'PGP Investment Banking',
					path: '/programs/pgpib',
				},
				{
					label: 'Product Management Programs',
					path: '/programs/pmp',
				}
			],
		},
		{
			label: 'Pedagogy',
			path: '/pedagogy',
			submenu: [
				// {
				// 	label: 'Faculty',
				// 	path: '/pedagogy/faculty',
				// },
				{
					label: 'Teaching Methodology',
					path: '/pedagogy/teaching_methodology',
				},
				// {
				// 	label: 'Industry Interface',
				// 	path: '/pedagogy/industry_interface',
				// },
			],
		},
		{
			label: 'Admissions',
			path: '/admissions',
			submenu: [
				{
					label: 'Admission Process',
					path: '/admissions/process',
					submenu: [
						{
							label: 'Application form',
							// path: { pathname: { pathname: 'https://admissions.kmmias.org/register' } },
							// icon: 'file-download ms-1',
							path: '/admissions/process/application_form',
						},
						{
							label: 'Eligibility',
							path: '/admissions/process/eligibility',
						},
						{
							label: 'Fees',
							path: '/admissions/process/fees',
						},
					],
				},
				{
					label: 'PGDM',
					path: { pathname: 'https://admissions.kmmias.org/register' },
				},
				{
					label: 'PGDM (Executive)',
					path: { pathname: 'https://admissions.kmmias.org/register' },
				},
				{
					label: 'PGDM-ERP',
					path: { pathname: 'https://admissions.kmmias.org/register' },
				},
				{
					label: 'PGP Business Analytics',
					path: { pathname: 'https://admissions.kmmias.org/register' },
				},
				{
					label: 'PGP Investment Banking',
					path: { pathname: 'https://admissions.kmmias.org/register' },
				},
				// {
				// 	label: 'Scholarship & financial assistance',
				// 	path: '/admissions/scholarship',
				// },
				// {
				// 	label: 'FAQ',
				// 	path: '/admissions/faq',
				// },
			],
		},
		// {
		// 	label: 'Resource',
		// 	path: '/resources',
		// 	submenu: [
		// 		{
		// 			label: 'Infrastructure',
		// 			path: '/resources/infrastructure',
		// 		},
		// 	],
		// },
		// {
		// 	label: 'Campus Cafe',
		// 	path: '/campus',
		// 	submenu: [
		// 		{
		// 			label: 'News and Events',
		// 			path: '/campus/news',
		// 		},
		// 		{
		// 			label: 'Articles',
		// 			path: '/campus/articles',
		// 		},
		// 		{
		// 			label: 'Blog',
		// 			path: '/campus/blogs',
		// 		},
		// 		{
		// 			label: 'Photo Gallery',
		// 			path: '/campus/gallery',
		// 		},
		// 	],
		// },
		{
			label: 'Contact Us',
			path: '/contact',
			submenu: [
				{
					label: 'Enquiry form',
					path: '/contact#form',
					// submenu: [
					// 	{
					// 		label: 'Vendor enquiry',
					// 		path: '/contact/enquiry/vendor',
					// 	},
					// 	{
					// 		label: 'Admission enquiry',
					// 		path: '/contact/enquiry/admission',
					// 	},
					// 	{
					// 		label: 'Job enquiry',
					// 		path: '/contact/enquiry/job',
					// 	},
					// 	{
					// 		label: 'General enquiry',
					// 		path: '/contact/enquiry/general',
					// 	},
					// ],
				},
				{
					label: 'Email & Helpline',
					path: '/contact#email',
				},
				{
					label: 'Google Map',
					path: '/contact#map',
				},
			],
		},
		{
			label: 'Blogs',
			path: '/blogs',
		}
	];

	const checkActive = (path) => {
		if (location.pathname.includes(path)) return 'bg-info text-white';
		return null;
	};

	const makeLinkDom = (item, index) => {
		if (item.path.pathname) {
			return (
				<li className='' key={index}>
					<Link
						className={`custom-dropdown-item text-dark px-3 py-2`}
						to={item.path}
						target='_blank'
					>
						{item.label}
						{item.icon && <i className={`fas fa-${item.icon}`}></i>}
					</Link>
				</li>
			);
		}
		if (item.path.includes('#')) {
			return (
				<li className='' key={index}>
					<HashLink
						className={`custom-dropdown-item text-dark px-3 py-2`}
						to={item.path}
					>
						{item.label}
						{item.icon && <i className={`fas fa-${item.icon}`}></i>}
					</HashLink>
				</li>
			);
		} else {
			return (
				<li className='' key={index}>
					<NavLink
						className={`custom-dropdown-item text-dark px-3 py-2`}
						to={item.path}
					>
						{item.label}
						{item.icon && <i className={`fas fa-${item.icon}`} style={{color:'#fbe015'}}></i>}
					</NavLink>
				</li>
			);
		}
	};

	useEffect(() => {
		setsidenavWidth('0');
		let furls= ['/schools/financial-services/know-us','/schools/financial-services/courses','/schools/financial-services/activities','/schools/financial-services/contact','/schools/financial-services/gallery'];
		let eurls= ['/schools/entrepreneurship/know-us','/schools/entrepreneurship/courses','/schools/entrepreneurship/activities','/schools/entrepreneurship/contact','/schools/entrepreneurship/gallery'];
		if(furls.includes(location.pathname)){
			sethideMainNav(true)
			setschoolTypeImg('sof.png');
			setSubUrl('financial-services');
		} 
		else if(eurls.includes(location.pathname)){
			sethideMainNav(true)
			setschoolTypeImg('soe.png');
			setSubUrl('entrepreneurship');
		}
		else {
			sethideMainNav(false)
		}
	}, [location.pathname]);

	useEffect(() => {
		return () => {
			window.scrollTo(0, 0);
		};
	}, []);
	return (
		<>
			<div className='sticky-top bg-light shadow'>
				<div className='container py-2 md-py-4'>
					{hideMainNav===false ? (
					<nav className='navbar navbar-light py-0'>
						<div className='container-fluid'>
							<Link className={`navbar-brand`} to='/home'>
								<img
									src={`${process.env.PUBLIC_URL}/media/bhavans.png`}
									alt=''
									width='200px'
								/>
							</Link>
							<div className='align-items-center justify-content-center d-none d-md-flex'>
								<ul className='navbar-nav flex-row gap-2'>
									{navigation.map((menu, menuindex) => {
										if (!menu.submenu) {
											return (
												<li className={`nav-item`} key={menuindex}>
													<NavLink
														className={`nav-link text-dark px-2 rounded-1 ${checkActive(
															menu.path
														)}`}
														to={menu.path}
													>
														{menu.label}
													</NavLink>
												</li>
											);
										} else {
											return (
												<li className={`nav-item dropdown`} key={menuindex}>
													<button
														className={`btn nav-link text-dark dropdown-toggle px-2 rounded-1 ${checkActive(
															menu.path
														)}`}
													>
														{menu.label}
													</button>
													<ul
														className='dropdown-menu py-0 rounded-0'
														style={
															menuindex < 7
																? { position: 'absolute', left: '0' }
																: { position: 'absolute', right: '0' }
														}
													>
														{menu.submenu.map((sub, subindex) => {
															if (!sub.submenu) {
																return makeLinkDom(sub, subindex);
															} else {
																return (
																	<Fragment key={subindex}>
																		<li className='nav-item dropend py-0 rounded-0'>
																			<NavLink
																				className={`custom-dropdown-item text-dark px-3 py-2`}
																				to={sub.path}
																			>
																				{sub.label}
																			</NavLink>
																			<ul
																				className='dropend-menu'
																				style={
																					menuindex > 6
																						? {
																								right: '100%',
																								marginRight: '-1px',
																						  }
																						: {
																								left: '100%',
																								marginLeft: '-1px',
																						  }
																				}
																			>
																				{sub.submenu.map((sub2, sub2index) => {
																					return makeLinkDom(sub2, sub2index);
																				})}
																			</ul>
																		</li>
																	</Fragment>
																);
															}
														})}
													</ul>
												</li>
											);
										}
									})}
								</ul>
							</div>
							<button
								className='d-md-none navbar-toggler'
								type='button'
								onClick={() => setsidenavWidth('100%')}
							>
								<span className='navbar-toggler-icon'></span>
							</button>
						</div>
					</nav>
					) : (
						<nav className='navbar navbar-light py-0'>
							<div className='container-fluid'>
								<Link className={`navbar-brand`} to='/home'>
									<img
										src={`${process.env.PUBLIC_URL}/media/bhavans.png`}
										alt=''
										width='200px'
									/>
								</Link>
								<div className='align-items-center justify-content-center d-none d-md-flex'>
									<ul className='navbar-nav flex-row gap-2'>
						                <li className={`nav-item`}>
											<NavLink
												className={`nav-link text-dark px-2 rounded-1`}
														to={'/home'}
													>
													Home
											</NavLink>
										</li>
						                <li className={`nav-item`}>
											<NavLink className={`nav-link text-dark px-2 rounded-1 ${checkActive('/schools/'+subUrl+'/know-us')}`} to={`/schools/`+subUrl+`/know-us`}>Know Us</NavLink>
										</li>
										<li className={`nav-item`}>
											<NavLink className={`nav-link text-dark px-2 rounded-1 ${checkActive('/schools/'+subUrl+'/courses')}`} to={`/schools/`+subUrl+`/courses`}>Courses</NavLink>
										</li>
										<li className={`nav-item`}>
											<NavLink className={`nav-link text-dark px-2 rounded-1 ${checkActive('/schools/'+subUrl+'/activities')}`} to={`/schools/`+subUrl+`/activities`}>Activities</NavLink>
										</li>	
										<li className={`nav-item`}>
											<NavLink className={`nav-link text-dark px-2 rounded-1 ${checkActive('/schools/'+subUrl+'/contact')}`} to={`/schools/`+subUrl+`/contact`}>Contact Us</NavLink>
										</li>
										<li className={`nav-item`}>
											<NavLink className={`nav-link text-dark px-2 rounded-1 ${checkActive('/schools/'+subUrl+'/gallery')}`} to={`/schools/`+subUrl+`/gallery`}>Photo Gallery</NavLink>
									    </li>
						            </ul>
						        </div>
								<button
								className='d-md-none navbar-toggler'
								type='button'
								onClick={() => setsidenavWidth('100%')}
							>
								<span className='navbar-toggler-icon'></span>
							</button>
								<Link className={`navbar-brand`} to='/home'>
								<img
									src={`${process.env.PUBLIC_URL}/media/${schoolTypeImg}`}
									alt=''
									width='200px'
								/>
								</Link>
						    </div>
						</nav>

					)}
				</div>
			</div>
			{hideMainNav===false ? (
			<div
				className='mobile-sidenav'
				style={{
					width: sidenavWidth,
				}}
			>
				<div className=' bg-light' style={{ width: '80%', height: '100%' }}>
					<div className='clearfix'>
						<button
							className='btn btn-outline-danger float-end rounded-0 m-2'
							type='button'
							onClick={() => setsidenavWidth('0')}
						>
							<i className='fas fa-times'></i>
						</button>
					</div>
					<div
						className='accordion'
						style={{ height: '100vh', maxHeight: '100vh', overflow: 'auto' }}
						id='mobile_nav'
					>
						{navigation.map((menu, menuindex) => {
							if (!menu.submenu) {
								return (
									<div className='accordion-item' key={menuindex}>
										<NavLink
											to={menu.path}
											className='empty-accordion-button'
											style={{ textDecoration: 'none' }}
										>
											{menu.label}
										</NavLink>
									</div>
								);
							} else {
								return (
									<div className='accordion-item' key={menuindex}>
										<h6 className='accordion-header'>
											<button
												className='accordion-button collapsed'
												type='button'
												data-bs-toggle='collapse'
												data-bs-target={`#menu${menuindex}`}
												aria-expanded='false'
												aria-controls={`menu${menuindex}`}
											>
												{menu.label}
											</button>
										</h6>
										<div
											id={`menu${menuindex}`}
											className='accordion-collapse collapse'
											aria-labelledby={`menu${menuindex}`}
											data-bs-parent='#mobile_nav'
										>
											<div className='accordion-body'>
												<ul className='list-unstyled pl-1'>
													{menu.submenu.map((sub, subindex) => {
														if (sub.submenu) {
															return (
																<li key={subindex}>
																	<span className='fw-bold'>{sub.label}</span>
																	<ul className=''>
																		{sub.submenu.map((sub2, sub2index) => {
																			return (
																				<li key={sub2index}>
																					<NavLink
																						to={sub2.path}
																						className='text-info'
																					>
																						{sub2.label}
																					</NavLink>
																				</li>
																			);
																		})}
																	</ul>
																</li>
															);
														} else {
															return (
																<li key={subindex}>
																	<NavLink to={sub.path} className='text-info'>
																		{sub.label}
																	</NavLink>
																</li>
															);
														}
													})}
												</ul>
											</div>
										</div>
									</div>
								);
							}
						})}
					</div>
				</div>
				<div
					style={{
						width: '20%',
						background: 'rgba(0, 0, 0, 0.5)',
					}}
					onClick={() => setsidenavWidth('0')}
				></div>
			</div>
			) : (
				<div className='mobile-sidenav' style={{width: sidenavWidth}}>
					<div className=' bg-light' style={{ width: '80%', height: '100%' }}>
						<div className='clearfix'>
							<button
								className='btn btn-outline-danger float-end rounded-0 m-2'
								type='button'
								onClick={() => setsidenavWidth('0')}
							>
								<i className='fas fa-times'></i>
							</button>
						</div>
						<div className='accordion' style={{ height: '100vh', maxHeight: '100vh', overflow: 'auto' }} id='mobile_nav'>
				            <div className='accordion-item'>
				                <NavLink className={`nav-link text-dark px-2 rounded-1`} to={'/home'}>Home</NavLink>
							</div>
				            <div className='accordion-item'>
				                <NavLink className={`nav-link text-dark px-2 rounded-1`} to={`/schools/`+subUrl+`/know-us`}>Know Us</NavLink>
							</div>
				            <div className='accordion-item'>
				                <NavLink className={`nav-link text-dark px-2 rounded-1`} to={`/schools/`+subUrl+`/courses`}>Courses</NavLink>
							</div>
				            <div className='accordion-item'>
				                <NavLink className={`nav-link text-dark px-2 rounded-1`} to={`/schools/`+subUrl+`/activities`}>Activities</NavLink>
							</div>
				            <div className='accordion-item'>
				                <NavLink className={`nav-link text-dark px-2 rounded-1`} to={`/schools/`+subUrl+`/contact`}>Contact Us</NavLink>
							</div>
				            <div className='accordion-item'>
				                <NavLink className={`nav-link text-dark px-2 rounded-1`} to={`/schools/`+subUrl+`/gallery`}>Photo Gallery</NavLink>
							</div>

				        </div>
					</div>
					<div style={{width: '20%',background: 'rgba(0, 0, 0, 0.5)'}} onClick={() => setsidenavWidth('0')}>

				    </div>
				</div>	
			)}
		</>
	);
}
