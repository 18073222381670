import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import UnderConstruction from '../UnderConstruction';
import AimaTieUp from './aima/AimaTieUp';
import Pgdm from './pgdm/Pgdm';
import Pgdme from './pgdme/Pgdme';
import Pgpba from './pgpba/Pgpba';
import Pgpib from './pgpib/Pgpib';
import Pgdmerp from './pgdmerp/Pgdmerp';
import SkillPrograms from './skill/SkillPrograms';
import ProductManagementPrograms from './pmp/ProductManagementPrograms';
import Stp from './stp/Stp';

export default function ProgramRoutes() {
	return (
		<>
			<section className='mb-4' style={{ position: 'relative' }}>
				<img
					src={`${process.env.PUBLIC_URL}/media/home/campus.png`}
					alt=''
					style={{
						width: '100%',
						maxHeight: '150px',
						objectFit: 'cover',
						objectPosition: 'center center',
						filter: 'brightness(.3)',
					}}
				/>
				<div
					className='text-center text-white'
					style={{
						position: 'absolute',
						top: '50%',
						left: '50%',
						transform: 'translate(-50%,-50%)',
					}}
				>
					<h1>Programs</h1>
				</div>
			</section>
			{/* <section className='mb-5'>
				<div className='container'>
					<nav>
						<ol className='breadcrumb'>
							<li className={`breadcrumb-item text-uppercase`}>
								<Link to={`/`} className='text-info'>
									home
								</Link>
							</li>
							{makeBreadcrumb(location.pathname)}
						</ol>
					</nav>
				</div>
			</section> */}
			<Switch>
				<Redirect exact from='/programs' to='/programs/aima' />
				<Route exact path='/programs/aima_tie_up' component={AimaTieUp} />
				<Route exact path='/programs/pgdm_main' component={Pgdm} />
				<Route exact path='/programs/pgdm_executive' component={Pgdme} />
				<Route exact path='/programs/pgdm_erp' component={Pgdmerp} />
				<Route exact path='/programs/sbp' component={SkillPrograms} />
				<Route exact path='/programs/stp' component={Stp} />
				<Route exact path='/programs/pgpba' component={Pgpba} />
				<Route exact path='/programs/pgpib' component={Pgpib} />
				<Route exact path='/programs/pmp' component={ProductManagementPrograms} />
				<Route component={UnderConstruction} />
			</Switch>
		</>
	);
}
