import React from 'react';
import { Link } from 'react-router-dom';
import Headings from '../../../utils/Headings';

export default function ProgramsOffered() {
	return (
		<div className='container py-5'>
			<div className='text-center mb-5 mx-auto' style={{ maxWidth: '600px' }}>
				<Headings className='text-white'>Programs Offered</Headings>
				<p className='text-muted'>
					We are offering the following industry specific program for blended
					learning to get an immersive impact. The program will create an
					entrepreneurial mindset, leadership trades &amp; critical thinking.
				</p>
			</div>
			<div className='row g-5'>
				<div className='col-lg-4'>
					<div className='p-lg-2 card home-different'>
						<div className='card-body d-flex flex-column justify-content-between'>
							<div className='mb-4'>
								<img
									src={`${process.env.PUBLIC_URL}/media/courses/enterpreneurship.jpg`}
									alt=''
									style={{ width: '100%' }}
									className='mb-2'
								/>
								<h5 className='card-title'>PGPBA</h5>
								<h6 className='mb-3'>Post Graduate Program in Business Analytics</h6>
								<p className='card-text'>
								PG Program in Business Analytics is brought to you by BVB KMMIAS and RISE with KPMG as knowledge partner, for students and working professionals who want to foray into Analytics.
								</p>
							</div>
							<div className='d-flex justify-content-between'>
								<Link to='/programs/pgpba' className='text-info'>
									Learn More
								</Link>
								<span
									className='badge bg-dark'
									style={{ lineHeight: 'normal' }}
								>
									<i className='fas fa-clock me-2'></i>
									11 Months
								</span>
							</div>
						</div>
					</div>
				</div>
				<div className='col-lg-4'>
					<div className='p-lg-2 card home-different'>
						<div className='card-body d-flex flex-column justify-content-between'>
							<div className='mb-4'>
								<img
									src={`${process.env.PUBLIC_URL}/media/courses/pgdme.jpg`}
									alt=''
									style={{ width: '100%' }}
									className='mb-2'
								/>
								<h5 className='card-title'>PGPIB</h5>
								<h6 className='mb-3'>
									Post Graduate Program in Investment Banking
								</h6>
								<p className='card-text'>
								The Post Graduate Program in Management (Investment Banking) is a 11 Months highly recognised autonomous joint certification degree offered by Bhartiya Vidya Bhavan’s K. M. Munshi Institute of Advanced Studies and Akademia School of Business Studies with a CEEMAN (Institutional Membership) recognition.
								</p>
							</div>
							<div className='d-flex justify-content-between'>
								<Link to='/programs/pgpib' className='text-info'>
									Learn More
								</Link>
								<span
									className='badge bg-dark'
									style={{ lineHeight: 'normal' }}
								>
									<i className='fas fa-clock me-2'></i>
									11 Months
								</span>
							</div>
						</div>
					</div>
				</div>
				<div className='col-lg-4'>
					<div className='p-lg-2 card home-different'>
						<div className='card-body d-flex flex-column justify-content-between'>
							<div className='mb-4'>
								<img
									src={`${process.env.PUBLIC_URL}/media/courses/problem_solving.jpg`}
									alt=''
									style={{ width: '100%' }}
									className='mb-2'
								/>
								<h5 className='card-title'>SBP</h5>
								<h6 className='mb-3'>Skill Based Program</h6>
								<p className='card-text'>
									This program aims to meet unique educational needs in their
									areas of interest. It also develops emerging skill sets and
									knowledge of the growing world with latest technologies
								</p>
							</div>
							<div className='d-flex justify-content-between'>
								<Link to='/programs/sbp' className='text-info'>
									Learn More
								</Link>
								<span
									className='badge bg-dark'
									style={{ lineHeight: 'normal' }}
								>
									<i className='fas fa-clock me-2'></i>
									Flexible
								</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
