import React, { Component } from 'react';
import Headings from '../../utils/Headings';
import blogService from './blog.service';
import { Link } from "react-router-dom";

export default class Blogs extends Component {
	constructor(props) {
		super(props);
		this.loadBlogs = this.loadBlogs.bind(this);
		this.state = {
			blogsList: [{
				'title':'What is Lorem Ipsum?',
				'body':'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s,',
				'id':1,
				'date':'2021-10-20'
			},{
				'title':'Where does it come from?',
				'body':'Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. ',
				'id':2,
				'date':'2021-10-20'
			},{
				'title':'Why do we use it',
				'body':'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.',
				'id':3,
				'date':'2021-10-20'
			}]
		  };
	  
	}

	componentDidMount() {
		this.loadBlogs();
	}
	
	loadBlogs() {
		blogService.getAll()
		  .then(response => {
			this.setState({
				blogsList: response.data
			});
		  })
		  .catch(e => {
			this.setState({
				blogsList: []
			});
			console.log(e);
		  });
	}
	
	


	render() {
		const { blogsList } = this.state;
		const DATE_OPTIONS = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };
		return <section className='my-5 py-5'>
			<div className='container'>
				<Headings className='mb-5'>Blogs</Headings>
				<div className='row gy-5'>
					{
						blogsList && blogsList.map((item, index) => (
							<div className="col-lg-4" key={index}>
                    		    <div className="blog-grid">
                    		        <div className="blog-img">
                    		            <div className="date">{(new Date(item.createdAt)).toLocaleDateString('en-US', DATE_OPTIONS)}</div>
                    		            <a href="#">
                    		                <img src={'http://localhost:5000/'+item.img} />
                    		            </a>
                    		        </div>
                    		        <div className="blog-info">
                    		            <h5><a href="#">{item.title}</a></h5>
                    		            {/* <p>{item.body}</p> */}
                    		            <div className="btn-bar">
										<Link to={"/blogs/"+item.id} className="px-btn-arrow">
                    		                    <span>Read More</span>
                    		                    <i className="arrow"></i>
                    		            </Link>
                    		            </div>
                    		        </div>
                    		    </div>
                    		</div>	
						))
					}
				</div>
			</div>
		</section>
	}
}
