import React from 'react';
import Headings from '../../../utils/Headings';

export default function FKnowUs() {

	return (
		<>
		<section className='mb-4' style={{ position: 'relative' }}>
				<img
					//src={`${process.env.PUBLIC_URL}/media/home/campus.png`}
					src={`${process.env.PUBLIC_URL}/media/home/campus.png`}
					alt=''
					style={{
						width: '100%',
						maxHeight: '150px',
						objectFit: 'cover',
						objectPosition: 'center center',
						filter: 'brightness(.3)',
					}}
				/>
				<div
					className='text-center text-white'
					style={{
						position: 'absolute',
						top: '50%',
						left: '50%',
						transform: 'translate(-50%,-50%)',
					}}
				>
					<h1>School Of Financial Services</h1>
				</div>
		</section>
		<section className='mb-5 py-2'>
			<div className='container'>
				{/* <h1 className='text-center mb-5'>School Of Financial Services</h1> */}
				<div className='mx-auto clearfix text-justify' style={{ maxWidth: '1000px' }}>
				</div>
				<div
					className={`mb-5 mx-auto clearfix text-justify`}
					style={{ maxWidth: '1000px' }}>
					<Headings className='mb-5'>Know Us</Headings>
					<p>
					At the School of Financial Services, our vision is to create an ecosystem that eventually leads to betterment for the consumers of financial services. We believe that a happy and safe financial services consumer is essential for efficient capital allocation in the country. For that purpose, education plays a major role, both for the consumers as well as the industry participants. With this insight, we propose to develop education programs to cater to three distinct segments:
					</p>
					<p>
						<ol>
							<li>Industry participants, who want to upgrade their skill sets</li>
							<li>Students, who want to build a career in financial services</li>
							<li>Public at large, who would be the customers of financial services</li>
						</ol>
					</p>
					<p>
						While the programs aimed at the industry participants would help them enhance their skills, and
						thus grow their business; the programs would also help them provide better services and solutions
						to their customers.	
					</p>
					<p>
						Students, on the other hand, look for career-oriented education. We would offer a bridge between
						the students and industry by creating specific programs as per the requirements.
					</p>
					<p>
						The consumers of financial services, the public at large, need to have basic knowledge about various
						products and services. They also need to know their rights and responsibilities. We intend to build a
						platform for providing the requisite education while at the same time offering inputs to the industry
						and the regulators to make appropriate changes in the processes, products, and regulations.
					</p>
					<p>
						The entire effort would be supported by various partnerships with the regulators, industry, and
						consumer protection groups. We are also in the process of setting up research facilities in order to
						enhance the quality of our programs.
					</p>
				</div>
			</div>
		</section>
		</>
	);
}
