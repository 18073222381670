import React from 'react';
import Headings from '../../../utils/Headings';

export default function IndustryInterface() {
	return (
		<section className='my-5 py-5'>
			<div className='container'>
				<Headings className='mb-5'>Industry Interface</Headings>
				<div className='row mx-auto'>
					<div className='col-md-6 p-0 gy-3 gy-md-0'>
						<img
							src={`${process.env.PUBLIC_URL}/media/industry_interface/seminar.jpg`}
							alt=''
							style={{
								width: '100%',
								height: '400px',
								maxHeight: '100%',
								objectFit: 'cover',
							}}
						/>
					</div>
					<div className='col-md-6 d-flex align-items-center'>
						<div className='px-5 text-center'>
							<h1>Seminars</h1>
							<p>
								Lorem ipsum, dolor sit amet consectetur adipisicing elit.
								Laudantium omnis quasi officiis tenetur non ea esse dolorum at
								vero maxime dolorem.
							</p>
							<p>
								Lorem ipsum dolor sit amet, consectetur adipisicing elit.
								Consequatur aspernatur cumque quaerat nostrum provident vel
								incidunt atque molestias harum nam est laudantium accusamus!
							</p>
						</div>
					</div>
				</div>
				<div className='row mx-auto gy-3 gy-md-0'>
					<div className='col-md-6 p-0 order-md-2'>
						<img
							src={`${process.env.PUBLIC_URL}/media/industry_interface/project.jpg`}
							alt=''
							style={{
								width: '100%',
								height: '400px',
								maxHeight: '100%',
								objectFit: 'cover',
							}}
						/>
					</div>
					<div className='col-md-6 order-md-1 d-flex align-items-center'>
						<div className='px-5 text-center'>
							<h1>Immersive Projects</h1>
							<p>
								Lorem ipsum dolor sit, amet consectetur adipisicing elit.
								Commodi aperiam sed hic aspernatur ab a placeat nihil dolorem,
								voluptatem excepturi illum id eius perspiciatis.
							</p>
							<p>
								Lorem ipsum dolor sit amet consectetur adipisicing elit. Animi,
								ea cum. Illum quia aspernatur minus tempore ratione magnam,
								facere, omnis, rerum odio nemo nihil earum excepturi.
							</p>
						</div>
					</div>
				</div>
				<div className='row mx-auto'>
					<div className='col-md-6 p-0 gy-3 gy-md-0'>
						<img
							src={`${process.env.PUBLIC_URL}/media/industry_interface/industrial_visit.jpg`}
							alt=''
							style={{
								width: '100%',
								height: '400px',
								maxHeight: '100%',
								objectFit: 'cover',
							}}
						/>
					</div>
					<div className='col-md-6 d-flex align-items-center'>
						<div className='px-5 text-center'>
							<h1>Industrial Visit</h1>
							<p>
								Lorem, ipsum dolor sit amet consectetur adipisicing elit.
								Repellendus, cum recusandae tenetur perspiciatis maiores placeat
								provident laborum dolorem! Velit repudiandae provident!
							</p>
							<p>
								Lorem, ipsum dolor sit amet consectetur adipisicing elit. Dolore
								neque repudiandae accusantium vitae autem animi necessitatibus
								rerum voluptate hic.
							</p>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}
