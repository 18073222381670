import React from 'react';
import AboutBhavans from './components/AboutBhavans';
import BhavansEthos from './components/BhavansEthos';
import OurFounder from './components/OurFounder';
import AwardsRecognition from './components/AwardsRecognition';
import Growth from './components/Growth';
import Pedigree from './components/Pedigree';
import Kendras from './Kendras';

export default function AboutBvb() {
	return (
		<>
			<AboutBhavans />
			<OurFounder />
			<BhavansEthos />
			<Kendras />
			<AwardsRecognition />
			<Growth />
			<Pedigree />
		</>
	);
}
