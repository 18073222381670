import React, { useState } from 'react';
import Headings from '../../utils/Headings';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import ReCAPTCHA from 'react-google-recaptcha';
import axios from 'axios';

export default function ContactUs() {
	const [alertType, setalertType] = useState('info');
	const initialValues = {
		name: '',
		email: '',
		mobile: '',
		message: '',
		responseKey: '',
	};
	const validationSchema = Yup.object().shape({
		name: Yup.string().required().label('Name'),
		email: Yup.string().email().required().label('Email'),
		message: Yup.string()
			.required()
			.min('50', 'Message should be a minimum of 50 characters')
			.max('1000', 'Message cannot be longer than 1000 characters')
			.label('Message'),
		responseKey: Yup.string()
			.required('Please verify you are not a robot')
			.label('Captcha'),
	});
	const formik = useFormik({
		initialValues,
		validationSchema,
		onSubmit: (values, { setStatus, setErrors }) => {
			return axios
				.post(`${process.env.REACT_APP_BASE_API_URL}/enquiry`, values)
				.then((res) => {
					formik.handleReset();
					setalertType('success');
					setStatus(
						'Enquiry sent successfully, our team will contact you shortly'
					);
				})
				.catch((err) => {
					if (err?.response?.data?.errors) {
						setErrors(err.response.data.errors);
					} else {
						setalertType('danger');
						setStatus(err?.response?.data?.message);
					}
				});
		},
	});
	return (
		<section className='my-5 py-5'>
			<div className='container'>
				<Headings className='mb-5'>Contact Us</Headings>
				<div className='row gy-5'>
					<div className='col-md-6 d-flex align-items-center' id='email'>
						<div className='mx-auto text-center' style={{ maxWidth: '400px' }}>
							<dl className='mb-5'>
								<dt>
									<i className='fas fa-map-marker-alt me-2'></i>Address:
								</dt>
								<dd>
									Bharatiya Vidya Bhavan Navi Mumbai Kendra,Plot 3A,Sector-30,
									Vashi, Navi Mumbai – 400703
								</dd>
							</dl>
							<dl className='mb-5'>
								<dt>
									<i className='fas fa-phone-alt me-2'></i>Contact:
								</dt>
								<dd>
									<p className='mb-0'>9136664343 / 9136665252 / 9136665353</p>
									<p>(contact timing: 9am - 7pm)</p>
								</dd>
							</dl>
							<dl className='mb-5'>
								<dt>
									<i className='fas fa-envelope me-2'></i>Email:
								</dt>
								<dd>admissions@kmmias.org</dd>
							</dl>
						</div>
					</div>
					<form className='col-md-6' id='form' onSubmit={formik.handleSubmit}>
						{formik.status && (
							<div class={`alert alert-${alertType}`} role='alert'>
								{formik.status}
							</div>
						)}
						<div className='mb-3'>
							<label className='form-label' htmlFor='name'>
								Name
							</label>
							<input
								type='text'
								className={`form-control ${
									formik.errors.name && formik.touched.name && 'is-invalid'
								}`}
								{...formik.getFieldProps('name')}
							/>
							{formik.errors.name && formik.touched.name && (
								<div className='invalid-feedback'>{formik.errors.name}</div>
							)}
						</div>
						<div className='mb-3'>
							<label className='form-label' htmlFor=''>
								Email
							</label>
							<input
								type='email'
								className={`form-control ${
									formik.errors.email && formik.touched.email && 'is-invalid'
								}`}
								{...formik.getFieldProps('email')}
							/>
							{formik.errors.email && formik.touched.email && (
								<div className='invalid-feedback'>{formik.errors.email}</div>
							)}
						</div>
						<div className='mb-3'>
							<label className='form-label' htmlFor=''>
								Message ({formik.values.message.length}/1000)
							</label>
							<textarea
								rows='5'
								className={`form-control ${
									formik.errors.message &&
									formik.touched.message &&
									'is-invalid'
								}`}
								{...formik.getFieldProps('message')}
								style={{ resize: 'none' }}
							></textarea>
							{formik.errors.message && formik.touched.message && (
								<div className='invalid-feedback'>{formik.errors.message}</div>
							)}
						</div>
						<div className='d-flex align-items-center justify-content-center'>
							<ReCAPTCHA
								sitekey={process.env.REACT_APP_GOOGLE_CAPTCHA_SITEKEY}
								onChange={(val) => formik.setFieldValue('responseKey', val)}
								onExpired={() => formik.setFieldValue('responseKey', '')}
							/>
						</div>
						{formik.errors.responseKey && (
							<div className='text-danger text-center'>
								{formik.errors.responseKey}
							</div>
						)}
						<button className='btn btn-success' disabled={formik.isSubmitting}>
							{formik.isSubmitting && (
								<span
									class='spinner-border spinner-border-sm'
									role='status'
									aria-hidden='true'
								></span>
							)}
							Send <i className='fas fa-paper-plane'></i>
						</button>
					</form>
					<div
						className='col-12 p-3'
						style={{ border: '1px solid #cbc8d0' }}
						id='map'
					>
						<div className='map-container'>
							<iframe
								src='https://www.google.com/maps/embed?pb=!1m21!1m12!1m3!1d2307.942639758038!2d72.99357835427975!3d19.06447755555344!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m6!3e0!4m0!4m3!3m2!1d19.0646669!2d72.99458299999999!5e0!3m2!1sen!2sin!4v1602585836838!5m2!1sen!2sin'
								title='Map'
								frameBorder='0'
								allowFullScreen=''
								aria-hidden='false'
								tabIndex='0'
								className='map'
							></iframe>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}
