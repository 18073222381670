import React from 'react';
import Headings from '../../../../utils/Headings';

export default function AwardsRecognition() {
	return (
		<section className='mb-5 py-5' id='awards'>
			<div className='container'>
				<Headings className='mb-4'>Awards and Recognition</Headings>
				<div className='text-center'>
					<ul className='list-unstyled fs-5'>
						<li></li>
						<li></li>
						<li></li>
						<li></li>
					</ul>
				</div>

				<div
					className='row mx-auto'
					style={{ maxWidth: '800px', width: '100%' }}
				>
					<div className='col-md-6 p-5 alert-info border border-warning border-end-0'>
						<div
							className='d-flex align-items-end'
							style={{
								backgroundImage:
									'url(../../media/awards/gandhi_peace_prize.jpg)',
								backgroundRepeat: 'no-repeat',
								backgroundPosition: 'center center',
								backgroundSize: 'contain',
								minHeight: '250px',
							}}
						>
							<p
								className='px-4 py-3 fs-5 text-center text-white m-0'
								style={{ backgroundColor: 'rgba(0, 0, 0,0.7)' }}
							>
								International Mahatma Gandhi Peace Prize -2002
							</p>
						</div>
					</div>
					<div className='col-md-6 p-5 alert-info border border-warning border-start-0'>
						<div
							className='d-flex align-items-end'
							style={{
								backgroundImage:
									'url(../../media/awards/rajiv_gandhi_award.jpg)',
								backgroundRepeat: 'no-repeat',
								backgroundPosition: 'center center',
								backgroundSize: 'contain',
								minHeight: '250px',
							}}
						>
							<p
								className='px-4 py-3 fs-5 text-center text-white m-0'
								style={{ backgroundColor: 'rgba(0, 0, 0,0.7)' }}
							>
								Rajiv Gandhi Award for National Integration -1992
							</p>
						</div>
					</div>
					<div className='col-12 p-5 alert-info border border-warning'>
						<h3 className='text-center mb-3'>Other Awards</h3>
						<p className='text-center mb-1 fs-5'>
							Communal Harmony Award -1999
						</p>
						<p className='text-center fs-5'>
							Educational Institution of National Eminence
						</p>
					</div>
				</div>

				{/* <div className='row mx-auto'>
					<div className='col-md-6 p-5 alert-warning border border-warning'>
						<div className='d-flex align-items-center'>
							<img
								src={`${process.env.PUBLIC_URL}/media/awards/gandhi_peace_prize.jpg`}
								alt=''
								style={{ width: '100px' }}
								className='me-3'
							/>
							<p className='fs-5'>
								International Mahatma Gandhi Peace Prize -2002
							</p>
						</div>
					</div>
					<div className='col-md-6 p-5 alert-warning d-flex align-items-center border border-warning'>
						<div className='d-flex align-items-center'>
							<img
								src={`${process.env.PUBLIC_URL}/media/awards/rajiv_gandhi_award.jpg`}
								alt=''
								style={{ width: '100px' }}
								className='me-3'
							/>
							<p className='fs-5'>
								Rajiv Gandhi Award for National Integration -1992
							</p>
						</div>
					</div>
				</div> */}
			</div>
		</section>
	);
}
