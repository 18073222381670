import React from 'react';
import Headings from '../../../utils/Headings';

export default function EKnowUs() {

	return (
		<>
		<section className='mb-4' style={{ position: 'relative' }}>
				<img
					//src={`${process.env.PUBLIC_URL}/media/home/campus.png`}
					src={`${process.env.PUBLIC_URL}/media/home/campus.png`}
					alt=''
					style={{
						width: '100%',
						maxHeight: '150px',
						objectFit: 'cover',
						objectPosition: 'center center',
						filter: 'brightness(.3)',
					}}
				/>
				<div
					className='text-center text-white'
					style={{
						position: 'absolute',
						top: '50%',
						left: '50%',
						transform: 'translate(-50%,-50%)',
					}}
				>
					<h1>School Of Entrepreneurship</h1>
				</div>
		</section>
		<section className='mb-5 py-2'>
			<div className='container'>
				{/* <h1 className='text-center mb-5'>School Of Financial Services</h1> */}
				<div className='mx-auto clearfix text-justify' style={{ maxWidth: '1000px' }}>
				</div>
				<div
					className={`mb-5 mx-auto clearfix text-justify`}
					style={{ maxWidth: '1000px' }}>
					<Headings className='mb-5'>Know Us</Headings>
					<p>
						KMMIAS School of Entrepreneurship is
						your community, your creative studio,
						your learning space, your trampoline.
					</p>
					<p>
						KMMIAS School of Entrepreneurship, has accumulated knowledge, experiences,
						and learnings on how to develop as a space for entrepreneurship. But in order to
						truly enable and unleash the transformative power of others we must be willing to
						transform ourselves. To explore across boundaries, to venture further, to connect, to
						share, to open.
					</p>
					<p>
						Hence, KMMIAS SoE cannot work alone. We are connected to an entrepreneurial
						ecosystem of entrepreneurs, academics, employers and community partners
						(nationally as well as internationally). Together we work to deliver transformational
						value through strengthening our learning environment, refining our andragogical
						tools with academic research, broadening our portfolio of venture activities, and
						supporting personal development.
					</p>
					<p>
						At KMMIAS SoE, we have the great ambition to transform society with business. We
						believe that entrepreneurship plays a critical role in solving the grand challenges that
						we face as individuals and in organizations and society. Thus, we see it as our
						business at KMMIAS SoE to energize the collaborative attention around
						transformational entrepreneurship and bring that into action.​
					</p>
					<h4 className='mb-4'>Our Philosophy</h4>
					<strong>KMMAIS SoE believes in Non-Traditional methods of education</strong>
					<p>
						<ul>
							<li>Experiential Learning</li>
							<li>Life Long Learning</li>
							<li>Reflection &amp; Teamwork</li>
						</ul>
					</p>

					<h4 className='mb-4'>
					Our Methodology
					</h4>
					<strong>KMMIAS SoE methodology is inspired by</strong>
					<p>
						<ul>
							<li>Wheeler’s method of Group ology</li>
							<li>Johari Window</li>
							<li>Schutz FIRO Model</li>
							<li>Kolb’s Experiential Learning Model</li>
						</ul>
					</p>
				</div>
			</div>
		</section>
		</>
	);
}
