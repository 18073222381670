import React from 'react';

export default function MessageFromDirector() {
	return (
		<section className='mb-5 py-5'>
			<div className='container'>
				<h1 className='mb-5 text-center'>A Message from our Hon. Director</h1>
				<div
					className='card mx-auto mb-5'
					style={{ width: '800px', maxWidth: '100%' }}
				>
					<div className='d-flex align-items-center justify-content-center alert-warning flex-wrap flex-md-nowrap'>
						<div className=''>
							<img
								src={`${process.env.PUBLIC_URL}/media/teachers/sengupta.png`}
								alt=''
								style={{
									height: '100%',
									objectFit: 'cover',
									maxHeight: '260px',
								}}
								className='me-2'
							/>
						</div>
						<div className=''>
							<div className='card-body'>
								<h4 className='card-title mb-1'>Dr A K Sen Gupta</h4>
								<h6 className='text-muted mb-3'>Hon. Dean and Hon. Director</h6>
								<p className='card-text'>
									Former Director, S P Jain Institute of Management & Research
									(SPJIMR), Mumbai and Former Director, SIES College of Manage
									Studies (SIESCOMS), Navi Mumbai.
								</p>
							</div>
						</div>
					</div>
				</div>
				<div
					className='x-background p-3 p-md-5 mx-auto fs-4'
					style={{ maxWidth: '800px' }}
				>
					<p>Dear friends,</p>
					<p>
						Welcome to the Bharatiya Vidya Bhavan’s K M Munshi Institute of
						Advanced Studies (KMMIAS).
					</p>
					<p>
						Bharatiya Vidya Bhavan is known for its glorious past in terms of
						its educational and cultural contribution to the history of India
						since pre-independence days. With more that 120 Kendras all over the
						world, BVB is an institute of eminence with several institutions
						from schools to post-graduate and beyond affiliated to it.
					</p>
					<p>
						Among the leading Institutions of Bhavans in Mumbai are S P Jain
						Institute of Management & Research (SPJIMR), Sardar Patel College of
						Engineering (SPCE), Sardar Patel Institute of Technology (SPIT), and
						A H Wadia School, Bhavan’s College all at Andheri. Many other
						leading institutions are there under the banner of Bharatitya Vidya
						Bhavan in different parts of the country.
					</p>
					<p>
						KMMIAS is the latest entrant among the elite institutions of higher
						learning of Bharatiya Vidya Bhavan. The institute will focus on
						different unique skill-building programs for 21st Century: both
						technical / managerial as well as cognitive. Over a period of time,
						KMMIAS is expected to position itself as one of the best
						institutions of higher learning in India in the various areas of
						advanced and relevant skills necessary for a global manager /
						technologist to succeed in this integrated world.
					</p>
					<p>
						The institute will have several schools of specialization as we go
						along. Two of the other areas where the institute is likely to
						specialize are Managing MSMEs and Entrepreneurship. The institute
						envisions to be a thought leader in the above areas both through
						fundamental / applied research as well as world classNameName
						teaching. We will be operating from the newly set up latest “Navi
						Mumbai Kendra” of Bharatiya Vidya Bhavan.
					</p>
					<p>
						Unfortunately, we are starting our journey at a time when the
						country is going through a serious health pandemic. But we are sure
						the adverse situation also provides us with a window of opportunity
						for the country to become stronger and new role for institutions
						like us. We welcome all stakeholders in our journey and call for
						their support. We are sure with the patronage and love from all, we
						will be in a position to create an institution of higher learning
						that is different, vibrant, global but yet full of Indian ethos and
						multi-disciplinary as envisioned in NEP 2020.
					</p>
					<p>Let us together build the Institute.</p>
					<br />
					<p>A K Sen Gupta,</p>
					<p>
						Hon Director of Bharatiya Vidya Bhavan’s K M Munshi Institute of
						Advanced Studies
					</p>
				</div>
			</div>
		</section>
	);
}
