import React from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import Footer from './pages/footer/Footer';
import Header from './pages/header/Header';
import Credits from './pages/credits/Credits';
import Home from './pages/home/Home';
import UnderConstruction from './pages/UnderConstruction';
import IntroductionRoutes from './pages/introduction/IntroductionRoutes';
import ScrollToTop from './utils/ScrollToTop';
import ProgramRoutes from './pages/programs/ProgramRoutes';
import PedagogyRoutes from './pages/Pedagogy/PedagogyRoutes';
import AdmissionRoutes from './pages/admissions/AdmissionRoutes';
import ResourcesRoutes from './pages/resources/ResourcesRoutes';
// import CampusCafeRoutes from './pages/campusCafe/CampusCafeRoutes';
import ContactUsRoutes from './pages/contact/ContactUsRoutes';
import BlogsRoutes from './pages/blogs/BlogsRoutes';
import SchoolRoutes from './pages/schools/SchoolRoutes';

export default function Routes() {
	return (
		<BrowserRouter>
			<ScrollToTop />
			<div
				className='d-flex flex-column justify-content-between'
				style={{ minHeight: '100vh' }}
			>
				<Header />
				<div className='flex-grow-1'>
					<Switch>
						<Route exact path='/home' component={Home} />
						<Route exact path='/credits' component={Credits} />
						<Route path='/introduction' component={IntroductionRoutes} />
						<Route path='/schools' component={SchoolRoutes} />
						<Route path='/programs' component={ProgramRoutes} />
						<Route path='/pedagogy' component={PedagogyRoutes} />
						<Route path='/admissions' component={AdmissionRoutes} />
						<Route path='/resources' component={ResourcesRoutes} />
						{/* <Route path='/campus' component={CampusCafeRoutes} /> */}
						<Route path='/contact' component={ContactUsRoutes} />
						<Route path='/blogs' component={BlogsRoutes} />
						<Redirect exact from='/' to='/home' />
						<Route component={UnderConstruction} />
					</Switch>
				</div>
				<Footer />
			</div>
		</BrowserRouter>
	);
}
