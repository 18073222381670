import React from 'react';
import Headings from '../../../../utils/Headings';

export default function BhavansEthos() {
	return (
		<section className='mb-5 py-5' id='bhavans_ethos'>
			<div className='container py-5 mx-auto' style={{ maxWidth: '800px' }}>
				<Headings className='mb-4'>Bhavans Ethos</Headings>
				<p>
					The Bhavan firmly believes that revitalizing ancient values and
					reintegrating them to suit the changing needs of modern times alone
					will give people the strength to assimilate whatever is good in the
					new, without being swept away from their traditional moorings.
				</p>
				<p>
					The Bhavan believes that there are elements in all cultures which
					transcends all barriers and knits people together. Its ideal is
				</p>
				<blockquote className='blockquote blockquote-custom'>
					<p>Vasudhaiva Kutumbakam: "The world is One Family"</p>
				</blockquote>
				<p>Its motto is</p>
				<blockquote className='blockquote blockquote-custom'>
					<p>
						Aa no bhadraah kratavo yantu vishwatah: "Let noble thoughts come to
						us from every side"
					</p>
				</blockquote>
				<p>
					The main purpose of the Bharatiya Vidya Bhavan is to provide a modern
					bridge to span the past, the present and the future, built on the
					strong foundation of the fundamental values of life.
				</p>
			</div>
		</section>
	);
}
