export const modulesBase = [
	{
		name: 'Module-I',
		img: `${process.env.PUBLIC_URL}/media/programs/management.jpg`,
		duration: '6 Months',
		subjects: [
			'Management Functions & Organization Behaviour',
			'Managerial Economics',
			'Business Statistics',
			'Financial & Management Accounting',
			'Introduction to Information Technology',
			'Business Communication',
		],
	},
	{
		name: 'Module-II',
		img: `${process.env.PUBLIC_URL}/media/programs/operations.jpg`,
		duration: '6 Months',
		subjects: [
			'Operations Management',
			'Human Resource Management',
			'Marketing Management',
			'Economic and Social Environment',
			'Research Methodology',
			'Financial Management',
		],
	},
	{
		name: 'Module-III',
		img: `${process.env.PUBLIC_URL}/media/programs/marketing.jpg`,
		desc: 'Specialization Module',
		duration: '6 Months',
		subjects: [
			'Marketing Management (MM)',
			'Financial Management (FM)',
			'Human Resource Management (HRM)',
			'Operations Management (OM)',
			'Business Analytics',
		],
	},
	{
		name: 'Module-IV',
		img: `${process.env.PUBLIC_URL}/media/programs/business_law.jpg`,
		duration: '6 Months',
		subjects: [
			'Business Law & corporate Governance',
			'International Business',
			'Entrepreneurial Management',
			'Strategic Management & Ethics',
			'Project Work',
		],
	},
];

export const moduleAdditional = [
	{
		name: 'Module-I',
		sections: [
			{
				head: 'Subjects',
				content: [
					'Campus to Corporate Grooming',
					'Business Terms and Business Reading',
				],
			},
			{
				head: 'Workshops',
				content: [
					'Indian Accounting Standards',
					'Indian Economy: Today and Tomorrow',
					'Select industry Studies',
					'Pricing Strategies',
					'Emerging Technologies',
				],
			},
		],
	},
	{
		name: 'Module–II',
		sections: [
			{
				head: 'Subjects',
				content: [
					'Campus to Corporate Grooming - Advanced',
					'Digital Marketing',
				],
			},
			{
				head: 'Workshops',
				content: [
					'Supply Chain Management',
					'Lean Manufacturing',
					'Quality Management',
					'Project Management',
					'Working Capital Management',
					'Introduction to Financial Markets',
				],
			},
		],
	},
	{
		name: 'Module–III',
		sections: [
			{
				head: 'Subjects in Entrepreneurship Specialization',
				content: [
					'Entrepreneurship: Introduction, Legal Framework, Formation of an Enterprise',
					'Attributes of an Entrepreneur',
					'Financing of an Entrepreneurship Enterprise',
					'Marketing of Products',
					'Project Report (Start and continued into module 4)',
				],
			},
			{
				head: 'Subjects in MSME Specialization',
				content: [
					'MSME Sector: Introduction, Legal Framework',
					'Best Manufacturing Practices',
					'Supply Chain, Logistics and Quality Framework',
					'Raising Finance for an Enterprise',
					'Marketing of Products',
					'Project Report (Start and continued in module 4)',
				],
			},
		],
	},
	{
		name: 'Module-IV',
		sections: [
			{
				head: 'Subjects in Entrepreneurship / MSME Specialization',
				content: ['Project Report (Continued from module 3)'],
			},
		],
	},
];

export const programHighlights = [
	'Holistic development of students',
	'Honing of managerial skills (for different functional disciplines) for effective decision making by resorting to globally used technologies and tools / techniques',
	'Enhancement of critical thinking as well as lateral thinking',
	'Development of research skills',
	'Networking through participation in National/ International Seminars  and Conferences',
	'Best of faculty from academic as well as corporate world',
	'World class pedagogy (case study, gaming, simulation, immersion projects) and corporate workshops',
	'Immersion projects / internships',
	'International tour (optional)',
	'Campus to corporate, total grooming and placement support',
];
