import React from 'react';
import { Link } from 'react-router-dom';

export default function Blank() {
	return (
		<section className='my-5 py-5'>
			<div className='container mx-auto pt-5' style={{ maxWidth: '600px' }}>
				<h3 className='text-center'>
					Full functionality is under development
				</h3>
			</div>
		</section>
	);
}
