import React from 'react';
import { Link } from 'react-router-dom';

export default function Carousel() {
	return (
		<>
			<div id='heroCarousel' className='carousel slide' data-bs-ride='carousel'>
				<div className='carousel-inner' style={{ maxHeight: '70vh' }}>
					<div className='carousel-item active'>
						<img
							src={`${process.env.PUBLIC_URL}/media/carousel/getinspired.jpg`}
							className='d-block w-100'
							style={{
								height: '70vh',
								objectFit: 'cover',
							}}
							alt=''
						/>
						<div
							className='centered-caption text-center text-dark p-3 rounded'
							// style={{
							// 	backgroundColor: 'rgba(0,0,0,0.8)',
							// 	width: '500px',
							// 	maxWidth: '90%',
							// }}
						>
							<h1 className='display-6 mb-3 fw-bold'>Welcome to Bhavan’s</h1>
							<h3 className='mb-3'>
								Get inspired &amp; educated with values at BVBs KMMIAS
							</h3>
							<Link
								to={{ pathname: 'https://admissions.kmmias.org/register' }}
								target='_blank'
								className='btn btn-success'
							>
								Apply Now <i className='fas fa-external-link-alt ms-1'></i>
							</Link>
						</div>
					</div>
					<div className='carousel-item'>
						<img
							src={`${process.env.PUBLIC_URL}/media/carousel/futurebegins.jpg`}
							className='d-block w-100'
							style={{
								height: '70vh',
								objectFit: 'cover',
							}}
							alt=''
						/>
						<div
							className='centered-caption text-center text-white p-3 rounded'
							// style={{
							// 	backgroundColor: 'rgba(0,0,0,0.8)',
							// 	width: '500px',
							// 	maxWidth: '90%',
							// }}
						>
							<h1 className='display-6 mb-3 fw-bold'>Welcome to Bhavan’s</h1>
							<h3 className='mb-3'>Future begins here</h3>
							<Link
								to={{ pathname: 'https://admissions.kmmias.org/register' }}
								target='_blank'
								className='btn btn-success'
							>
								Apply Now <i className='fas fa-external-link-alt ms-1'></i>
							</Link>
						</div>
					</div>
					<div className='carousel-item'>
						<img
							src={`${process.env.PUBLIC_URL}/media/carousel/teamleader.jpg`}
							className='d-block w-100'
							style={{
								height: '70vh',
								objectFit: 'contain',
							}}
							alt=''
						/>
						<div
							className='centered-caption text-center p-3 rounded text-dark'
							// style={{
							// 	backgroundColor: 'rgba(0,0,0,0.8)',
							// 	width: '500px',
							// 	maxWidth: '90%',
							// }}
						>
							<h1 className='display-6 mb-3 fw-bold'>Welcome to Bhavan’s</h1>
							<h3 className='mb-3'>Learn to become a team leader</h3>
							<Link
								to={{ pathname: 'https://admissions.kmmias.org/register' }}
								target='_blank'
								className='btn btn-success'
							>
								Apply Now <i className='fas fa-external-link-alt ms-1'></i>
							</Link>
						</div>
					</div>
					<div className='carousel-item'>
						<img
							src={`${process.env.PUBLIC_URL}/media/carousel/pmp.jpg`}
							className='d-block w-100'
							style={{
								height: '70vh',
								objectFit: 'contain',
							}}
							alt=''
						/>
						<div
							className='centered-caption text-center p-3 rounded text-dark'
							// style={{
							// 	backgroundColor: 'rgba(0,0,0,0.8)',
							// 	width: '500px',
							// 	maxWidth: '90%',
							// }}
						>
							
							<Link
								to={{ pathname: 'https://kmmias.org/programs/pmp' }}
								target='_blank'
								className='btn btn-success'
							>
								Apply Now <i className='fas fa-external-link-alt ms-1'></i>
							</Link>
						</div>
					</div>
				</div>
				<button
					className='carousel-control-prev d-none d-sm-block'
					type='button'
					data-bs-target='#heroCarousel'
					data-bs-slide='prev'
				>
					<span
						className='carousel-control-prev-icon'
						aria-hidden='true'
					></span>
					<span className='visually-hidden'>Previous</span>
				</button>
				<button
					className='carousel-control-next d-none d-sm-block'
					type='button'
					data-bs-target='#heroCarousel'
					data-bs-slide='next'
				>
					<span
						className='carousel-control-next-icon'
						aria-hidden='true'
					></span>
					<span className='visually-hidden'>Next</span>
				</button>
			</div>
			<div className='mx-auto row container' style={{ marginTop: '-40px' }}>
				<div
					className='col-md-4 text-center p-5'
					style={{ zIndex: 1, backgroundColor: '#314C96' }}
				>
					<i className='fas fa-book h4 text-white mb-4'></i>
					<p className='h4 text-white'>Industry Focused Curriculum</p>
				</div>
				<div
					className='col-md-4 text-center bg-primary p-5'
					style={{ zIndex: 1 }}
				>
					<i className='fas fa-chalkboard-teacher h4 text-white mb-4'></i>
					<p className='h4 text-white'>World Class Faculty</p>
				</div>
				<div
					className='col-md-4 text-center p-5'
					style={{ zIndex: 1, backgroundColor: '#314C96' }}
				>
					<i className='fas fa-project-diagram h4 text-white mb-4'></i>
					<p className='h4 text-white'>Smart Class Rooms</p>
				</div>
			</div>
		</>
	);
}
