import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import UnderConstruction from '../UnderConstruction';
import ApplicationForm from './AdmissionProcess/ApplicationForm';
import Eligibility from './AdmissionProcess/Eligibility';
// import FAQ from './FAQ';
// import ScholarshipAssistance from './ScholarshipAssistance';

export default function AdmissionRoutes() {
	return (
		<>
			<section className='mb-4' style={{ position: 'relative' }}>
				<img
					src={`${process.env.PUBLIC_URL}/media/home/campus.png`}
					alt=''
					style={{
						width: '100%',
						maxHeight: '150px',
						objectFit: 'cover',
						objectPosition: 'center center',
						filter: 'brightness(.3)',
					}}
				/>
				<div
					className='text-center text-white'
					style={{
						position: 'absolute',
						top: '50%',
						left: '50%',
						transform: 'translate(-50%,-50%)',
					}}
				>
					<h1>Admissions</h1>
				</div>
			</section>
			{/* <section className='mb-5'>
				<div className='container'>
					<nav>
						<ol className='breadcrumb'>
							<li className={`breadcrumb-item text-uppercase`}>
								<Link to={`/`} className='text-info'>
									home
								</Link>
							</li>
							{makeBreadcrumb(location.pathname)}
						</ol>
					</nav>
				</div>
			</section> */}
			<Switch>
				<Redirect exact from='/admissions' to='/admissions/process' />
				<Redirect
					exact
					from='/admissions/process'
					to='/admissions/process/application_form'
				/>
				<Route
					exact
					path='/admissions/process/application_form'
					component={ApplicationForm}
				/>
				<Route
					exact
					path='/admissions/process/eligibility'
					component={Eligibility}
				/>
				{/* <Route
					exact
					path='/admissions/scholarship'
					component={ScholarshipAssistance}
				/> */}
				{/* <Route exact path='/admissions/faq' component={FAQ} /> */}
				<Route component={UnderConstruction} />
			</Switch>
		</>
	);
}
