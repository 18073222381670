import React from 'react';

export default function AboutKmmias() {
	return (
		<>
			<section className='mb-5 py-5'>
				<div className='container'>
					<h1 className='text-center mb-5'>About KMMIAS</h1>
					<div className='row g-0'>
						<div className='col-lg-6'>
							<img
								src={`${process.env.PUBLIC_URL}/media/introduction/navi_mumbai_kendra.jpg`}
								alt=''
								style={{ width: '100%', height: '100%', objectFit: 'contain' }}
							/>
						</div>
						<div className='col-lg-6 p-3 p-lg-5 d-flex flex-column justify-content-center'>
							<h1 className='mb-3'>BVB Navi Mumbai Kendra</h1>
							<p>
								The latest entrant in the family of BVB is its new generation
								Centre at Vashi.
							</p>
							<p>
								This Centre is poised to be the next generation entity of BVB
								catering to the cultural and educational needs of Navi Mumbai
								and beyond. Besides educational activities, the Navi Mumbai
								Kendra will focus on Children Club, Yoga and Related Activities
								and a Cultural Club. The Centre is likely to become one of the
								hubs of education, spiritual, fine arts and cultural activities
								in the satellite city of Navi Mumbai.
							</p>
						</div>
					</div>
				</div>
			</section>
			<section className='py-5' style={{ backgroundColor: 'rgb(226,226,226)' }}>
				<div className='container'>
					<div className='row g-0'>
						<div className='col-lg-6 order-1 order-lg-2'>
							<img
								src={`${process.env.PUBLIC_URL}/media//introduction/about_kmmias.jpg`}
								alt=''
								style={{ width: '100%', height: '100%', objectFit: 'contain' }}
							/>
						</div>
						<div className='col-lg-6 p-3 p-lg-5 d-flex flex-column justify-content-center order-2 order-md-1'>
							<h1 className='mb-3'>
								K M Munshi Institute of Advanced Studies (KMMIAS)
							</h1>
							<p>
								One of the most important hallmarks of the Kendra is providing
								education. A state-of-the-art Institute known as K M Munshi
								Institute of Advanced Studies (KMMIAS) is located at the Centre.
								The Institute will focus on different unique skill-building
								programs for 21st Century: both technical / managerial as well
								as cognitive. Over a period of time, KMMIAS is expected to
								position itself as one of the best institutions of higher
								learning in India in various areas of advanced and relevant
								skills necessary for a global manager / technologist to succeed
								in this integrated world.
							</p>
							<h4>Our Vision</h4>
							<p>
								To be the institution of choice that catalyzes,
								<ol>
									<li>Creation of professionals with a passion for excellence</li>
									<li>Promotion of innovative global knowledge for new age professionals through innovative andragogy</li>
								</ol>
							</p>
							<h4>Our Mission</h4>
							<p>
								Providing incisive education for developing socially responsible &amp; environmentally
								responsible / conscious managers/ leaders with global ethos, Ecologically responsible,
								analytical mind and Creative Thinking for the new millennium.
							</p>
						</div>
					</div>
				</div>
			</section>
			{/* <section className='mb-5 testimonials'>
				<div className='py-2' style={{ backgroundColor: 'rgba(0,0,0,0.7)' }}>
					<div className='container'>
						<div className='row text-white text-center'>
							<div className='col-md-6 p-2 p-md-4 mb-4 mb-md-0'>
								<h1 className='mb-3'>Our Vision</h1>
								<p>
									To be one of the best new generation higher education
									institutions in India
								</p>
							</div>
							<div className='col-md-6 p-2 p-md-4'>
								<h1 className='mb-3'>Our Mission</h1>
								<p>
									Providing quality education for developing socially conscious
									managers and leaders with Indian ethos
								</p>
							</div>
						</div>
					</div>
				</div>
			</section> */}
		</>
	);
}
