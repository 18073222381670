import React from 'react';
import { Link } from 'react-router-dom';
import { modulesBase, programHighlights } from './constants';

export default function Pgpib() {
	return (
		<section className='mb-5 py-5'>
			<div className='container'>
				<div className='mb-5'>
					<h1 className='mb-5 text-center'>PG Program in Investment Banking</h1>
					<dl className='row'>
						<dt className='col-sm-2 offset-xl-1'></dt>
						<dd className='col-sm-10 col-xl-8 text-right'>
							<img
								src={`${process.env.PUBLIC_URL}/media/akademia.png`}
								alt=''
								style={{ width: '100%', maxWidth: '180px', objectFit: 'contain' }}
								className='float-end ms-4 mb-3'
							/>
						</dd>
						<dt className='col-sm-2 offset-xl-1'>About PGP Investment Banking:</dt>
						<dd className='col-sm-10 col-xl-8 text-justify'>
							<p>
							An investment bank acts as an intermediary between investors and corporate entities. The investment banking sector is exciting for youngsters for various reasons like high earning capabilities, excitement of dealing with corporates with high stakes, and the fascination of dealing with top executives like CEOs and board members. The PGPM in Investment Banking gives students a all-inclusive understanding of investment banking techniques, equity research, and capital markets. Designed by corporate advisory and industry experts, this programme wads the gap between theoretical concepts learned during academic degrees/diplomas and on-the-job application of those concepts. The Post Graduate Program in Management (Investment Banking) is a 11 Months highly recognised autonomous joint certification degree offered by Bhartiya Vidya Bhavan’s K. M. Munshi Institute of Advanced Studies and Akademia School of Business Studies with a CEEMAN (Institutional Membership) recognition.
							</p>
						</dd>
						<dt className='col-sm-2 offset-xl-1'>Eligibility:</dt>
						<dd className='col-sm-10 col-xl-8 text-justify'>
							<ul>
								<li>Graduates from any discipline.</li>
								<li>Should have obtained at least 50% marks in Graduation.</li>
								<li>Final year students are eligible to apply.</li>
							</ul>
						</dd>
						<dt className='col-sm-2 offset-xl-1'>Academic Duration:</dt>
						<dd className='col-sm-10 col-xl-8 text-justify'>
							<p>11 Months</p>
						</dd>
						<dt className='col-sm-2 offset-xl-1'>Course Fees:</dt>
						<dd className='col-sm-10 col-xl-8 text-justify'>
							<p>4,00,000/- Exclusive of GST</p>
						</dd>
						<dt className='col-sm-2 offset-xl-1'>Academic Curriculum: </dt>
						<dd className='col-sm-10 col-xl-8'>
							<div className='mb-4'>
								<p className='fw-bold'>Module wise break-up of the Program</p>
								<div className='row g-4 g-lg-5'>
									{modulesBase.map((module, index) => {
										return (
											<div className='col-lg-6' key={index}>
												<div
													className='card alert-info'
													style={{ height: '100%' }}
												>
													<img
														src={module.img}
														alt=''
														style={{
															height: '200px',
															objectFit: 'cover',
															filter: 'brightness(.9)',
														}}
													/>
													<div className='card-body'>
														<div className='d-flex justify-content-between align-items-start mb-2'>
															<div className=''>
																<h5 className='card-title m-0'>
																	{module.name}
																</h5>
																{module.desc && (
																	<span className='text-muted'>
																		{module.desc}
																	</span>
																)}
															</div>
														</div>
														<ul
															className='card-text list-unstyled fa-ul'
															style={{ marginLeft: '1.3em' }}
														>
															{module.subjects.map((subject, subjectIndex) => {
																return (
																	<li className='mb-1' key={subjectIndex}>
																		<i className='fas fa-caret-right fa-li'></i>
																		{subject}
																	</li>
																);
															})}
														</ul>
													</div>
												</div>
											</div>
										);
									})}
								</div>
							</div>
						</dd>
						<dt className='col-sm-2 offset-xl-1'>Program Highlights:</dt>
						<dd className='col-sm-10 col-xl-8'>
							<ul>
								{programHighlights.map((module, index) => {
									return (
										<li>
											<p>{module.desc}</p>
										</li>
									)
								})}
							</ul>
						</dd>
						<dt className='col-sm-2 offset-xl-1'></dt>
						<dd className='col-sm-10 col-xl-8'>
							<div className='d-flex'>
								<a
									href='/media/brochure/akademia.pdf'
									download='brochure.pdf'
									className='btn btn-primary me-3'
								>
									Download Brochure
									<i className='fas fa-file-download ms-1'></i>
								</a>
								<Link
									to={{ pathname: 'https://admissions.kmmias.org/register' }}
									target='_blank'
									className='btn btn-primary'
								>
									Apply Now
									<i className='fas fa-external-link-alt ms-1'></i>
								</Link>
							</div>
						</dd>
					</dl>
				</div>
			</div>
		</section>
	);
}
