import React from 'react';
import { Link } from 'react-router-dom';
import { moduleAdditional, modulesBase, programHighlights } from './constants';

export default function Pgdm() {
	return (
		<section className='mb-5 py-5'>
			<div className='container'>
				<div className='mb-5'>
					<h1 className='mb-5 text-center'>PGDM</h1>
					<dl className='row'>
						<dt className='col-sm-2 offset-xl-1'>About AIMA PGDM Program:</dt>
						<dd className='col-sm-10 col-xl-8 text-justify'>
							<p>
								<strong>
									The AIMA’s Post Graduate Diploma in Management (PGDM)
								</strong>{' '}
								is approved by All India Council for Technical education (AICTE)
								in ODL mode. The program is targeted at fresh graduates or
								graduates with work experience and aims at providing
								contemporary management knowledge and skills in tune with the
								students’ experience base to give the desired fillip and boost
								to the students’ career progression and aspirations.
							</p>
							<p>
								The program shall provide adequate perspective and an integrated
								overview of core business functions, key concepts and emerging
								tools and techniques enabling them to succeed in a competitive
								business environment and to become socially responsible and
								value driven leaders. Finally, the program is aimed at making
								the participants world class managers while keeping Indian
								values and ethos. For fresher’s, the program will be rigorous to
								make them ready for corporate managerial roles.
							</p>
						</dd>
						<dt className='col-sm-2 offset-xl-1'>Target Group:</dt>
						<dd className='col-sm-10 col-xl-8 text-justify'>
							<p>
								The PGDM program is specially designed for fresh graduates,
								working professionals, individuals from business families,
								managers of start-ups, entrepreneurs and graduates who plan to
								work after they pursue PGDM. All candidates should have minimum
								graduate degree in any discipline from a UGC recognized
								University or equivalent.
							</p>
						</dd>
						<dt className='col-sm-2 offset-xl-1'>Academic Duration:</dt>
						<dd className='col-sm-10 col-xl-8 text-justify'>
							<p>24 months; weekdays program; 4 modules</p>
						</dd>
						<dt className='col-sm-2 offset-xl-1'>Academic Curriculum: </dt>
						<dd className='col-sm-10 col-xl-8'>
							<div className='mb-4'>
								<p className='fw-bold'>Module wise break-up of the Program</p>
								<div className='row g-4 g-lg-5'>
									{modulesBase.map((module, index) => {
										return (
											<div className='col-lg-6' key={index}>
												<div
													className='card alert-info'
													style={{ height: '100%' }}
												>
													<img
														src={module.img}
														alt=''
														style={{
															height: '200px',
															objectFit: 'cover',
															filter: 'brightness(.9)',
														}}
													/>
													<div className='card-body'>
														<div className='d-flex justify-content-between align-items-start mb-2'>
															<div className=''>
																<h5 className='card-title m-0'>
																	{module.name}
																</h5>
																{module.desc && (
																	<span className='text-muted'>
																		{module.desc}
																	</span>
																)}
															</div>
															<div className='badge bg-dark'>
																<i className='fas fa-clock me-1'></i>
																{module.duration}
															</div>
														</div>
														<ul
															className='card-text list-unstyled fa-ul'
															style={{ marginLeft: '1.3em' }}
														>
															{module.subjects.map((subject, subjectIndex) => {
																return (
																	<li className='mb-1' key={subjectIndex}>
																		<i className='fas fa-caret-right fa-li'></i>
																		{subject}
																	</li>
																);
															})}
														</ul>
													</div>
												</div>
											</div>
										);
									})}
								</div>
							</div>
							<div className='mb-4'>
								<p className='fw-bold'>
									Additional Inputs Covered by BVB- KMMIAS
								</p>
								<div className='row g-4 g-lg-5'>
									{moduleAdditional.map((module, index) => {
										return (
											<div className='col-lg-6' key={index}>
												<div
													className='card alert-primary'
													style={{ height: '100%' }}
												>
													<div className='card-body'>
														<div className='d-flex justify-content-between align-items-start mb-2'>
															<div className=''>
																<h5 className='card-title m-0'>
																	{module.name}
																</h5>
																{module.desc && (
																	<span className='text-muted'>
																		{module.desc}
																	</span>
																)}
															</div>
														</div>
														{module.sections.map((section, sectionIndex) => {
															return (
																<div className='card-text' key={sectionIndex}>
																	<p className='fw-bold'>{section.head}</p>
																	<ul>
																		{section.content.map(
																			(content, contentIndex) => {
																				return (
																					<li key={contentIndex}>{content}</li>
																				);
																			}
																		)}
																	</ul>
																</div>
															);
														})}
													</div>
												</div>
											</div>
										);
									})}
								</div>
							</div>
						</dd>
						<dt className='col-sm-2 offset-xl-1'>Program Highlights:</dt>
						<dd className='col-sm-10 col-xl-8'>
							<ul className='fa-ul'>
								{programHighlights.map((program, index) => {
									return (
										<li key={index}>
											<i className='fas fa-check fa-li text-success'></i>
											{program}
										</li>
									);
								})}
							</ul>
						</dd>
						<dt className='col-sm-2 offset-xl-1'>Certifications:</dt>
						<dd className='col-sm-10 col-xl-8'>
							<ol>
								<li>
									Post-Graduate Diploma in Management (PGDM) on completion of
									the 2 year course work and passing in the examination by AIMA.
								</li>
								<li>
									Participants at the end of the program will also get a
									Post-Graduate{' '}
									<strong>
										Certificate in Entrepreneurship or MSME Management
									</strong>{' '}
									from BVB’s K M Munshi Institute of Advanced Studies.
								</li>
							</ol>
						</dd>
						<dt className='col-sm-2 offset-xl-1'>Resources:</dt>
						<dd className='col-sm-10 col-xl-8'>
							<div className='d-flex'>
								<a
									href='/media/brochure/brochure.pdf'
									download='brochure.pdf'
									className='btn btn-primary me-3'
								>
									Download Brochure
									<i className='fas fa-file-download ms-1'></i>
								</a>
								<Link
									to={{ pathname: 'https://admissions.kmmias.org/register' }}
									target='_blank'
									className='btn btn-primary'
								>
									Apply Now
									<i className='fas fa-external-link-alt ms-1'></i>
								</Link>
							</div>
						</dd>
					</dl>
				</div>
			</div>
		</section>
	);
}
