import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import ReCAPTCHA from 'react-google-recaptcha';
import axios from 'axios';


export default function ProductManagementPrograms() {
	const [alertType, setalertType] = useState('info');
	const [initialValues, setinitialValues] = useState({
		name: '',
		email:'',
		mobile:'',
		message: ''
	});

	const validationSchema = Yup.object().shape({
		name: Yup.string().required().label('Name'),
		email: Yup.string().required().label('Email'),
		mobile: Yup.string().required().label('Mobile'),
		responseKey: Yup.string()
			.required('Please verify you are not a robot')
			.label('Captcha'),
	});

	const formik = useFormik({
		initialValues,
		validationSchema,
		onSubmit: (values, { setStatus, setErrors }) => {
			return axios
				.post(`${process.env.REACT_APP_BASE_API_URL}/enquiry`, values)
				.then((res) => {
					formik.handleReset();
					setalertType('success');
					setStatus(
						'Details saved successfully, our team will contact you shortly'
					);

				})
				.catch((err) => {
					if (err?.response?.data?.errors) {
						setErrors(err.response.data.errors);
					} else {
						setalertType('danger');
						setStatus(err?.response?.data?.message);
					}
				});
		},
	});
	return (
		<section className='mb-5 py-5'>
			<div className='container'>
				<div className='mb-5'>
					<h1 className='mb-5 text-center'>KMMIAS Product Management Program</h1>
					<dl className='row'>
						<dt className='col-sm-2 offset-xl-1'>
							The Program Architecture
						</dt>
						<dd className='col-sm-10 col-xl-8 text-justify'>
							<p>
							The KMMIAS Product Management Program adopts a well-rounded approach, equipping you with a
							comprehensive skillset. You&#39;ll delve into Product Analysis, understanding market needs and competitor
							landscapes. Product Marketing and Branding modules will teach you to craft compelling narratives and
							strategies to position your product for success. The curriculum also incorporates global economics,
							finance, and business acumen, ensuring you grasp the broader commercial context. Additionally, the
							program recognizes the transformative power of Artificial Intelligence, integrating modules on new-age
							product management in the context of AI. This ensures you&#39;re prepared to navigate the future of
							product development and stay ahead of the curve.
							</p>
							<img
								src={`${process.env.PUBLIC_URL}/media/courses/pmp.jpg`}
								alt=''
								className="text-center"
								style={{
									width: '500px',
									height: '500px',
								}}
							/>
						</dd>
						<dt className='col-sm-2 offset-xl-1'>Why Product Management?</dt>
						<dd className='col-sm-10 col-xl-8 text-justify'>
							<p>
							In today&#39;s rapidly evolving marketplace, well-managed products are the cornerstone of business success.
							A product management course equips you with the skills to bridge the gap between business goals and
							user needs, making you a highly sought-after asset.
							</p>
							<p>
							The demand for skilled product managers is skyrocketing globally. A research report found a staggering
							32% increase in product management jobs year-over-year in the US alone. In India, the scenario is
							similar. A recent LinkedIn survey revealed a growth of 72% in product management jobs year-on-year,
							highlighting the growing importance of this field in our burgeoning startup ecosystem.
							</p>
							<p>
							By investing in a product management course, you gain the expertise to lead the entire product lifecycle,
							from ideation to launch and beyond.
							</p>
						</dd>
						<dt className='col-sm-2 offset-xl-1'>What areas will be covered?</dt>
						<dd className='col-sm-10 col-xl-8'>
							<p>
								The program architecture &amp; the subject areas covered have been tabulated that emphasizes on
								specifically 4 areas that are integrated through these programs.
							</p>
						</dd>
						<dt className='col-sm-2 offset-xl-1'>Certifications:</dt>
						<dd className='col-sm-10 col-xl-8'>
							<p>
								On successfully completing the program, participants at the end
								of the program will get certification from BVB’s K M Munshi
								Institute of Advanced Studies.
							</p>
						</dd>
						<dt className='col-sm-2 offset-xl-1'></dt>
						<dd className='col-sm-10 col-xl-8 mb-4'>
							<div className='row'>
								<div className='col-md-3'>
									<div className='card'>
										<div className='card-heading bg-info text-white text-center'>Global Economics, Finance &amp; Business</div>
										<div className='card-body height-240'>
											<p>GEFC</p>
											<p>Fundamentals of Finance</p>
											<p>Micro Aspects ofBusiness</p>
										</div>
									</div>
								</div>
								<div className='col-md-3'>
									<div className='card'>
										<div className='card-heading bg-info text-white text-center pt-2 pb-3'>Product Analysis</div>
										<div className='card-body height-240'>
											<p>Product Devlopment</p>
											<p>Tech Product Management</p>
											<p>AI Product Management</p>
											<p>Design Thinking Generative AI</p>
										</div>
									</div>
								</div>
								<div className='col-md-3'>
									<div className='card'>
										<div className='card-heading bg-info text-white text-center pt-2 pb-3'>Product Marketing</div>
										<div className='card-body height-240'>
											<p>Marketing Mix</p>
											<p>Product Positioning</p>
											<p>Price Discovery</p>
											<p>Consumer Behaviour</p>
											<p>Sales &amp; Distribution</p>
										</div>
									</div>
								</div>
								<div className='col-md-3'>
									<div className='card'>
										<div className='card-heading bg-info text-white text-center pt-2 pb-3'>Brand Management</div>
										<div className='card-body height-240'>
											<p>Branding</p>
											<p>Logistics</p>
											<p>Marcom</p>
										</div>
									</div>
								</div>
							</div>
						</dd>
						<dt className='col-sm-2 offset-xl-1'></dt>
						<dd className='col-sm-10 col-xl-8'>
							<h5>How different is the Product Management Program?</h5>
							<p>
							Unlike a traditional MBA&#39;s broad business focus, a Product Management program equips you with a
							specialized skillset for the in-demand product management role. This targeted program focuses on the
							entire product lifecycle, from understanding user needs to launch and growth, making you a highly
							sought-after expert in today&#39;s product-driven job market. LinkedIn had over 20,000 product
							management jobs listed in India, and Jobted says the average salary for a product manager is around
							246% higher than the national average of the conventional MBA pass outs.
							</p>
						</dd>
						<dt className='col-sm-2 offset-xl-1'></dt>
						<dd className='col-sm-10 col-xl-8'>
							<h5>What does powered mean?</h5>
							<p>
							KMMIAS powered MBA (PGDM/MMS) programs means integrating the areas of Product Management
							along with conventional MBA curriculum. The training imparted is modular in nature and is spread
							across 3 semesters with trainers drawn from the industry.
							</p>
						</dd>
						<dt className='col-sm-2 offset-xl-1'></dt>
						<dd className='col-sm-10 col-xl-8'>
							<h5>KMMIAS Powered MBA Product Management Course Structure</h5>
							<table className='table table-bordered'>
								<thead>
								<tr>
									<th className="bg-info text-white">Topics</th>
									<th className="bg-info text-white">Sem 1</th>
									<th className="bg-info text-white">Sem 2</th>
									<th className="bg-info text-white">Sem 3</th>
									<th className="bg-info text-white">Sem 4</th>
								</tr>
								</thead>
								<tbody>
								<tr>
									<td>Business &amp;
Environment</td>
									<td>Buillding Blocks of
Business- DMT
approach</td>
									<td>GEFC | Fundamentals of
Finance</td>
									<td>NA</td>
									<td>OJT* + Thesis
Report</td>
								</tr>
								<tr>
									<td>Product Life
Cycle</td>
									<td>Product
Development
|Design Thinking</td>
									<td>Product Strategy</td>
									<td></td>
									<td></td>
								</tr>
								<tr>
									<td>Business Skills</td>
									<td>Fundamentals of

Business</td>
									<td>Covered</td>
									<td>Disruptive
Innovation &amp;
Growth</td>
									<td></td>
								</tr>
								<tr>
									<td>Technology</td>
									<td>AI Product
Management</td>
									<td>Tech Product
Management</td>
									<td>Generative AI for
Product Managers</td>
									<td></td>
								</tr>
								<tr>
									<td>Marketing Skills</td>
									<td>NA</td>
									<td>Marketing Mix | Price
Discovery</td>
									<td>Positioning |
Segmentation</td>
									<td></td>
								</tr>
								<tr>
									<td>Branding</td>
									<td>NA</td>
									<td>MarCom</td>
									<td>Branding |

Logistics</td>
									<td></td>
								</tr>
								<tr>
									<td>Capstone Project</td>
									<td>Yes</td>
									<td>Yes</td>
									<td>Yes</td>
									<td></td>
								</tr>
								</tbody>
							</table>
						</dd>
						<dt className='col-sm-2 offset-xl-1'></dt>
						<dd className='col-sm-10 col-xl-8'>
							<h5>What is MAA – Marketing Acumen Test and its process?</h5>
							<p>
							Marketing Acumen Assessment is an assessment tool to check the readiness of a student to study the
							program. In simple words it acts as tool to screen students &amp; even a tool for availing KMMIAS
							scholarships.
							</p>
						</dd>
						<dt className='col-sm-2 offset-xl-1'></dt>
						<dd className='col-sm-10 col-xl-8'>
							<h5>MAA Process</h5>
							<div className='arrow'>Application</div>
							<div className='arrow'>Application Screening</div>
							<div className='arrow'>
								Apptitude/ Marketing Accumen
								<span>Only Selected Candidates after App Screening</span>
							</div>
							<div className='arrow'>Personal Interview</div>
							<div className='arrow'>Seat Reservation on Selection</div>
						</dd>
						<dt className='col-sm-2 offset-xl-1'></dt>
						<dd className='col-sm-10 col-xl-8'>
						<form className='col-md-6  offset-xl-3 col-sm-12' id='form' onSubmit={formik.handleSubmit}>
							<div className="row">
								<div className='col-sm-12 col-xl-12 text-center'>
									<h4>Enquiry Form</h4>
								</div>
								<div className='col-sm-12 col-xl-12 text-justify'>
									{formik.status!=null && (
										<div className={`alert alert-${alertType}`} role='alert'>
											{formik.status}
										</div>
									)}
								</div>
								<div className='col-sm-12 col-xl-12 text-justify'>
									<label className='form-label' htmlFor='name'>
											Full Name
										</label>
										<input
											type='text'
											className={`form-control ${
												formik.errors.name && formik.touched.name && 'is-invalid'
											}`}
											{...formik.getFieldProps('name')}
										/>
										{formik.errors.name && formik.touched.name && (
											<div className='invalid-feedback'>{formik.errors.name}</div>
										)}
								</div>
								<div className='col-sm-12 col-xl-12 text-justify'>
									<label className='form-label' htmlFor='email'>
											Email
										</label>
										<input
											type='email'
											className={`form-control ${
												formik.errors.email && formik.touched.email && 'is-invalid'
											}`}
											{...formik.getFieldProps('email')}
										/>
										{formik.errors.email && formik.touched.email && (
											<div className='invalid-feedback'>{formik.errors.email}</div>
										)}
								</div>
								<div className='col-sm-12 col-xl-12 text-justify'>
									<label className='form-label' htmlFor='email'>
											Mobile
										</label>
										<input
											type='text'
											className={`form-control ${
												formik.errors.mobile && formik.touched.mobile && 'is-invalid'
											}`}
											{...formik.getFieldProps('mobile')}
										/>
										{formik.errors.mobile && formik.touched.mobile && (
											<div className='invalid-feedback'>{formik.errors.mobile}</div>
										)}
								</div>
								<div className='col-sm-12 col-xl-12 text-justify'>
									<label className='form-label' htmlFor='message'>
											Message
										</label>
										<textarea className={`form-control`} {...formik.getFieldProps('message')}>
										</textarea>
								</div>
								<div className='d-flex align-items-center justify-content-center mt-2'>
										<ReCAPTCHA
											sitekey={process.env.REACT_APP_GOOGLE_CAPTCHA_SITEKEY}
											onChange={(val) => formik.setFieldValue('responseKey', val)}
											onExpired={() => formik.setFieldValue('responseKey', '')}
										/>
									</div>
									{formik.errors.responseKey && (
										<div className='text-danger text-center'>
											{formik.errors.responseKey}
										</div>
									)}
									<button className='btn btn-success mt-3' disabled={formik.isSubmitting}>
										{formik.isSubmitting && (
											<span
												className='spinner-border spinner-border-sm'
												role='status'
												aria-hidden='true'
											></span>
										)}
										Submit <i className='fas fa-save'></i>
									</button>
							</div>
						</form>
						</dd>
					</dl>
				</div>
			</div>
		</section>
	);
}
