import React from 'react';

export default function Credits() {
	return (
		<section className='my-5 py-5'>
			<div className='container'>
				<h3 className='text-center mb-5'>Credits</h3>
				<div className='mx-auto' style={{ maxWidth: '600px' }}>
					<h5 className=''>Stock images from:</h5>
					<ul className='list-unstyled'>
						<li>
							<a
								className='text-info'
								href='https://www.freepik.com/photos/book'
								target='_blank'
								rel='noreferrer'
							>
								Book photo created by jannoon028 - www.freepik.com
								<i className='fas fa-external-link-alt ms-1'></i>
							</a>
						</li>
						<li>
							<a
								className='text-info'
								href='https://www.freepik.com/vectors/background'
								target='_blank'
								rel='noreferrer'
							>
								Background vector created by starline - www.freepik.com
								<i className='fas fa-external-link-alt ms-1'></i>
							</a>
						</li>
					</ul>
				</div>
			</div>
		</section>
	);
}
