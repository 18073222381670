import React from 'react';
import Headings from '../../../../utils/Headings';
import { useMediaQuery } from '../../../../utils/useMediaQuery';

export default function Pedigree() {
	let isPageMd = useMediaQuery('(min-width: 1140px)');
	const imgClass = { height: '100%', maxHeight: '400px' };
	return (
		<section className='mb-5 py-5' id='pedigree'>
			<div className='container'>
				<div className='mx-auto mb-5' style={{ maxWidth: '800px' }}>
					<Headings className='mb-4'>Bhavans Pedigree</Headings>
					<p className='text-center'>
						Among the leading Institutions of Bhavans in Mumbai are S P Jain
						Institute of Management &amp; Research (SPJIMR), Sardar Patel
						College of Engineering (SPCE), Sardar Patel Institute of Technology
						(SPIT), all at Andheri.
					</p>
				</div>
				<div className='d-flex flex-column flex-xl-row align-items-center mb-5 py-2 py-md-4'>
					<img
						src={`${process.env.PUBLIC_URL}/media/introduction/sp_jain.png`}
						alt=''
						style={
							isPageMd
								? {
										...imgClass,
										width: '50%',
										minWidth: '50%',
										objectFit: 'cover',
								  }
								: { ...imgClass, width: '100%' }
						}
					/>
					<div
						className={`bg-primary text-white p-3 p-md-5 ${
							isPageMd ? 'rounded' : 'rounded-bottom'
						}`}
						style={isPageMd ? { marginLeft: '-40px' } : { marginLeft: '0' }}
					>
						<h4>S P Jain Institute of Management &amp; Research (SPJIMR)</h4>
						<p>
							Bharatiya Vidya Bhavan's S P Jain Institute of Management and
							Research (SPJIMR) is a leading school of management in the heart
							of India’s financial centre, Mumbai. SPJIMR is a part of the
							Bharatiya Vidya Bhavan and functions as an autonomous Institute
							with entrepreneurial agility. It consistently ranks amongst the
							top ten management Institutes in India.
						</p>
						<p>
							SPJIMR has been awarded accreditation by the prestigious AACSB
							International - The Association to Advance Collegiate Schools of
							Business.
						</p>
					</div>
				</div>
				<div className='d-flex flex-column-reverse flex-xl-row align-items-center mb-5 py-2 py-md-4'>
					<div
						className={`bg-info text-white p-3 p-md-5 ${
							isPageMd ? 'rounded' : 'rounded-bottom'
						}`}
						style={
							isPageMd
								? { marginRight: '-40px', zIndex: '2' }
								: { marginRight: '0' }
						}
					>
						<h4>Sardar Patel College of Engineering (SPCE)</h4>
						<p>
							Bharatiya Vidya Bhavan's Sardar Patel College of Engineering
							(SPCE) was established in 1962 as a Government Aided Engineering
							College. The College is permanently affiliated to University of
							Mumbai.
						</p>
						<p>
							Over the last 50 years the college has gained an excellent
							reputation in the field of Technical Education. S.P.C.E. Grade "A"
							rating engineering college from Govt. of Maharashtra. The College
							is a recognized PhD and QIP center.{' '}
						</p>
					</div>
					<img
						src={`${process.env.PUBLIC_URL}/media/introduction/sardar_patel.jpg`}
						alt=''
						style={
							isPageMd
								? {
										...imgClass,
										width: '50%',
										minWidth: '50%',
										objectFit: 'cover',
								  }
								: { ...imgClass, width: '100%' }
						}
					/>
				</div>
				<div className='d-flex flex-column flex-xl-row align-items-center mb-5 py-2 py-md-4'>
					<img
						src={`${process.env.PUBLIC_URL}/media/introduction/bhavans_andheri.jpeg`}
						alt=''
						style={
							isPageMd
								? {
										...imgClass,
										width: '50%',
										minWidth: '50%',
										objectFit: 'cover',
								  }
								: { ...imgClass, width: '100%' }
						}
					/>
					<div
						className={`text-white p-3 p-md-5 ${
							isPageMd ? 'rounded' : 'rounded-bottom'
						}`}
						style={
							isPageMd
								? { marginLeft: '-40px', backgroundColor: '#314C96' }
								: { marginLeft: '0', backgroundColor: '#314C96' }
						}
					>
						<h4>Bhavan’s College of Arts, Science and Commerce Andheri</h4>
						<p>
							Bhavan’s College established in 1946, to preserve and propagate
							Bharatiya Vidya, has throughout the post-independence period,
							served the society’s educational needs, by being deeply committed
							to Bhavan’s Culture, Bhavan’s Traditions and Bhavan’s Values. Due
							to the dynamic, versatile and visionary leadership of our Chairman
							Adv. Asif Mulla, the Bhavan’s Campus at Andheri has been enriched
							by bonding and networking between the Sister Institutions on the
							Campus.
						</p>
					</div>
				</div>

				<div className='d-flex flex-column flex-xl-row align-items-center mb-5 py-2 py-md-4'>
					<div
						className={`text-white p-3 p-md-5 ${
							isPageMd ? 'rounded' : 'rounded-bottom'
						}`}
						style={
							isPageMd
								? { marginRight: '-40px', backgroundColor: '#d92929', zIndex: '2' }
								: { marginRight: '0', backgroundColor: '#d92929' }
						}
					>
						<h4>Bhavan’s Ramakrishnan Institute of Teacher Education</h4>
						<p>
						Although Bharatiya Vidya Bhavan had various educational institutions, 
						there was no College for teacher education under its banner up to 2005. 
						The establishment of Bhavan’s Ramakrishnan Institute of Teacher Education –BRITE at 
						Ramanattukara removed this shortcoming. 
						(The institute was named in memory of late Padmabhooshan S. Ramakrishnaji 
						who was the real builder of Bhavan and rose up to a synonym of it being its 
						Executive Secretary &amp; Director General for more than four decades).
						</p>
					</div>
					<img
						src={`${process.env.PUBLIC_URL}/media/introduction/bhavans_ramakrishnan.jpg`}
						alt=''
						style={
							isPageMd
								? {
										...imgClass,
										width: '50%',
										minWidth: '50%',
										objectFit: 'cover',
								  }
								: { ...imgClass, width: '100%' }
						}
					/>
				</div>

				<div className='d-flex flex-column flex-xl-row align-items-center mb-5 py-2 py-md-4'>
					<img
						src={`${process.env.PUBLIC_URL}/media/introduction/bhavans_mehta.jpg`}
						alt=''
						style={
							isPageMd
								? {
										...imgClass,
										width: '50%',
										minWidth: '50%',
										objectFit: 'cover',
								  }
								: { ...imgClass, width: '100%' }
						}
					/>
					<div
						className={`text-white p-3 p-md-5 ${
							isPageMd ? 'rounded' : 'rounded-bottom'
						}`}
						style={
							isPageMd
								? { marginLeft: '-40px', backgroundColor: '#ed7728' }
								: { marginLeft: '0', backgroundColor: '#ed7728' }
						}
					>
						<h4>B.M. Mahavidhyalaya</h4>
						<p>
						B.M. Mahavidhyalaya is situated in Kaushambi in Uttar Pradesh state of India. B.M. Mahavidhyalaya offers 13 courses across 3 streams namely Science, Arts, Physical Education. Popular degrees offered at B.M. Mahavidhyalaya include BA, BSc, MSc.
						</p>
					</div>
				</div>

				<div className='d-flex flex-column flex-xl-row align-items-center mb-5 py-2 py-md-4'>
					<div
						className={`text-white p-3 p-md-5 ${
							isPageMd ? 'rounded' : 'rounded-bottom'
						}`}
						style={
							isPageMd
								? { marginRight: '-40px', backgroundColor: '#8cf263', zIndex: '2' }
								: { marginRight: '0', backgroundColor: '#8cf263' }
						}
					>
						<h4>Bhavan’s Tripura College of Science &amp; Technology (BTCST)</h4>
						<p>
						Bhavan’s Tripura College of Science &amp; Technology (BTCST) started its journey on 20th September, 2003. It was inaugurated by Sri. Manik Sarkar, Hon’ble Chief Minister of Tripura in the august presence of Late Dr. Pratap Chandra Chunder, Ex-Chairman , BVB , Kolkata Kendra and Ex-Minister of Education, Government of India and other state Government &amp; BVB functionaries.
						</p>
					</div>
					<img
						src={`${process.env.PUBLIC_URL}/media/introduction/bhavans_tripura.jpg`}
						alt=''
						style={
							isPageMd
								? {
										...imgClass,
										width: '50%',
										minWidth: '50%',
										objectFit: 'cover',
								  }
								: { ...imgClass, width: '100%' }
						}
					/>
				</div>

				<div className='d-flex flex-column flex-xl-row align-items-center mb-5 py-2 py-md-4'>
					<img
						src={`${process.env.PUBLIC_URL}/media/introduction/bhavans_doshi.jpg`}
						alt=''
						style={
							isPageMd
								? {
										...imgClass,
										width: '50%',
										minWidth: '50%',
										objectFit: 'cover',
								  }
								: { ...imgClass, width: '100%' }
						}
					/>
					<div
						className={`text-white p-3 p-md-5 ${
							isPageMd ? 'rounded' : 'rounded-bottom'
						}`}
						style={
							isPageMd
								? { marginLeft: '-40px', backgroundColor: '#e3b910' }
								: { marginLeft: '0', backgroundColor: '#e3b910' }
						}
					>
						<h4>Bhavan s Shri A.K.Doshi Mahila College</h4>
						<p>
						Bhavan s Shri A.K.Doshi Mahila College is situated in Jamnagar in Gujarat state of India. Established in 1975, is a Private college. The college is accredited by UGC, NAAC. Bhavan s Shri A.K.Doshi Mahila College offers 30 courses across 12 streams namely Commerce and Banking, Law, Engineering, Arts, Paramedical. Popular degrees offered at Bhavan s Shri A.K.Doshi Mahila College include B.Com, BSc, BA, Certificate. Besides a robust teaching pedagogy, Bhavan s Shri A.K.Doshi Mahila College is also a leader in research and innovation. Focus is given to activities beyond academics at Bhavan s Shri A.K.Doshi Mahila College, which is evident from its infrastructure, extracurricular activities and national &amp; international collaborations. The placement at Bhavan s Shri A.K.Doshi Mahila College is varied, with recruitment options both in corporates and the public sector as well as entrepreneurship.
						</p>
					</div>
				</div>

				<div className='d-flex flex-column flex-xl-row align-items-center mb-5 py-2 py-md-4'>
					<div
						className={`text-white p-3 p-md-5 ${
							isPageMd ? 'rounded' : 'rounded-bottom'
						}`}
						style={
							isPageMd
								? { marginRight: '-40px', backgroundColor: '#c84ddb', zIndex: '2' }
								: { marginRight: '0', backgroundColor: '#c84ddb' }
						}
					>
						<h4>Bhavan’s Sheth Ranchhodlal Acharatlal College of Science</h4>
						<p>
						Bhavan’s Sheth R.A. College of Science is a valued and vital community, partner striving to enrich our expanding, challenging and diverse and demanding global environment. Bharatiya Vidya Bhavan's Sheth R.A. College of Science is situated in Ahmedabad in Gujarat state of India. The college is accredited by NAAC. Bharatiya Vidya Bhavan's Sheth R.A. College of Science offers 5 courses across 1 streams namely Science. Popular degrees offered at Bharatiya Vidya Bhavan's Sheth R.A. College of Science include BSc. Besides a robust teaching pedagogy, Bharatiya Vidya Bhavan's Sheth R.A. College of Science is also a leader in research and innovation.
						</p>
					</div>
					<img
						src={`${process.env.PUBLIC_URL}/media/introduction/bhavans_ranchhodlal.jpg`}
						alt=''
						style={
							isPageMd
								? {
										...imgClass,
										width: '50%',
										minWidth: '50%',
										objectFit: 'cover',
								  }
								: { ...imgClass, width: '100%' }
						}
					/>
				</div>
				
				<div className='d-flex flex-column flex-xl-row align-items-center mb-5 py-2 py-md-4'>
					<img
						src={`${process.env.PUBLIC_URL}/media/introduction/bhavans_nayandurga.jpg`}
						alt=''
						style={
							isPageMd
								? {
										...imgClass,
										width: '50%',
										minWidth: '50%',
										objectFit: 'cover',
								  }
								: { ...imgClass, width: '100%' }
						}
					/>
					<div
						className={`text-white p-3 p-md-5 ${
							isPageMd ? 'rounded' : 'rounded-bottom'
						}`}
						style={
							isPageMd
								? { marginLeft: '-40px', backgroundColor: '#0064b5' }
								: { marginLeft: '0', backgroundColor: '#0064b5' }
						}
					>
						<h4>Bhavans New Science College</h4>
						<p>
						Bhavans New Science College (Day and Evening), Hyderabad (Secundrabad) Telangana is a recognised institute / college. Status: Private Aided Under Graduate Co-Education Colleges. Bhavans New Science College (Day and Evening), Hyderabad (Secundrabad) Telangana was established on / in 1956.
						</p>
					</div>
				</div>

				<div className='d-flex flex-column flex-xl-row align-items-center mb-5 py-2 py-md-4'>
					<div
						className={`text-white p-3 p-md-5 ${
							isPageMd ? 'rounded' : 'rounded-bottom'
						}`}
						style={
							isPageMd
								? { marginRight: '-40px', backgroundColor: '#45aaad', zIndex: '2' }
								: { marginRight: '0', backgroundColor: '#45aaad' }
						}
					>
						<h4>Bhavan's Vivekananda College</h4>
						<p>
						Bhavan's Vivekananda College popularly Bhavan's Sainikpuri, is a graduate and postgraduate college in Sainikpuri Post, Neredmet, Secunderabad, India. The institute was established by the Bharatiya Vidya Bhavan trust in 1993. It offers graduate and postgraduate courses in Science, Commerce and Humanities.
						</p>
					</div>
					<img
						src={`${process.env.PUBLIC_URL}/media/introduction/bhavans_vivekananda.jpg`}
						alt=''
						style={
							isPageMd
								? {
										...imgClass,
										width: '50%',
										minWidth: '50%',
										objectFit: 'cover',
								  }
								: { ...imgClass, width: '100%' }
						}
					/>
				</div>










				{/* <div className='d-flex flex-column-reverse flex-xl-row align-items-center mb-5 py-2 py-md-4'>
					<div
						className={`text-white p-3 p-md-5 ${
							isPageMd ? 'rounded' : 'rounded-bottom'
						}`}
						style={
							isPageMd
								? {
										marginRight: '-40px',
										zIndex: '2',
										backgroundColor: '#351d5a',
								  }
								: { marginRight: '0', backgroundColor: '#351d5a' }
						}
					>
						<h4>A H Wadia School</h4>
						<p>
							Bhavan’s A. H. Wadia High School caters to meet its cultural and
							ethical values through a concrete framework of Kulapati Dr K M
							Munshiji’s teachings that are associated on the principles of
							spirituality, morality and peace. The school provides learning
							environment that encourages children to explore their potential
							and allows them to grow into sensitive and thinking adults.
						</p>
					</div>
					<img
						src={`${process.env.PUBLIC_URL}/media/introduction/wadia.jpg`}
						alt=''
						style={
							isPageMd
								? {
										...imgClass,
										width: '50%',
										minWidth: '50%',
										objectFit: 'cover',
								  }
								: { ...imgClass, width: '100%' }
						}
					/>
				</div> */}
			</div>
		</section>
	);
}
