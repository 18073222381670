import React from 'react';
import Headings from '../../../../utils/Headings';

export default function OurFounder() {
	return (
		<section
			className='mb-5 py-5'
			style={{ backgroundColor: '#e2e2e2' }}
			id='our_founder'
		>
			<div className='container py-5'>
				<div className='row g-0'>
					<div className='col-md-8 p-3 p-md-5 d-flex flex-column justify-content-center order-2 order-md-1'>
						<Headings className='mb-3' align='left' border='full'>
							Our Founder
						</Headings>
						<p style={{ textAlign: 'justify' }}>
							In the Galaxy of eminent men of India, Kanhaiyalal Maneklal Munshi
							is a shining star that still beckons humanity to the path of duty
							and dharma.Spruce in his turn out, gifted with intense and
							penetrating eyes, Munshiji impressed one as a person who had a
							strong hold on his inner as well as outer life. He struck one as a
							man with great inner strength which prompted and sustained his
							external actions.
						</p>
						<p style={{ textAlign: 'justify' }}>
							Munshiji was imbued, from the very start, by a strong sense of the
							expanse and sweep of India-both literally and figuratively. He was
							similarly imbued with a sense of its inherent greatness. His
							mother, through songs and stories, had acquainted him at a very
							young age with the great sages and seers of India's past-Vasishta,
							Vishwamitra, Vyasa.
						</p>
					</div>
					<div className='col-md-4 bg-dark d-flex justify-content-center position-relative order-1 order-md-2'>
						<img
							src={`${process.env.PUBLIC_URL}/media/introduction/our_founder.jpg`}
							alt=''
							style={{
								objectFit: 'contain',
							}}
						/>
						<div
							className='position-absolute text-white py-2'
							style={{
								bottom: '0',
								left: '50%',
								width: '100%',
								transform: 'translate(-50%,0)',
								backgroundColor: 'rgba(0,0,0,0.6)',
							}}
						>
							<h4 className='text-center'>Mr Kanhaiyalal Maneklal Munshi </h4>
							<p className='text-center'>Founder, Bhartiya Vidya Bhavan</p>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}
