import React from 'react';
import { Link } from 'react-router-dom';
import Headings from '../../../utils/Headings';

export default function HowAreWeDifferent() {
	const different = [
		{
			title: 'Legacy',
			text: 'Backed by unparalleled pedigree with the 82 years legacy of Bhartiya Vidya Bhavan',
			icon: 'fas fa-dna',
		},
		{
			title: 'Holistic learning',
			text: 'Special attention to personal grooming, soft skills & holistic development of personality',
			icon: 'fab fa-galactic-republic',
		},
		{
			title: 'Curriculum',
			text: 'Our courses are contemporary in line with the industry requirements, backed by AIMA brand',
			icon: 'fab fa-leanpub',
		},
		{
			title: 'Start-up expertise',
			text: 'Programs delivering knowledge on Entrepreneurship & MSMEs',
			icon: 'fas fa-hourglass-start',
		},
		{
			title: 'Infrastructure',
			text: 'State of art infrastructure & smart classrooms probably one of the best in Mumbai',
			icon: 'fas fa-building ',
		},
		{
			title: 'Certification',
			text: 'AICTE approved certification. Centre for Management Education (CME) under the aegis of AIMA runs the AICTE approved program under ODL mode',
			icon: 'fas fa-certificate',
		},
		{
			title: 'Our Inspiration',
			text: 'We have the role model of SPJIMR as our sister concern',
			icon: 'fas fa-lightbulb',
		},
		{
			title: 'Skill based program',
			text: 'Well designed modules to benefit the students business knowledge',
			icon: 'fas fa-cogs',
		},
		{
			title: 'Faculty ',
			text: 'Word class faculty drawn from experts of the industry',
			icon: 'fas fa-users',
		},
	];
	const colors = [
		'danger',
		'info',
		'primary',
		'warning',
		'secondary',
		'info',
		'primary',
		'warning',
		'danger',
	];
	return (
		<>
			<div className='text-center mb-5'>
				<Headings className=''>How are we different?</Headings>
				<p className='text-muted'>
					We position ourselves as leaders with the following unique features
				</p>
				<Link to='/introduction/usp' className='text-info'>
					Read more <i className='fas fa-link ms-2'></i>
				</Link>
			</div>
			<div className='row g-1'>
				{different.map((element, index) => {
					return (
						<div className='col-sm-6 col-md-4' key={index}>
							<div className={`home-different p-5 alert-${colors[index]}`}>
								<h3>
									<i className={`${element.icon} me-2`}></i>
									{element.title}
								</h3>
								<p>{element.text}</p>
							</div>
						</div>
					);
				})}
			</div>
		</>
	);
}
