import React from 'react';
import AwardsRecognition from './components/AwardsRecognition';
import Growth from './components/Growth';
import Pedigree from './components/Pedigree';

export default function Awards() {
	return (
		<>
			<AwardsRecognition />
			<Growth />
			<Pedigree />
		</>
	);
}
