export const modulesBase = [
	{
		name: 'MODULE D',
		img: `${process.env.PUBLIC_URL}/media/programs/pgpib/0.jpg`,
		duration: '',
		subjects: [
			'Economics',
			'Accounting',
			'Statistics',
			'Research Methodologies',
			'Communication'
		],
	},
	{
		name: 'MODULE O',
		img: `${process.env.PUBLIC_URL}/media/programs/pgpib/1.jpg`,
		duration: '',
		subjects: [
			'Corporate Communication',
			'Case Writing',
			'WAC',
			'Business Issue Management'
		],
	},
	{
		name: 'MODULE S',
		img: `${process.env.PUBLIC_URL}/media/programs/pgpib/2.jpg`,
		duration: '',
		subjects: [
			'All functional subjects: Marketing, Finance systems, HRD, Operations'
		],
	},
	{
		name: 'MODULE M',
		img: `${process.env.PUBLIC_URL}/media/programs/pgpib/3.jpg`,
		duration: '',
		subjects: [
			'All functional subjects: Marketing, Finance systems, HRD, Operations'
		],
	},
	{
		name: 'MODULE A',
		img: `${process.env.PUBLIC_URL}/media/programs/pgpib/4.jpg`,
		duration: '',
		subjects: [
			'Investment Banking',
			'Mergers and Acquisitions',
			'Capital Market Operations',
			'Investment Advisory'
		],
	},
	{
		name: 'MODULE N',
		img: `${process.env.PUBLIC_URL}/media/programs/pgpib/5.jpg`,
		duration: '',
		subjects: [
			'Mutual Funds and Fixed Income Securities',
			'Equity Research',
			'Financial Valuation and Modelling',
			'Credit Research'
		],
	},
	{
		name: 'MODULE O',
		img: `${process.env.PUBLIC_URL}/media/programs/pgpib/6.jpg`,
		duration: '',
		subjects: [
			'Computer Applications in IB',
			'Financial Valuation and Modelling',
			'Taxation and Money- laundering',
			'Technical Analysis'
		],
	},
	{
		name: 'MODULE S',
		img: `${process.env.PUBLIC_URL}/media/programs/pgpib/7.jpg`,
		duration: '',
		subjects: [
			'Domain Centric subject-course of independent study'
		],
	}
];


export const programHighlights =[{
	desc:'Investment Banking Process know-hows'
},
{
	desc:'Understanding nitty-gritties of Banking operations.'
},
{
	desc:'Product Training- Equities, Bonds, FX, Derivatives, Rates, Commodities & others'
},
{
	desc:'In-depth knowledge on value chain of office operations'
},
{
	desc:'Middle Office Operations: Trade Capture/ Execution/ Validation/ Reconciliation'
},
{
	desc:'Back Office Operations: Trade Confirmation & settlements, Trade reporting, Brokerage'
},
{
	desc:'KYC Operations: Due diligence & On-boarding of clients'
},
{
	desc:'Day-to-day understanding of Risk, Legal Compliance Operations Case studies and real life examples'
}]
