import React from 'react';
import Headings from '../../../utils/Headings';

export default function EGallery() {

	return (
		<>
		<section className='mb-4' style={{ position: 'relative' }}>
				<img
					//src={`${process.env.PUBLIC_URL}/media/home/campus.png`}
					src={`${process.env.PUBLIC_URL}/media/home/campus.png`}
					alt=''
					style={{
						width: '100%',
						maxHeight: '150px',
						objectFit: 'cover',
						objectPosition: 'center center',
						filter: 'brightness(.3)',
					}}
				/>
				<div
					className='text-center text-white'
					style={{
						position: 'absolute',
						top: '50%',
						left: '50%',
						transform: 'translate(-50%,-50%)',
					}}
				>
					<h1>School Of Entrepreneurship</h1>
				</div>
		</section>
		<section className='mb-5 py-2'>
			<div className='container'>
				{/* <h1 className='text-center mb-5'>School Of Financial Services</h1> */}
				<div className='mx-auto clearfix text-justify' style={{ maxWidth: '1000px' }}>
				</div>
				<div
					className={`mb-5 mx-auto clearfix text-justify`}
					style={{ maxWidth: '1000px' }}>
					<Headings className='mb-5'>Photo Gallery</Headings>
					
				</div>
			</div>
		</section>
		</>
	);
}
