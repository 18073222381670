export const modulesBase = [
	{
		name: 'Module-I',
		img: `${process.env.PUBLIC_URL}/media/programs/management.jpg`,
		duration: '6 Months',
		subjects: [
			'Finance & Management Accounting',
			'Business Statistics',
			'Managerial Economics',
			'Management Functions & OB',
			'Business Communication',
			'Introduction to IT',
			'International Business',
		],
	},
	{
		name: 'Module-II',
		img: `${process.env.PUBLIC_URL}/media/programs/operations.jpg`,
		duration: '6 Months',
		subjects: [
			'Financial Management',
			'Economic & Social Environment',
			'Research Methodology',
			'Human Resource Management',
			'Marketing Management',
			'Operations Management',
			'Strategic Management & Ethics',
		],
	},
	{
		name: 'Module-III',
		img: `${process.env.PUBLIC_URL}/media/programs/marketing.jpg`,
		desc: 'Specialization Module',
		duration: '3 Months',
		subjects: ['Project work to be completed in 3 months’ time'],
	},
];

export const moduleAdditional = [
	{
		name: 'Module-I',
		sections: [
			{
				head: 'Subjects',
				content: [
					'Campus to Corporate Grooming',
					'Business Terms and Business Reading',
				],
			},
			{
				head: 'Workshops',
				content: [
					'Indian Accounting Standards',
					'Indian Economy: Today and Tomorrow',
					'Select industry Studies',
					'Pricing Strategies',
					'Emerging Technologies',
				],
			},
		],
	},
	{
		name: 'Module–II',
		sections: [
			{
				head: 'Subjects',
				content: [
					'Campus to Corporate Grooming - Advanced',
					'Digital Marketing',
				],
			},
			{
				head: 'Workshops',
				content: [
					'Supply Chain Management',
					'Lean Manufacturing',
					'Quality Management',
					'Project Management',
					'Working Capital Management',
					'Introduction to Financial Markets',
				],
			},
		],
	},
	{
		name: 'Module–III',
		sections: [
			{
				head: 'Subjects in Entrepreneurship Specialization',
				content: [
					'Entrepreneurship: Introduction, Legal Framework, Formation of an Enterprise',
					'Attributes of an Entrepreneur',
					'Financing of an Entrepreneurship Enterprise',
					'Marketing of Products',
					'Project Report',
				],
			},
			{
				head: 'Subjects in MSME Specialization',
				content: [
					'MSME Sector: Introduction, Legal Framework',
					'Best Manufacturing Practices',
					'Supply Chain, Logistics and Quality Framework',
					'Raising Finance for an Enterprise',
					'Marketing of Products',
					'Project Report',
				],
			},
		],
	},
];

export const programHighlights = [
	'Holistic development of students',
	'Honing of managerial skills (for different functional disciplines) for effective decision making by resorting to globally used technologies and tools / techniques',
	'Enhancement of critical thinking as well as lateral thinking',
	'Development of research skills',
	'Networking through participation in National/ International Seminars  and Conferences',
	'Best of faculty from academic as well as corporate world',
	'World class pedagogy (case study, gaming, simulation, immersion projects) and corporate workshops',
	'Immersion projects / internships',
	'International tour (optional)',
	'Campus to corporate, total grooming and placement support',
];
