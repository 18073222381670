import React from 'react';
import Headings from '../../../utils/Headings';

export default function Achievements() {
	const achievements = [
		{
			icon: 'building',
			number: 119,
			text: 'CENTRES',
		},
		{
			icon: 'city',
			number: 367,
			text: 'CONSTITUENT INSTITUTIONS',
		},
		{
			icon: 'university',
			number: 200,
			text: 'EDUCATIONAL INSTITUTIONS',
		},
		{
			icon: 'hotel',
			number: 7,
			text: 'OVERSEAS CENTRES',
		},
	];
	return (
		<div className=''>
			<Headings className='mb-4'>Enduring Journey of Bhavans</Headings>
			<div className='home-achievements'>
				<div className='py-5' style={{ backgroundColor: 'rgba(0,0,0,0.5)' }}>
					<div className='container py-5'>
						<div className='row g-5'>
							{achievements.map((object, index) => {
								return (
									<div
										className='col-sm-6 col-md-3 d-flex flex-column align-items-center text-white py-1'
										key={index}
									>
										<i className={`fas fa-${object.icon} h1`}></i>
										<span className='h2 mb-0'>{object.number}</span>
										<span className='h5 text-center'>{object.text}</span>
									</div>
								);
							})}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
