import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import UnderConstruction from '../UnderConstruction';
// import Faculty from './Faculty/Faculty';
import IndustryInterface from './IndustryInterface/IndustryInterface';
import TeachingMethodology from './TeachingMethodology/TeachingMethodology';

export default function PedagogyRoutes() {
	return (
		<>
			<section className='mb-4' style={{ position: 'relative' }}>
				<img
					src={`${process.env.PUBLIC_URL}/media/home/campus.png`}
					alt=''
					style={{
						width: '100%',
						maxHeight: '150px',
						objectFit: 'cover',
						objectPosition: 'center center',
						filter: 'brightness(.3)',
					}}
				/>
				<div
					className='text-center text-white'
					style={{
						position: 'absolute',
						top: '50%',
						left: '50%',
						transform: 'translate(-50%,-50%)',
					}}
				>
					<h1>Pedagogy</h1>
				</div>
			</section>
			{/* <section className='mb-5'>
				<div className='container'>
					<nav>
						<ol className='breadcrumb'>
							<li className={`breadcrumb-item text-uppercase`}>
								<Link to={`/`} className='text-info'>
									home
								</Link>
							</li>
							{makeBreadcrumb(location.pathname)}
						</ol>
					</nav>
				</div>
			</section> */}
			<Switch>
				<Redirect exact from='/pedagogy' to='/pedagogy/faculty' />
				{/* faculty */}
				{/* <Route exact path='/pedagogy/faculty' component={Faculty} /> */}
				<Route
					exact
					path='/pedagogy/teaching_methodology'
					component={TeachingMethodology}
				/>
				<Route
					exact
					path='/pedagogy/industry_interface'
					component={IndustryInterface}
				/>
				<Route component={UnderConstruction} />
			</Switch>
		</>
	);
}
