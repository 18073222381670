import React from 'react';
import Headings from '../../../utils/Headings';

export default function Mentors() {
	return (
		<div className='container py-5'>
			<div className='mb-5 text-center mx-auto' style={{ maxWidth: '600px' }}>
				<Headings>Our Mentor</Headings>
				<p className='text-muted'>
					“A mentor is someone who sees more talent and ability within you, than
					you see in yourself, and helps bring it out of you.”
				</p>
			</div>
			<div className='row g-5'>
				<div className='col-12'>
					<div
						className='card mx-auto'
						style={{ width: '800px', maxWidth: '100%' }}
					>
						<div className='d-flex align-items-center justify-content-center alert-warning flex-wrap flex-md-nowrap'>
							<div className=''>
								<img
									src={`${process.env.PUBLIC_URL}/media/teachers/sengupta.png`}
									alt=''
									style={{
										height: '100%',
										objectFit: 'cover',
										maxHeight: '260px',
									}}
									className='me-2'
								/>
							</div>
							<div className=''>
								<div className='card-body'>
									<h4 className='card-title mb-1'>Dr A K Sen Gupta</h4>
									<h6 className='text-muted mb-3'>
										Hon. Dean and Hon. Director
									</h6>
									<p className='card-text'>
										Former Director, S P Jain Institute of Management & Research
										(SPJIMR), Mumbai and Former Director, SIES College of Manage
										Studies (SIESCOMS), Navi Mumbai.
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>

				{/* <div className='col-sm-6 col-lg-3'>
					<div className='d-flex flex-column align-items-center'>
						<img
							src={`${process.env.PUBLIC_URL}/media/teachers/harish.png`}
							className='mb-2'
							alt=''
							style={{ height: '200px' }}
						/>
						<h4 className='text-center'>Mr Harish Aiyer</h4>
					</div>
				</div>
				<div className='col-sm-6 col-lg-3'>
					<div className='d-flex flex-column align-items-center'>
						<img
							src={`${process.env.PUBLIC_URL}/media/teachers/aparna2.jpeg`}
							className='mb-2'
							alt=''
							style={{ height: '200px' }}
						/>
						<h4 className='text-center'>Mrs Aparna Ramachandra</h4>
					</div>
				</div>
				<div className='col-sm-6 col-lg-3'>
					<div className='d-flex flex-column align-items-center'>
						<img
							src={`${process.env.PUBLIC_URL}/media/teachers/sona.jpeg`}
							className='mb-2'
							alt=''
							style={{ height: '200px' }}
						/>
						<h4 className='text-center'>Mrs Sona Raman</h4>
					</div>
				</div>
				<div className='col-sm-6 col-lg-3'>
					<div className='d-flex flex-column align-items-center'>
						<img
							src={`${process.env.PUBLIC_URL}/media/teachers/venkat2.jpeg`}
							className='mb-2'
							alt=''
							style={{ height: '200px' }}
						/>
						<h4 className='text-center'>Mr Venkat Raman</h4>
					</div>
				</div> */}
			</div>
		</div>
	);
}
