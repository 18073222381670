import React from 'react';
import { Link } from 'react-router-dom';
import { modulesBase, programHighlights } from './constants';

export default function Pgpba() {
	return (
		<section className='mb-5 py-5'>
			<div className='container'>
				<div className='mb-5'>
					<h1 className='mb-5 text-center'>PGP Business Analytics</h1>
					<dl className='row'>
						<dt className='col-sm-2 offset-xl-1'></dt>
						<dd className='col-sm-10 col-xl-8 text-right'>
							<img
								src={`${process.env.PUBLIC_URL}/media/rise.png`}
								alt=''
								style={{ width: '100%', maxWidth: '180px', objectFit: 'contain' }}
								className='float-end ms-4 mb-3'
							/>
						</dd>
						<dt className='col-sm-2 offset-xl-1'>About PGP Business Analytics:</dt>
						<dd className='col-sm-10 col-xl-8 text-justify'>
							<p>
							PG Program in Business Analytics is powered by RISE, in collaboration with KPMG in India, for students and working professionals who want to foray into Analytics. Our course has been co-designed with industry leaders through the practice of advanced concepts to ensure that you are able to make better predictions and decisive business strategies. The course enables you to grasp current and relevant business analytics concepts used in various industries like supply chain, finance, marketing and HR through assignments, sessions, case studies, capstone projects and internships.
							</p>
						</dd>
						<dt className='col-sm-2 offset-xl-1'>Eligibility:</dt>
						<dd className='col-sm-10 col-xl-8 text-justify'>
							<ul>
								<li>A candidate must hold a graduate/ post graduate degree with a minimum of 50% or equivalent CGPA in the qualifying degree.</li>
								<li>Students pursuing their final year graduation/post-graduation can apply for the course*.</li>
								<li>Working professionals with experience in managing businesses, operations, strategy, finance, HR, business development, and marketing.</li>
								<li>Graduates who are keen in, and want to foray into, Business Analytics.</li>
								<li>Professionals who want to up-skill and re-skill to boost their career growth.</li>
								<li>Students and professionals who want a career-switch and to gain a competitive edge. (*Program certificate will be provided only after completion of the graduation/post- graduation with the criteria mentioned above.)</li>
							</ul>
						</dd>
						<dt className='col-sm-2 offset-xl-1'>Academic Duration:</dt>
						<dd className='col-sm-10 col-xl-8 text-justify'>
							<p>11 Months</p>
						</dd>
						<dt className='col-sm-2 offset-xl-1'>Course Fees:</dt>
						<dd className='col-sm-10 col-xl-8 text-justify'>
							<p>2,00,000/- inclusive of GST</p>
						</dd>
						<dt className='col-sm-2 offset-xl-1'>Academic Curriculum: </dt>
						<dd className='col-sm-10 col-xl-8'>
							<div className='mb-4'>
								<p className='fw-bold'>Module wise break-up of the Program</p>
								<div className='row g-4 g-lg-5'>
									{modulesBase.map((module, index) => {
										return (
											<div className='col-lg-6' key={index}>
												<div
													className='card alert-info'
													style={{ height: '100%' }}
												>
													<img
														src={module.img}
														alt=''
														style={{
															height: '200px',
															objectFit: 'cover',
															filter: 'brightness(.9)',
														}}
													/>
													<div className='card-body'>
														<div className='d-flex justify-content-between align-items-start mb-2'>
															<div className=''>
																<h5 className='card-title m-0'>
																	{module.name}
																</h5>
																{module.desc && (
																	<span className='text-muted'>
																		{module.desc}
																	</span>
																)}
															</div>
															
														</div>
														<ul
															className='card-text list-unstyled fa-ul'
															style={{ marginLeft: '1.3em' }}
														>
															{module.subjects.map((subject, subjectIndex) => {
																return (
																	<li className='mb-1' key={subjectIndex}>
																		<i className='fas fa-caret-right fa-li'></i>
																		{subject}
																	</li>
																);
															})}
														</ul>
													</div>
												</div>
											</div>
										);
									})}
								</div>
							</div>
						</dd>
						<dt className='col-sm-2 offset-xl-1'>Program Highlights:</dt>
						<dd className='col-sm-10 col-xl-8'>
							<ul>
								{programHighlights.map((module, index) => {
									return (
										<li>
											<h4>{module.title}</h4>
											<p>{module.desc}</p>
										</li>
									)
								})}
							</ul>
						</dd>
						<dt className='col-sm-2 offset-xl-1'></dt>
						<dd className='col-sm-10 col-xl-8'>
							<div className='d-flex'>
								<a
									href='/media/brochure/rise.pdf'
									download='brochure.pdf'
									className='btn btn-primary me-3'
								>
									Download Brochure
									<i className='fas fa-file-download ms-1'></i>
								</a>
								<Link
									to={{ pathname: 'https://admissions.kmmias.org/register' }}
									target='_blank'
									className='btn btn-primary'
								>
									Apply Now
									<i className='fas fa-external-link-alt ms-1'></i>
								</Link>
							</div>
						</dd>
					</dl>
				</div>
			</div>
		</section>
	);
}
