import React from 'react';
import Headings from '../../../utils/Headings';

export default function Recruiters() {
	const recruiters = [
		{
			college: 'SPJMIR, Mumbai',
			companies: [
				'Bain & Co.',
				'Boston Consulting Group',
				'Deloitte USI',
				'Ernest and Young',
				'Hindustan Unilever',
				'Amazon',
				'American Express',
				'Barclays',
			],
		},
		{
			college: 'SPCE, Mumbai',
			companies: [
				'Jacobs Engineering',
				'Infosys (Non Core)',
				'L&T Construction',
				'Reliance Industries',
				'Grindwell Norton (Saint-Gobain Group Company)',
			],
		},
		{
			college: 'BCCM, Bhubaneswar',
			companies: [
				'ICICI Bank',
				'Amazon',
				'Paytm',
				'Just Dial',
				'Byjus',
				'Britannia Industries',
				'Pantaloons',
				'Tech Mahindra',
				'Wipro',
			],
		},
		{
			college: 'Bhavans Royal Institute of Management , Kochi',
			companies: [
				'Amul',
				'Reliance Retail',
				'Tesco Bank',
				'TCS',
				'Honda',
				'IFB',
				'KPMG',
				'Lenovo ',
			],
		},
	];
	return (
		<div className='container'>
			<div className='text-center mx-auto mb-5' style={{ maxWidth: '600px' }}>
				<Headings className=''>Recruiters at Bhavans</Headings>
				<p className='text-muted'>
					Placement received under our sister Institutions offering management
					education
				</p>
			</div>
			<div className='row g-3'>
				{recruiters.map((recruiter, index) => {
					return (
						<div className='col-sm-6 col-md-3' key={index}>
							<div
								className={`card pt-3 pb-3 alert-primary`}
								style={{ height: '100%' }}
							>
								<div className='card-body'>
									<h5 className='card-title text-center text-primary mb-3'>
										{recruiter.college}
									</h5>
									<ul className='card-text list-unstyled px-3'>
										{recruiter.companies.map((company, cindex) => {
											return <li key={cindex}>{company}</li>;
										})}
									</ul>
								</div>
							</div>
						</div>
					);
				})}
			</div>
		</div>
	);
}
