import React from 'react';

export default function Headings({ className, children, align, border }) {
	return (
		<div className={`text-${align || 'center'}`}>
			<h1 className={`${className}`} style={{ display: 'inline-block' }}>
				{children}
				<div
					className={`${
						border === 'full' ? 'w-100' : 'mx-auto w-50'
					} rounded-pill bg-warning mt-2`}
					style={{ height: '4px' }}
				></div>
			</h1>
		</div>
	);
}
