export const programHighlights = [
	'Critical thinking',
	'Creative / out of the box thinking',
	'Entrepreneurship including Social Entrepreneurship',
	'Problem Solving',
	'Risk Mitigation',
	'Data Analytics',
	'Communication and total Personality Development',
	'Emotional intelligence',
	'World Class Manufacturing',
	'Quality Management',
	'New generation technologies like AI, ML, Virtual Reality, among others',
];
