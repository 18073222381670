import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { moduleAdditional, modulesBase, programHighlights } from './constants';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import ReCAPTCHA from 'react-google-recaptcha';
import axios from 'axios';
import { render } from 'react-dom';
import { useModal } from 'react-hooks-use-modal';


export default function Pgdmerp() {

	const [alertType, setalertType] = useState('info');
	const [imageFile, setimageFile] = useState('');
	const [initialValues, setinitialValues] = useState({
		name: '',
		dob:'',
		gender:'',
		email: '',
		fathername: '',
		mothername: '',
		file:''
	});

	const validationSchema = Yup.object().shape({
		name: Yup.string().required().label('Name'),
		fathername: Yup.string().required().label('Father Name'),
		mothername: Yup.string().required().label('Mother Name'),
		dob: Yup.string().required().label('Date Of Birth'),
		gender: Yup.string().required().label('Gender'),
		email: Yup.string().email().required().label('Email'),
		// responseKey: Yup.string()
		// 	.required('Please verify you are not a robot')
		// 	.label('Captcha'),
	});

	const formik = useFormik({
		initialValues,
		validationSchema,
		onSubmit: (values, { setStatus, setErrors }) => {
			if(imageFile==''){
				setalertType('danger');
				setStatus(
					'Attachment is required'
				);
				return false;
			}
			let k = JSON.parse(JSON.stringify(values))
			k['file']=imageFile;
			var formData = new FormData();
			for (const [key, value] of Object.entries(k)) {
				formData.append(key, value);
			}
			return axios
				.post(`${process.env.REACT_APP_BASE_API_URL}/registererp`,formData)
				.then((res) => {
					formik.handleReset();
					setalertType('success');
					setStatus(
						'Details saved successfully, our team will contact you shortly'
					);
				})
				.catch((err) => {
					if (err?.response?.data?.errors) {
						setErrors(err.response.data.errors);
					} else {
						setalertType('danger');
						setStatus(err?.response?.data?.message);
					}
				});
		},
	});


	const initialDownloadValues = {
		name: '',
		email:'',
		mobile:''
	};
	const validationDownloadSchema = Yup.object().shape({
		name: Yup.string().required().label('Name'),
		email: Yup.string().email().required().label('Email'),
		mobile: Yup.string().required().label('Mobile'),
		// responseKey: Yup.string()
		// 	.required('Please verify you are not a robot')
		// 	.label('Captcha'),
	});
	const brochureForm = useFormik({
		initialValues:initialDownloadValues,
		validationSchema:validationDownloadSchema,
		onSubmit: (values, { setStatus, setErrors }) => {
			return axios
				.post(`${process.env.REACT_APP_BASE_API_URL}/brochure`, values)
				.then((res) => {
					brochureForm.handleReset();
					setalertType('success');
					setStatus(
						'Thank you.. Brochure has been sent on your emailid'
					);
					setTimeout(() => {
						setStatus(
							null
						);
						close();
					}, 3000);

				})
				.catch((err) => {
					if (err?.response?.data?.errors) {
						setErrors(err.response.data.errors);
					} else {
						setalertType('danger');
						setStatus(err?.response?.data?.message);
					}
				});
		},
	});

	const [Modal, downloadBrochure, close, isOpen] = useModal('root', {
		preventScroll: true,
		closeOnOverlayClick: false
	});

	const changeHandler = (event) => {
		setimageFile(event.target.files[0]);
	};




	return (
		<section className='mb-5 py-5'>
			<Modal>
				<div className="modal fade show" style={{display:'block',top:'20vh'}}>
				  <div className="modal-dialog">
				    <div className="modal-content">
				      <div className="modal-header">
				        <h5 className="modal-title">Download Brochure</h5>
				        <button type="button" className="close" onClick={close}>
				          <span aria-hidden="true">&times;</span>
				        </button>
				      </div>
					  <form onSubmit={brochureForm.handleSubmit}>
				      <div className="modal-body">
							<div className="row">
								<div className='col-sm-12 col-xl-12 text-justify'>
									{brochureForm.status!=null && (
										<div className={`alert alert-${alertType}`} role='alert'>
											{brochureForm.status}
										</div>
									)}
								</div>
								<div className='col-sm-12 col-xl-12 text-justify'>
									<label className='form-label' htmlFor='name'>
											Name
										</label>
										<input
											type='text'
											className={`form-control ${
												brochureForm.errors.name && brochureForm.touched.name && 'is-invalid'
											}`}
											{...brochureForm.getFieldProps('name')}
										/>
										{brochureForm.errors.name && brochureForm.touched.name && (
											<div className='invalid-feedback'>{brochureForm.errors.name}</div>
										)}
								</div>
								<div className='col-sm-12 col-xl-12 text-justify'>
									<label className='form-label' htmlFor='email'>
											Email
										</label>
										<input
											type='email'
											className={`form-control ${
												brochureForm.errors.email && brochureForm.touched.email && 'is-invalid'
											}`}
											{...brochureForm.getFieldProps('email')}
										/>
										{brochureForm.errors.email && brochureForm.touched.email && (
											<div className='invalid-feedback'>{brochureForm.errors.email}</div>
										)}
								</div>
								<div className='col-sm-12 col-xl-12 text-justify'>
									<label className='form-label' htmlFor='mobile'>
											Mobile
										</label>
										<input
											type='text'
											className={`form-control ${
												brochureForm.errors.mobile && brochureForm.touched.mobile && 'is-invalid'
											}`}
											{...brochureForm.getFieldProps('mobile')}
										/>
										{brochureForm.errors.mobile && brochureForm.touched.mobile && (
											<div className='invalid-feedback'>{brochureForm.errors.mobile}</div>
										)}
								</div>
								<ReCAPTCHA
								sitekey={process.env.REACT_APP_GOOGLE_CAPTCHA_SITEKEY}
								onChange={(val) => brochureForm.setFieldValue('responseKey', val)}
								onExpired={() => brochureForm.setFieldValue('responseKey', '')}
							/>
							</div>
				      </div>
				      <div className="modal-footer">
					  		<button className='btn btn-success' disabled={brochureForm.isSubmitting}>
								{brochureForm.isSubmitting && (
									<span
										className='spinner-border spinner-border-sm'
										role='status'
										aria-hidden='true'
									></span>
								)}
								Download <i className='fas fa-save'></i>
							</button>
				      </div>
					  </form>
				    </div>
				  </div>
				</div>
    		</Modal>
			<div className='container'>
				<div className='mb-5'>
					<h1 className='mb-5 text-center'>PGDM (ERP)</h1>
					<dl className='row'>
						<dt className='col-sm-2 offset-xl-1'>In association with</dt>
						<dd className='col-sm-10 col-xl-8 mb-4'>
						<img src={`${process.env.PUBLIC_URL}/media/ntw.jpg`} style={{height:'60px'}} />
						</dd>
						<dt className='col-sm-2 offset-xl-1'>Admission Session:</dt>
						<dd className='col-sm-10 col-xl-8 text-justify'>
							<p>2022-2024</p>
						</dd>
						<dt className='col-sm-2 offset-xl-1'>Application Fee:</dt>
						<dd className='col-sm-10 col-xl-8 text-justify'>
							<p>1450/-</p>
						</dd>
						<dt className='col-sm-2 offset-xl-1'>
							Program Overview:
						</dt>
						<dd className='col-sm-10 col-xl-8 text-justify'>
							<p>
								<strong>
									AIMA’s Post Graduate Diploma in Management ERP- PGDM (ERP)
								</strong>
								&nbsp;program is basically a combination of Management Studies and ERP. Unlike other short term diploma programs that offer PGDERP, the PGDMERP program gives a dual
specialization certificate. The management certification is given by AIMA which is AICTE approved. The ERP certification given by Bhavan’s KMMIAS in association with Netweaver Technovations.
							</p>
						</dd>
						
						<dt className='col-sm-2 offset-xl-1'>
						Eligibility criteria:
						</dt>
						<dd className='col-sm-10 col-xl-8 text-justify'>
							<p>
							The PGDM program is specially designed for fresh graduates, working professionals, individuals from
							business families, managers of start-ups, entrepreneurs and graduates who plan to work after they
							pursue PGDM. All candidates should have minimum graduate degree in any discipline from a UGC
							recognized University or equivalent.
							</p>
						</dd>
						<dt className='col-sm-2 offset-xl-1'>
						Why choose PGDM ERP?
						</dt>
						<dd className='col-sm-10 col-xl-8 text-justify'>
							<p>
							Let’s first understand what ERP is and why it is important in this rapidly growing business world.
							</p>
							<p>ERP stands for Enterprise resource planning, it is a software which business uses for their data management. System Application and Product in data processing in short SAP is an ERP software which is used by more than 70% of businesses currently by completing the SAP global certification you will unlock job opportunities in 60,000+ companies.</p>
							<p>In this program, the candidates will be provided with in depth and advanced skills in SAP ECC 6.0, SAP S/4HANA (next gen SAP ERP) which has approximately 70% market share globally. After completion of SAP ERP, candidate will also be provided with corporate training and mock interviews.</p>
						</dd>
						<dt className='col-sm-2 offset-xl-1'>
						About SAP ERP
						</dt>
						<dd className='col-sm-10 col-xl-8 text-justify'>
							<p>
							SAP today is the global leader in business applications and analytics software, a market leader in digital commerce, and the world’s largest enterprise cloud company with over 130 million cloud users. We have 345,000 customers in more than 180 countries, from small companies (80% of our customers are small and mid-size enterprises) to global organizations (87% of the Forbes Global 2000 companies run SAP software). Globally, more than 76% of all business transactions worldwide touch an SAP software system.
							</p>
						</dd>
						<dt className='col-sm-2 offset-xl-1'>
						Admission Process
						</dt>
						<dd className='col-sm-10 col-xl-8 text-justify'>
						<img src={`${process.env.PUBLIC_URL}/media/erp_process.png`} style={{height:'200px',width:'100%'}} />
						</dd>
						<dt className='col-sm-2 offset-xl-1'>Brochure:</dt>
						<dd className='col-sm-10 col-xl-8'>
							<button className="btn btn-primary" onClick={downloadBrochure}>Download</button>
						</dd>
						<dt className='col-sm-2 offset-xl-1'>How to Apply:</dt>
						<dd className='col-sm-10 col-xl-8'>
							
						</dd>
					</dl>
				</div>
				<form className='col-md-6  offset-xl-3 col-sm-12' id='form' onSubmit={formik.handleSubmit}>
				<div className="row">
					<div className='col-sm-12 col-xl-12 text-center'>
						<h4>Registration Form</h4>
					</div>
					<div className='col-sm-12 col-xl-12 text-justify'>
						{formik.status!=null && (
							<div className={`alert alert-${alertType}`} role='alert'>
								{formik.status}
							</div>
						)}
					</div>
					<div className='col-sm-12 col-xl-12 text-justify'>
						<label className='form-label' htmlFor='name'>
								Applicant Name
							</label>
							<input
								type='text'
								className={`form-control ${
									formik.errors.name && formik.touched.name && 'is-invalid'
								}`}
								{...formik.getFieldProps('name')}
							/>
							{formik.errors.name && formik.touched.name && (
								<div className='invalid-feedback'>{formik.errors.name}</div>
							)}
					</div>
					<div className='col-sm-12 col-xl-12 text-justify'>
						<label className='form-label' htmlFor='dob'>
								Date Of Birth
							</label>
							<input
								type='date'
								className={`form-control ${
									formik.errors.dob && formik.touched.dob && 'is-invalid'
								}`}
								{...formik.getFieldProps('dob')}
							/>
							{formik.errors.dob && formik.touched.dob && (
								<div className='invalid-feedback'>{formik.errors.dob}</div>
							)}
					</div>
					<div className='col-sm-12 col-xl-12 text-justify'>
						<label className='form-label' htmlFor='gender'>
								Gender
							</label>
							<select
								className={`form-control ${
									formik.errors.gender && formik.touched.gender && 'is-invalid'
								}`}
								{...formik.getFieldProps('gender')}
							>
								<option value="">Select Gender</option>
								<option value="male">Male</option>
								<option value="female">Female</option>
							</select>
							{formik.errors.gender && formik.touched.gender && (
								<div className='invalid-feedback'>{formik.errors.gender}</div>
							)}
					</div>
					<div className='col-sm-12 col-xl-12 text-justify'>
						<label className='form-label' htmlFor='email'>
								Email
							</label>
							<input
								type='email'
								className={`form-control ${
									formik.errors.email && formik.touched.email && 'is-invalid'
								}`}
								{...formik.getFieldProps('email')}
							/>
							{formik.errors.email && formik.touched.email && (
								<div className='invalid-feedback'>{formik.errors.email}</div>
							)}
					</div>
					<div className='col-sm-12 col-xl-12 text-justify'>
						<label className='form-label' htmlFor='fathername'>
								Father's Name
							</label>
							<input
								type='text'
								className={`form-control ${
									formik.errors.fathername && formik.touched.fathername && 'is-invalid'
								}`}
								{...formik.getFieldProps('fathername')}
							/>
							{formik.errors.fathername && formik.touched.fathername && (
								<div className='invalid-feedback'>{formik.errors.fathername}</div>
							)}
					</div>
					<div className='col-sm-12 col-xl-12 text-justify'>
						<label className='form-label' htmlFor='mothername'>
								Mother's Name
							</label>
							<input
								type='text'
								className={`form-control ${
									formik.errors.mothername && formik.touched.mothername && 'is-invalid'
								}`}
								{...formik.getFieldProps('mothername')}
							/>
							{formik.errors.mothername && formik.touched.mothername && (
								<div className='invalid-feedback'>{formik.errors.mothername}</div>
							)}
					</div>
					<div className="row">
						<div className='col-sm-12 col-xl-12 text-justify pt-4'>
							<h4>Bank Details</h4>
							<ul>
								<li><strong>Bank Name :</strong> Axis Bank</li>
								<li><strong>Branch Name :</strong> Vashi</li>
								<li><strong>Account Type :</strong> Current Account</li>
								<li><strong>Account Number :</strong> 921010029470757</li>
								<li><strong>IFSC Code:</strong> UTIB0000072</li>
							</ul>
						</div>
					</div>
					<div className="row">
						<div className='col-sm-12 col-xl-12 text-justify pt-4'>
							<label className='form-label' htmlFor='file'>
								Attachment <small>(Screenshot of transaction)</small>
							</label>
							<input
								type='file'
								name='file'
								onChange={changeHandler}
								className={`form-control ${
									formik.errors.file && formik.touched.file && 'is-invalid'
								}`}
							/>
							
						</div>
					</div>
					<div className='d-flex align-items-center justify-content-center mt-2'>
							<ReCAPTCHA
								sitekey={process.env.REACT_APP_GOOGLE_CAPTCHA_SITEKEY}
								onChange={(val) => formik.setFieldValue('responseKey', val)}
								onExpired={() => formik.setFieldValue('responseKey', '')}
							/>
						</div>
						{formik.errors.responseKey && (
							<div className='text-danger text-center'>
								{formik.errors.responseKey}
							</div>
						)}
						<button className='btn btn-success mt-3' disabled={formik.isSubmitting}>
							{formik.isSubmitting && (
								<span
									className='spinner-border spinner-border-sm'
									role='status'
									aria-hidden='true'
								></span>
							)}
							Submit <i className='fas fa-save'></i>
						</button>
				</div>
				</form>
			</div>
		</section>
	);
}
