import React from 'react';
import Headings from '../../../utils/Headings';

export default function AboutUs() {
	return (
		<div className='row g-0'>
			<div className='col-md-6'>
				<img
					src={`${process.env.PUBLIC_URL}/media/home/campus_2.jpg`}
					alt=''
					style={{ width: '100%', height: '100%', objectFit: 'cover' }}
				/>
			</div>
			<div className='col-md-6 p-5 alert-primary d-flex flex-column justify-content-center'>
				<Headings className='mb-3' align='left' border='full'>
					Introduction
				</Headings>
				<p>
					Bharatiya Vidya Bhavan is one of the premier and coveted educational
					institutions in India. It has its foundation from Kulapati Dr K M
					Munshi, a freedom fighter and educational leader in 1938 with
					blessings from Mahatma Gandhi.
				</p>
				<p>
					K M Munshi Institute of Advanced Studies (KMMIAS) located at Bhavan’s
					Navi Mumbai Kendra, Vashi is one of the latest institutes in the
					family of Bhavans. KMMIAS has been created to prepare students by
					skilling &amp; re-skilling them and making them future ready for jobs
					of the 21st century.
				</p>
			</div>
		</div>
	);
}
