import React, { Component,useState,useEffect } from 'react';
import BlogService from './blog.service';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useParams } from 'react-router';
import Headings from '../../utils/Headings';
import { Link } from 'react-router-dom';

export default function BlogView() {
		const [alertType, setalertType] = useState('info');
		const { id } = useParams();
		const [currentBlog, setcurrentBlog] = useState({
			id: 0,
			  title: "",
			  body: "",
			  img:'', 
			  date: "",
			  latestBlogs:[],
			  commentList:[],
			  author:"Rahul Mhatre"
			});
		const initialValues = {
			commentor: '',
			body: '',
			blog_id:id
		};
		const validationSchema = Yup.object().shape({
			commentor: Yup.string().required().label('Name'),
			body: Yup.string()
				.required()
				.min('50', 'Message should be a minimum of 50 characters')
				.max('1000', 'Message cannot be longer than 1000 characters')
				.label('Message')
		});
		const formik = useFormik({
			initialValues,
			validationSchema,
			onSubmit: (values, { setStatus, setErrors }) => {
				return BlogService.postcomment(values)
					.then((res) => {
						formik.handleReset();
						setalertType('success');
						setStatus(
							'Comment submitted successfully.'
						);
						let c = currentBlog.commentList;
						c.push({commentor:values.commentor,body:values.body})
						currentBlog.commentList = c;
					})
					.catch((err) => {
						if (err?.response?.data?.errors) {
							setErrors(err.response.data.errors);
						} else {
							setalertType('danger');
							setStatus(err?.response?.data?.message);
						}
					});
			},
		});

		const DATE_OPTIONS = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };

		useEffect(()=>{
			BlogService.getView(id)
			.then((response) => {
				setcurrentBlog(response.data);
			})	
			.catch((e) => {
				setcurrentBlog({});
			  console.log(e);
			});
		},[])
	return (
		<section className='my-5 py-5'>
			<div className='container text-left mb-5'>
				<Link className="btn btn-primary" to={'/blogs'}>Back</Link>				
            </div>
			<div className='container'>
				{/* <Headings className='mb-5'>Blogs</Headings> */}
            </div>
				<div className="container">
					<div className="row">
        				<div className="col-md-9 mb40">
        			    <article>
        			        <div className="post-content">
        			            <h3>{currentBlog.title}</h3>
        			            <ul className="post-meta list-inline">
        			                <li className="list-inline-item">
        			                    <i className="fa fa-user-circle-o"></i> Author - {currentBlog.author}        			                </li>
        			                <li className="list-inline-item">
        			                    <i className="fa fa-calendar-o"></i>, Date - {(new Date(currentBlog.createdAt)).toLocaleDateString('en-US', DATE_OPTIONS)}
        			                </li>
        			            </ul>
								<img src={'http://localhost:5000/'+currentBlog.img} />
        			            <p dangerouslySetInnerHTML={ {__html: currentBlog.body} }></p>
        			            {/* <ul className="list-inline share-buttons">
        			                <li className="list-inline-item">Share Post:</li>
        			                <li className="list-inline-item">
        			                    <a href="#" className="social-icon-sm si-dark si-colored-facebook si-gray-round">
        			                        <i className="fa fa-facebook"></i>
        			                        <i className="fa fa-facebook"></i>
        			                    </a>
        			                </li>
        			                <li className="list-inline-item">
        			                    <a href="#" className="social-icon-sm si-dark si-colored-twitter si-gray-round">
        			                        <i className="fa fa-twitter"></i>
        			                        <i className="fa fa-twitter"></i>
        			                    </a>
        			                </li>
        			                <li className="list-inline-item">
        			                    <a href="#" className="social-icon-sm si-dark si-colored-linkedin si-gray-round">
        			                        <i className="fa fa-linkedin"></i>
        			                        <i className="fa fa-linkedin"></i>
        			                    </a>
        			                </li>
        			            </ul> */}
        			            <hr className="mb40" />
        			            <h4 className="mb40 text-uppercase font500">Comments</h4>
								{
									currentBlog.commentList && currentBlog.commentList.map((item, index) => (
        			            		<div className="media mb40" key={index}>
        			            		    <i className="d-flex mr-3 fa fa-user-circle-o fa-3x"></i>
        			            		    <div className="media-body">
        			            		        <h5 className="mt-0 font400 clearfix">
        			            		                    {item.commentor}</h5> {item.body}
        			            		    </div>
											<hr/>
        			            		</div>
								))}
        			            <h4 className="mb40 text-uppercase font500">Post a comment</h4>
        			            <form className='col-md-6' id='form' onSubmit={formik.handleSubmit}>
									{formik.status && (
										<div class={`alert alert-${alertType}`} role='alert'>
											{formik.status}
										</div>
									)}
									<div className='mb-3'>
										<label className='form-label' htmlFor='name'>
											Name
										</label>
										<input
											type='text'
											className={`form-control ${
												formik.errors.commentor && formik.touched.commentor && 'is-invalid'
											}`}
											{...formik.getFieldProps('commentor')}
										/>
										{formik.errors.commentor && formik.touched.commentor && (
											<div className='invalid-feedback'>{formik.errors.commentor}</div>
										)}
									</div>
									<div className='mb-3'>
										<label className='form-label' htmlFor=''>
											Comment ({formik.values.body.length}/1000)
										</label>
										<textarea
											rows='5'
											className={`form-control ${
												formik.errors.body &&
												formik.touched.body &&
												'is-invalid'
											}`}
											{...formik.getFieldProps('body')}
											style={{ resize: 'none' }}
										></textarea>
										{formik.errors.body && formik.touched.body && (
											<div className='invalid-feedback'>{formik.errors.body}</div>
										)}
									</div>
									<button className='btn btn-success' disabled={formik.isSubmitting}>
										{formik.isSubmitting && (
											<span
												class='spinner-border spinner-border-sm'
												role='status'
												aria-hidden='true'
											></span>
										)}
										Submit <i className='fas fa-paper-plane'></i>
									</button>
								</form>
							</div>
        			    </article>
        			</div>
        			{/* <div className="col-md-3 mb40">
        			    <div>
        			        <h4 className="sidebar-title">Latest Blogs</h4>
        			        <ul className="list-unstyled">
								{
									currentBlog.latestBlogs && currentBlog.latestBlogs.map((item, index) => (
        			            	<li className="media" key={index}>
        			            	    <img className="d-flex mr-3 img-fluid" width="64" src={'http://localhost:5000/'+item.img} alt="Generic placeholder image" />
        			            	    <div className="media-body">
        			            	        <h5 className="mt-0 mb-1"><a href="#">{item.title}</a></h5> {item.date}
        			            	    </div>
        			            	</li>
								))}
        			        </ul>
        			    </div>
        			</div> */}
    				</div>
                </div>
		</section>
		)
}
