export const infrastructure = [
	{
		image: `${process.env.PUBLIC_URL}/media/infrastructure/wifi_campus.jpg`,
		icon: 'fas fa-wifi',
		head: 'Wifi Campus',
		body: 'Lorem ipsum dolor sit amet consectetur adipisicing elit espanciur.',
	},
	{
		image: `${process.env.PUBLIC_URL}/media/infrastructure/wifi_campus.jpg`,
		icon: 'fas fa-chart-area',
		head: 'Total sqft area',
		body: 'Lorem ipsum dolor sit amet consectetur adipisicing elit espanciur.',
	},
	{
		image: `${process.env.PUBLIC_URL}/media/infrastructure/wifi_campus.jpg`,
		icon: 'fas fa-video',
		head: 'Seminar hall',
		body: 'Lorem ipsum dolor sit amet consectetur adipisicing elit espanciur.',
	},
	{
		image: `${process.env.PUBLIC_URL}/media/infrastructure/wifi_campus.jpg`,
		icon: 'fas fa-bullhorn',
		head: 'Auditorium',
		body: 'Lorem ipsum dolor sit amet consectetur adipisicing elit espanciur.',
	},
	{
		image: `${process.env.PUBLIC_URL}/media/infrastructure/wifi_campus.jpg`,
		icon: 'fas fa-microphone-alt',
		head: 'Atrium',
		body: 'Lorem ipsum dolor sit amet consectetur adipisicing elit espanciur.',
	},
	{
		image: `${process.env.PUBLIC_URL}/media/infrastructure/wifi_campus.jpg`,
		icon: 'fas fa-users',
		head: 'Special Board Room for GD',
		body: 'Lorem ipsum dolor sit amet consectetur adipisicing elit espanciur.',
	},
	{
		image: `${process.env.PUBLIC_URL}/media/infrastructure/wifi_campus.jpg`,
		icon: 'fas fa-train',
		head: 'Easy proximity to station',
		body: 'Lorem ipsum dolor sit amet consectetur adipisicing elit espanciur.',
	},
	{
		image: `${process.env.PUBLIC_URL}/media/infrastructure/wifi_campus.jpg`,
		icon: 'fas fa-hotel',
		head: 'Assistance for hostel',
		body: 'Lorem ipsum dolor sit amet consectetur adipisicing elit espanciur.',
	},
	{
		image: `${process.env.PUBLIC_URL}/media/infrastructure/wifi_campus.jpg`,
		icon: 'fas fa-chalkboard-teacher',
		head: 'Smart Classroom',
		body: 'Lorem ipsum dolor sit amet consectetur adipisicing elit espanciur.',
	},
	{
		image: `${process.env.PUBLIC_URL}/media/infrastructure/wifi_campus.jpg`,
		icon: 'fas fa-book-open',
		head: 'Library',
		body: 'Lorem ipsum dolor sit amet consectetur adipisicing elit espanciur.',
	},
	{
		image: `${process.env.PUBLIC_URL}/media/infrastructure/wifi_campus.jpg`,
		icon: 'fas fa-laptop',
		head: 'Computer Lab',
		body: 'Lorem ipsum dolor sit amet consectetur adipisicing elit espanciur.',
	},
	{
		image: `${process.env.PUBLIC_URL}/media/infrastructure/wifi_campus.jpg`,
		icon: 'fas fa-utensils',
		head: 'Canteen',
		body: 'Lorem ipsum dolor sit amet consectetur adipisicing elit espanciur.',
	},
	{
		image: `${process.env.PUBLIC_URL}/media/infrastructure/wifi_campus.jpg`,
		icon: 'fas fa-basketball-ball',
		head: 'Recreation',
		body: 'Lorem ipsum dolor sit amet consectetur adipisicing elit espanciur.',
	},
	{
		image: `${process.env.PUBLIC_URL}/media/infrastructure/wifi_campus.jpg`,
		icon: 'fas fa-venus-mars',
		head: 'Girls & Boys common room',
		body: 'Lorem ipsum dolor sit amet consectetur adipisicing elit espanciur.',
	},
];
