import http from "./../../utils/http-common";

class BlogService {
  getAll() {
    return http.get("/blogs");
  }

  get(id) {
    return http.get(`/blogs/${id}`);
  }
  getView(id) {
    return http.get(`/blogs/view/${id}`);
  }

  post() {
    return http.post("/blogs");
  }

  postcomment(data) {
    return http.post("/blogs/comment",data);
  }
}

export default new BlogService();
