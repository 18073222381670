import React from 'react';
import Headings from '../../../../utils/Headings';

export default function Growth() {
	const listItems = [
		{
			year: '1938',
			desc: [`Kulapati Munshiji lays the foundation of the Bhavan`],
			foot: 'November 7',
		},
		{
			year: '1952',
			desc: [
				`Dr. Rajendra Prasad lays the foundation-stone of the Bhavan's Delhi Kendra.`,
				`Kulapati Munshiji lays the foundation stone of the new buildings for the Bhavan's college at Andheri, Bombay.`,
			],
		},
		{
			year: '1962',
			desc: [
				`Shri Jawaharlal Nehru, Prime Minister of India, inaugurates the Bhavan's Sardar Patel College of Engineering at Andheri, Bombay.`,
			],
			foot: 'August',
		},
		{
			year: '1965',
			desc: [
				`Shri Y.B. Chavan, Union Defence Minister, inaugurates the Bhavan's Hazarimal Somani College of Arts & Science at Chowpatty, Bombay`,
			],
			foot: 'June',
		},
		{
			year: '1981',
			desc: [
				`Foundation - stone for the Bhavan's Shriyans Prasad Jain Institute at the Bhavan's Campus, Andheri, Bombay, is laid by the Rt. Hon'ble Margaret Thatcher, Prime Minister of United Kingdom. Shri G.D. Birla presided over the function.`,
			],
		},
		{
			year: '2020',
			desc: [
				`Bharatiya Vidya Bhavan’s Navi Mumbai Kendra was inaugurated on October 2, 2020 by I R Khandwala, trustee and Hon & Treasurer executive secretary Dasturji  of Bharatiya Vidya Bhavan`,
			],
			foot: 'October 2',
		},
	];
	return (
		<section
			className='mb-5 py-5'
			id='growth'
			style={{
				backgroundImage:
					'linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),url(../../media/home/achievements.jpg)',
				padding: '5rem 0',
				backgroundAttachment: 'fixed',
				backgroundRepeat: 'no-repeat',
			}}
		>
			<div className='container text-white mb-5'>
				<div className='mx-auto' style={{ maxWidth: '800px' }}>
					<Headings className='mb-4'>Years of growth</Headings>
					<p className='text-center'>
						Bhavan’s today has over 119 Centre’s spread all over India, 200
						educational institutions, 7 Overseas Centre’s and 367 constituent
						institutions.
					</p>
				</div>
			</div>
			<div className='container'>
				<div className='timeline'>
					{listItems.map((item, index) => {
						return (
							<div
								className={`timeline-container timeline-${
									index % 2 === 0 ? 'left' : 'right'
								}`}
								key={index}
							>
								<div className='timeline-content'>
									<div className='mb-3'>
										<h2 className='mb-0'>{item.year}</h2>
										{item.foot && (
											<span className='text-muted'>{item.foot}</span>
										)}
									</div>
									{item.desc.map((desc, descIndex) => {
										return <p key={descIndex}>{desc}</p>;
									})}
								</div>
							</div>
						);
					})}
				</div>
			</div>
		</section>
	);
}
