export const modulesBase = [
	{
		name: 'MODULE 00',
		img: `${process.env.PUBLIC_URL}/media/programs/pgpba/0.jpg`,
		duration: '',
		subjects: [
			'Advance Excel for Analytics'
		],
	},
	{
		name: 'MODULE I',
		img: `${process.env.PUBLIC_URL}/media/programs/pgpba/1.jpg`,
		duration: '',
		subjects: [
			'Basics of Python'
		],
	},
	{
		name: 'Module-II',
		img: `${process.env.PUBLIC_URL}/media/programs/pgpba/2.jpg`,
		duration: '',
		subjects: [
			'SQL for Data Analysis'
		],
	},
	{
		name: 'Module-III',
		img: `${process.env.PUBLIC_URL}/media/programs/pgpba/3.jpg`,
		duration: '',
		subjects: [
			'Business Statistics'
		],
	},
	{
		name: 'Module-IV',
		img: `${process.env.PUBLIC_URL}/media/programs/pgpba/4.jpg`,
		duration: '',
		subjects: [
			'Data Visualization using Power BI and Tableau'
		],
	},
	{
		name: 'Industry Internship',
		img: `${process.env.PUBLIC_URL}/media/programs/pgpba/5.jpg`,
		duration: '',
		subjects: [
			'(4 weeks)'
		],
	},
	{
		name: 'Module-V',
		img: `${process.env.PUBLIC_URL}/media/programs/pgpba/6.jpg`,
		duration: '',
		subjects: [
			'Strategy Analysis'
		],
	},
	{
		name: 'Module-VI',
		img: `${process.env.PUBLIC_URL}/media/programs/pgpba/7.jpg`,
		duration: '',
		subjects: [
			'Time series analysis and forecasting'
		],
	},
	{
		name: 'Module-VII',
		img: `${process.env.PUBLIC_URL}/media/programs/pgpba/8.jpg`,
		duration: '',
		subjects: [
			'Introduction to Additional business tools - SAS,SPSS,ORANGE 3'
		],
	},
	{
		name: 'Module-VIII',
		img: `${process.env.PUBLIC_URL}/media/programs/pgpba/9.jpg`,
		duration: '',
		subjects: [
			'Introduction to Domain Analytics- Finance & Operations, Marketing & Retail, HR'
		],
	},
	{
		name: 'Module-IX',
		img: `${process.env.PUBLIC_URL}/media/programs/pgpba/10.jpg`,
		duration: '',
		subjects: [
			'Advanced Specialization- Finance & Operations / Marketing & Retail / HR'
		],
	},
	{
		name: 'Module-X',
		img: `${process.env.PUBLIC_URL}/media/programs/pgpba/11.jpg`,
		duration: '',
		subjects: [
			'Current Organizational Dynamics/Business Com- munication/Introduction'
		],
	},
	{
		name: 'Industry Internship',
		img: `${process.env.PUBLIC_URL}/media/programs/pgpba/12.jpg`,
		duration: '',
		subjects: [
			'(4 weeks)'
		],
	}
];


export const programHighlights =[{
	title:'Interactive Learning',
	desc:'We employ experiential learning techniques for our students that will allow them to apply their knowledge to real-life professional scenarios. This approach will help students to tackle the most pressing problems that businesses face.'
},{
	title:'Inclusive Mentorship',
	desc:'For an optimal learning experience, we have on-board academicians and industry leaders. They will guide learners on different aspects such as academic issues, industry readiness and career support.'
},{
	title:'Applied Learning',
	desc:'In order to translate classroom learning into real-world application of concepts, learners are given the option to opt for a Capstone Project/Internship at the end of the course, with an industry. It ensures that all students graduate from the program with industry-relevant experience.'
},{
	title:'Placement Guarantee*',
	desc:'KMMIAS-RISE have an extensive network with corporate and placement Guarantee will be provided to all students so that they are placed in terms of their career goals, on their successful completion of the program.'
},{
	title:'Evaluation Format',
	desc:'Students will undergo a comprehensive evaluation process through quizzes, MCQs and assignments to track their progress. It ensures that each learner is receiving adequate support from academic and industry mentors.'
},{
	title:'Extra-Curricular Activities',
	desc:'We have curated an array of extracurricular activities to aid professional development along- side the regular course. Through these, we aim to provide learners with insights into current industry trends and provide networking opportunities with industry leaders.'
},{
	title:'Financial Assistance*',
	desc:'We are offering financial assistance through scholarships and interest free EMI options to all students enrolled in the program.'
},]
