import React from 'react';

export default function Isaca() {
	return (
		<section className='mb-5 py-5'>
			<div className='container'>
				<h1 className='text-center mb-5'>Introduction - ISACA</h1>
				<div
					className='mb-5 mx-auto clearfix text-justify'
					style={{ maxWidth: '800px' }}
				>
					<h5 className='mb-4'>
						ISACA Certification
					</h5>
					<p>ISACA (Information Systems Audit and Control Association) is an independent, nonprofit, global association that engages in the development, adoption and use of globally accepted information system (IS) knowledge and practices. ISACA provides guidance, benchmarks and governance tools for enterprises that use information systems. This program can benefit the applicant by granting them the recognition of the high standard of expertise and skills required to be an information security professional. Following are the list programs offered by KMMIAS and ISACA.</p>
					<p>1. Computing Fundamentals</p>
					<p>2. Networks and Infrastructure Fundamentals</p>
					<p>3. Cybersecurity</p>
					<p>4. Software Development</p>
					<p>5. Data Science</p>
				</div>
			</div>
		</section>
	);
}
