import React from 'react';
import AboutUs from './components/AboutUs';
import Achievements from './components/Achievements';
import Carousel from './components/Carousel';
import HowAreWeDifferent from './components/HowAreWeDifferent';
import Mentors from './components/Mentors';
import ProgramsOffered from './components/ProgramsOffered';
import Recruiters from './components/Recruiters';

export default function Home() {
	return (
		<>
			<section className='mb-5'>
				<Carousel />
			</section>
			<section className='mb-5 py-5 container'>
				<AboutUs />
			</section>
			<section className='mb-5 py-5'>
				<Achievements />
			</section>
			<section className='mb-5 py-5 container'>
				<HowAreWeDifferent />
			</section>
			<section className='mb-5 py-5 bg-dark'>
				<ProgramsOffered />
			</section>
			<section className='mb-5 py-5'>
				<Mentors />
				<Recruiters />
			</section>
			{/* <section className='mb-5 py-5 '>
				<Testimonials />
			</section> */}
		</>
	);
}
