import React from 'react';
import Headings from '../../utils/Headings';
import { infrastructure } from './constants';

export default function Infrastructure() {
	return (
		<section className='my-5 py-5'>
			<div className='container'>
				<Headings className='mb-5'>Infrastructure</Headings>
				<div className='row g-5'>
					{infrastructure.map((content, contentIndex) => {
						return (
							<div className='col-sm-6 col-md-4' key={contentIndex}>
								<div className='card border-0'>
									{/* <img src={content.image} alt='' /> */}
									<div className='card-body alert-primary rounded-pill text-center'>
										<h5 className='card-title'>
											<i className={`${content.icon} me-2`}></i>
											{content.head}
										</h5>
										{/* <p className='card-text'>{content.body}</p> */}
									</div>
								</div>
							</div>
						);
					})}
				</div>
			</div>
		</section>
	);
}
