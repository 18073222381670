import React from 'react';
import Headings from '../../../utils/Headings';

export default function Kendras() {
	return (
		<>
			<section className='mb-5 py-5' id="kendras">
				<div className='container'>
					<div className='mb-5 mx-auto' style={{ maxWidth: '800px' }}>
						<Headings>Bhavan's Kendras</Headings>
						<p className='text-center'>
							Bhavans has established more than (so many Kendra) all over the
							country and the prime aim of doing so is to spread the Indian
							culture &amp; ethos through its programs.
						</p>
					</div>
					<div className='row gap-0'>
						<div className='col-md-6 p-5 alert-primary'>
							<h3 className='mb-4' align='left' border='full'>
								Bhavan's Kala Kendra
							</h3>
							<p>
								Kala Kendra started in 1948 is the Cultural and Art wing of
								Bharatiya Vidya Bhavan devoted to the development and
								presentation of Indian Dance, Music, Literature and other
								Performing Arts. Bharatiya Vidya Bhavan has been a temple of
								cultural activities.
							</p>
							<p>
								Kala Kendra develops and promotes amateur talents in various
								fields such as Drama, Dance, Music, Children’s Theatre and
								Experimental Theatre.
							</p>
							<p>
								Kala Kendra organizes cultural programs of various kinds, as
								well as arranges lectures cum demonstrations on the various art
								forms. As a Production House, Kala Kendra also reinforces the
								importance of ‘Indian Literature’.
							</p>
						</div>
						<div className='col-md-6 p-0'>
							<img
								src={`${process.env.PUBLIC_URL}/media/introduction/kala_kendra.jpg`}
								alt=''
								style={{ height: '100%', width: '100%', objectFit: 'cover' }}
							/>
						</div>
					</div>
				</div>
			</section>
			<section className='mb-5 pb-5'>
				<div className='container p-5 mb-5'>
					<Headings className='mb-4'>BVB Navi Mumbai Kendra</Headings>
					<div className='mx-auto mb-5' style={{ maxWidth: '800px' }}>
						<p className='text-center'>
							The latest entrant in the family of BVB is its new generation
							Centre at Vashi.
						</p>
						<p className='text-center'>
							This Centre is poised to be the next generation entity of BVB
							catering to the cultural and educational needs of Navi Mumbai and
							beyond. Besides educational activities, the Navi Mumbai Kendra
							will focus on Children Club, Yoga and Related Activities and a
							Cultural Club. The Centre is likely to become one of the hubs of
							education, spiritual, fine arts and cultural activities in the
							satellite city of Navi Mumbai.
						</p>
					</div>
					<div className=''>
						<img
							src={`${process.env.PUBLIC_URL}/media/introduction/navi_mumbai_kendra.jpg`}
							alt=''
							style={{
								height: '100%',
								maxHeight: '600px',
								width: '100%',
								objectFit: 'cover',
								objectPosition: 'center -40px',
							}}
							className='mx-auto'
						/>
					</div>
				</div>
			</section>
		</>
	);
}
