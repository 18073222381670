import React from 'react';

export default function FinancialServices() {

	return (
		<>
		<section className='mb-4' style={{ position: 'relative' }}>
				<img
					//src={`${process.env.PUBLIC_URL}/media/home/campus.png`}
					src={`${process.env.PUBLIC_URL}/media/home/campus.png`}
					alt=''
					style={{
						width: '100%',
						maxHeight: '150px',
						objectFit: 'cover',
						objectPosition: 'center center',
						filter: 'brightness(.3)',
					}}
				/>
				<div
					className='text-center text-white'
					style={{
						position: 'absolute',
						top: '50%',
						left: '50%',
						transform: 'translate(-50%,-50%)',
					}}
				>
					<h1>School Of Financial Services</h1>
				</div>
		</section>
		<section className='mb-5 py-2'>
			<div className='container'>
				{/* <h1 className='text-center mb-5'>School Of Financial Services</h1> */}
				<div className='mx-auto clearfix text-justify' style={{ maxWidth: '1000px' }}>
				{/* <nav className="navbar navbar-expand-sm justify-content-center mb-2">
				  <div className="container-fluid">
				    <a className="navbar-brand" href="javascript:void(0)"><img src={`${process.env.PUBLIC_URL}/media/sof.png`} alt="Logo" width='250px' /></a>
				    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#mynavbar1">
				      <span className="fa fa-align-justify"></span>
				    </button>
				    <div className="collapse navbar-collapse" id="mynavbar1">
				      <ul className="navbar-nav me-auto mx-auto">
				        <li className="nav-item">
				          <a className={`btn btn-outline-info me-2 nax ${(navType===1)?'active':''}`} href="javascript:void(0)"  onClick={()=> operateNav(1)}>Know Us</a>
				        </li>
						<li className="nav-item">
				          <a className={`btn btn-outline-info me-2 nax ${(navType===2)?'active':''}`} href="javascript:void(0)"  onClick={()=> operateNav(2)}>Courses</a>
				        </li>
						<li className="nav-item">
				          <a className={`btn btn-outline-info me-2 nax ${(navType===3)?'active':''}`} href="javascript:void(0)"  onClick={()=> operateNav(3)}>Activities</a>
				        </li>
						<li className="nav-item">
				          <a className={`btn btn-outline-info me-2 nax ${(navType===4)?'active':''}`} href="javascript:void(0)"  onClick={()=> operateNav(4)}>Contact Us</a>
				        </li>
						<li className="nav-item">
				          <a className={`btn btn-outline-info me-2 nax ${(navType===5)?'active':''}`} href="javascript:void(0)"  onClick={()=> operateNav(5)}>Photo Gallery</a>
				        </li>
				      </ul>
				    </div>
				  </div>
				</nav> */}
				</div>
				
			</div>
		</section>
		</>
	);
}
