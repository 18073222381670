import React from 'react';
import { Link } from 'react-router-dom';
import { programHighlights } from './constants';

export default function SkillPrograms() {
	return (
		<section className='mb-5 py-5'>
			<div className='container'>
				<div className='mb-5'>
					<h1 className='mb-5 text-center'>SBP (Skill Based Programs)</h1>
					<dl className='row'>
						<dt className='col-sm-2 offset-xl-1'>
							About Skill Based Programs:
						</dt>
						<dd className='col-sm-10 col-xl-8 text-justify'>
							<p>
								BVB KMMIAS will cater to the emerging skill needs of the 21st
								Century both in terms of global This program aims to meet unique
								educational needs in their areas of interest. It also develops
								emerging skill sets and knowledge of the growing world with
								latest technologies
							</p>
						</dd>
						<dt className='col-sm-2 offset-xl-1'>Program Duration:</dt>
						<dd className='col-sm-10 col-xl-8 text-justify'>
							<p>
								These will be industry focused short duration courses which will
								last 3 to 6 months
							</p>
						</dd>
						<dt className='col-sm-2 offset-xl-1'>Program Highlights:</dt>
						<dd className='col-sm-10 col-xl-8'>
							<ul className='fa-ul' style={{ marginLeft: '1.5em' }}>
								{programHighlights.map((program, index) => {
									return (
										<li key={index}>
											<i className='fas fa-check fa-li text-success'></i>
											{program}
										</li>
									);
								})}
							</ul>
						</dd>
						<dt className='col-sm-2 offset-xl-1'>Certifications:</dt>
						<dd className='col-sm-10 col-xl-8'>
							<p>
								On successfully completing the program, participants at the end
								of the program will get certification from BVB’s K M Munshi
								Institute of Advanced Studies.
							</p>
						</dd>
						<dt className='col-sm-2 offset-xl-1'>Resources:</dt>
						<dd className='col-sm-10 col-xl-8'>
							<div className='d-flex'>
								{/*<a
									href='/media/brochure/brochure.pdf'
									download='brochure.pdf'
									className='btn btn-primary me-3'
								>
									Download Brochure
									<i className='fas fa-file-download ms-1'></i>
								</a>*/}
								<Link
									to={{ pathname: 'https://admissions.kmmias.org/register' }}
									target='_blank'
									className='btn btn-primary'
								>
									Apply Now
									<i className='fas fa-external-link-alt ms-1'></i>
								</Link>
							</div>
						</dd>
					</dl>
				</div>
			</div>
		</section>
	);
}
