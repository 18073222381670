import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import UnderConstruction from '../UnderConstruction';
import AimaTieUp from './aima/AimaTieUp';
import AboutBvb from './bvb/AboutBvb';
import Awards from './bvb/Awards';
import Kendras from './bvb/Kendras';
import Ffoi from './ffoi/ffoi';
import Isaca from './isaca/isaca';
// import NewsFromKendra from './bvb/NewsFromKendra';
import AboutKmmias from './kmmias/AboutKmmias';
import AdvisoryComittee from './kmmias/AdvisoryComittee';
import MessageFromDirector from './kmmias/MessageFromDirector';
import Usp from './usp/Usp';

export default function IntroductionRoutes() {
	return (
		<>
			<section className='mb-4' style={{ position: 'relative' }}>
				<img
					src={`${process.env.PUBLIC_URL}/media/home/campus.png`}
					alt=''
					style={{
						width: '100%',
						maxHeight: '150px',
						objectFit: 'cover',
						objectPosition: 'center center',
						filter: 'brightness(.3)',
					}}
				/>
				<div
					className='text-center text-white'
					style={{
						position: 'absolute',
						top: '50%',
						left: '50%',
						transform: 'translate(-50%,-50%)',
					}}
				>
					<h1>Introduction</h1>
				</div>
			</section>
			{/* <section className='mb-5'>
				<div className='container'>
					<nav>
						<ol className='breadcrumb'>
							<li className={`breadcrumb-item text-uppercase`}>
								<Link to={`/`} className='text-info'>
									home
								</Link>
							</li>
							{makeBreadcrumb(location.pathname)}
						</ol>
					</nav>
				</div>
			</section> */}
			<Switch>
				<Redirect exact from='/introduction' to='/introduction/bvb' />
				<Redirect exact from='/introduction/bvb' to='/introduction/bvb/about' />
				<Redirect
					exact
					from='/introduction/kmmias'
					to='/introduction/kmmias/about'
				/>
				{/* bvb */}
				<Route exact path='/introduction/bvb/about' component={AboutBvb} />
				<Route exact path='/introduction/bvb/kendras' component={Kendras} />
				<Route exact path='/introduction/bvb/awards' component={Awards} />
				{/* <Route exact path='/introduction/bvb/news' component={NewsFromKendra} /> */}
				{/* kmmias */}
				<Route
					exact
					path='/introduction/kmmias/about'
					component={AboutKmmias}
				/>
				<Route
					exact
					path='/introduction/kmmias/message_director'
					component={MessageFromDirector}
				/>
				<Route
					exact
					path='/introduction/kmmias/team'
					component={AdvisoryComittee}
				/>
				{/* aima */}
				<Route exact path='/introduction/aima' component={AimaTieUp} />
				<Route exact path='/introduction/isaca' component={Isaca} />
				<Route exact path='/introduction/rise' component={UnderConstruction} />
				<Route exact path='/introduction/ffoi' component={Ffoi} />
				{/* usp */}
				<Route exact path='/introduction/usp' component={Usp} />
				<Route component={UnderConstruction} />
			</Switch>
		</>
	);
}
