import React from 'react';
import Headings from '../../../utils/Headings';

export default function Eligibility() {
	return (
		<section className='my-5 py-5'>
			<div className='container'>
				<Headings className='mb-5'>Eligibility</Headings>
				<div className='mx-auto' style={{ maxWidth: '1000px' }}>
					<div className='mb-5'>
						<h5 className='mb-2'>PGDM</h5>
						<div className='d-flex align-items-center'>
							<img
								src={`${process.env.PUBLIC_URL}/media/courses/enterpreneurship.jpg`}
								alt=''
								style={{ width: '150px' }}
								className='me-3'
							/>
							<p>
								The PGDM program is specially designed for fresh graduates,
								working professionals, individuals from business families,
								managers of start-ups, entrepreneurs and graduates who plan to
								work after they pursue PGDM. All candidates should have minimum
								graduate degree in any discipline from a UGC recognized
								University or equivalent.
							</p>
						</div>
					</div>
					<div className=''>
						<h5 className='mb-2'>PGDM (E)</h5>
						<div className='d-flex align-items-center'>
							<img
								src={`${process.env.PUBLIC_URL}/media/courses/pgdme.jpg`}
								alt=''
								style={{ width: '150px' }}
								className='me-3'
							/>
							<p>
								The PGDM Executive program is specially designed for working
								professionals, individuals from business families, founders /
								managers of start-ups, entrepreneurs and graduates who plan to
								work while they pursue management degree. They should have
								minimum 5 years of work experience in supervisory / managerial
								position.
							</p>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}
