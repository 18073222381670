import React from 'react';

export default function AdvisoryComittee() {
	const academicAdvisors = [
		{
			name: 'Prof Kulkarni',
			desc: 'Retired member of S P Jain Institute of Management and Research',
		},
		{
			name: 'Prof Dr M S Rao',
			desc: 'Retired member of S P Jain Institute of Management and Research',
		},
		{
			name: 'Mr R Kannan',
			desc: 'Head,Corporate Performance Management, Hinduja Group, Mumbai',
		},
		{
			name: 'Dr R M Chitnis',
			desc: 'Pro Vice Chancellor, MIT World Peace University, Pune',
		},
		{
			name: 'Dr Sanjay Muthal',
			desc: 'Founder and CEO, Konempore Leadership & Business Solutions, Mumbai',
		},
		{
			name: 'Prof Shanthi Rajan',
			desc: 'Director, Institution Development, University of Stirling, UAE',
		},
		{
			name: 'Mr Thothathri Raman',
			desc: 'Chairman and Founder, Standard for Educational Advancement & Accreditation (SEAA) Trust, Delhi',
		},
	];
	// const corporateAdvisors = [
	// 	{
	// 		name: 'Mr Kartik Sharma',
	// 		desc: '',
	// 	},
	// 	{
	// 		name: 'Mr Vilas Tawde',
	// 		desc: '',
	// 	},
	// 	{
	// 		name: 'Mr Rajeev Vaishnav',
	// 		desc: '',
	// 	},
	// 	{
	// 		name: 'Mr Anil Khanna',
	// 		desc: '',
	// 	},
	// 	{
	// 		name: 'Mr Navin Goel',
	// 		desc: '',
	// 	},
	// 	{
	// 		name: 'Mr Bigna Nayak',
	// 		desc: '',
	// 	},
	// 	{
	// 		name: 'Mr KrishnaKant Thakre',
	// 		desc: '',
	// 	},
	// 	{
	// 		name: 'Adv Balwal',
	// 		desc: '',
	// 	},
	// 	{
	// 		name: 'Mr Shijju',
	// 		desc: '',
	// 	},
	// 	{
	// 		name: 'Mr Deeraj Mittal',
	// 		desc: '',
	// 	},
	// 	{
	// 		name: 'Mr Krishnamurty',
	// 		desc: '',
	// 	},
	// 	{
	// 		name: 'Adv Prasad Dhakephalkar',
	// 		desc: '',
	// 	},
	// ];

	const corporateAdvisors = [{
		name : 'Mr. Vilas Tawde',
		desc : 'Retired Chairman and Managing Director of Essar Oil and Gas.'
	},{
		name : 'Mr. Rajeev Vaishnav',
		desc : 'Ex Executive head of NASCOM.'
	},{
		name : 'Mr. Navin Goel',
		desc : 'Global CEO of Sogetti / Capgemini Ohio USA'
	},{
		name : 'Mr. Bigna Nayak',
		desc : 'Retired Manging Director of Fugro India ltd.'
	},{
		name : 'Mr. KrishnaKant Thakre',
		desc : 'Senior VP and head of Patalganga plant Reliance Industries Ltd.'
	},{
		name : 'Adv. Balwal',
		desc : 'Advocate High court and Supreme Court. District governor Lions Club Navi Mumbai.'
	},{
		name : 'Mr. Shijju',
		desc : 'Ex CTO of CIBIL / IIFL / Poonawala group and current CIO of Care Rating.'
	},{
		name : 'Mr. Deeraj Mittal',
		desc : 'CIO of Hiranandani Finance ltd.'
	},{
		name : 'Mr. Krishnamurty',
		desc : 'Managing Director of Tamilnadu Industrial Financial ltd.'
	},{
		name : 'Adv. Prasad Dhakephalkar',
		desc : 'Advocate High court and Supreme Court and President of Mumbai High court BAR association for 20 \+ years.'
	}];

	const chairPersons = [{
		name:'School of Skills',
		desc:'Chairperson. Mr N Jambunathan'
	},{
		name:'School of Finance',
		desc:'Chairperson. Mr Deepak Kumar Verma'
	},{
		name:'School of Strategy & Planning',
		desc:'Chairperson. Dr Keshab Nandy'
	},{
		name:'School of Corporate Compliance',
		desc:'Chairperson. Adv Radhika Dudhat'
	},{
		name:'School of Financial Services',
		desc:'Chairperson. Amit Trivedi'
	},{
		name:'School of IT',
		desc:'Chairperson. Harish Aiyer'
	},{
		name:'School of leadership',
		desc:'Chairperson. Prof Dr Shailaja Karve'
	}];


	// const subjectMatterExperts = [];
	// const culturalDirector = [];
	return (
		<section className='mb-5 py-5'>
			<div className='container'>
				<h1 className='mb-4'>Team KMMIAS</h1>
				<p className='mb-4'>
					BVB KMMIAS has a strong pedigree and high degree of relationship with
					corporates,academia,subject matter experts &amp; . Best of the faculty
					would be drawn from the academic world as well as corporate. The mix
					will provide the right theoretical inputs as well as practical acumen
					to the participants so that they become the right fit for the
					corporate world. There will be interaction with CEOs, CXOs,
					Entrepreneurs and Social Activists on a regular basis so that there is
					holistic grooming of the participants.
				</p>
				<div
					className='accordion accordion-custom mb-4'
					id='academic_accordion'
				>
					<div className='accordion-item border-0'>
						<h4 className='accordion-header'>
							<button
								className='accordion-button shadow-none fs-5'
								type='button'
								data-bs-toggle='collapse'
								data-bs-target='#academic_collapse'
							>
								<i className='fas fa-user-graduate me-2'></i>Academic Advisors
							</button>
						</h4>
						<div
							id='academic_collapse'
							className='accordion-collapse collapse show'
						>
							<div className='accordion-body'>
								<ol className=''>
									{academicAdvisors.map((advisor, index) => {
										return (
											<li key={index}>
												<h5>{advisor.name}</h5>
												{advisor.desc && <p>{advisor.desc}</p>}
											</li>
										);
									})}
								</ol>
							</div>
						</div>
					</div>
				</div>

				<div
					className='accordion accordion-custom mb-4'
					id='chairpersons'
				>
					<div className='accordion-item border-0'>
						<h4 className='accordion-header'>
							<button
								className='accordion-button collapsed shadow-none fs-5'
								type='button'
								data-bs-toggle='collapse'
								data-bs-target='#chair_persons'
							>
								<i className='fas fa-user-tie me-2'></i>Chairpersons of Schools of KMMIAS
							</button>
						</h4>
						<div
							id='chair_persons'
							className='accordion-collapse collapse'
						>
							<div className='accordion-body'>
								<ol className=''>
									{chairPersons.map((person, index) => {
										return (
											<li key={index}>
												<h5>{person.name} - {person.desc && person.desc}</h5>
												
											</li>
										);
									})}
								</ol>
							</div>
						</div>
					</div>
				</div>

				<div
					className='accordion accordion-custom mb-4'
					id='academic_accordion'
				>
					<div className='accordion-item border-0'>
						<h4 className='accordion-header'>
							<button
								className='accordion-button collapsed shadow-none fs-5'
								type='button'
								data-bs-toggle='collapse'
								data-bs-target='#corporate_collapse'
							>
								<i className='fas fa-user-tie me-2'></i>Corporate CXO Club
							</button>
						</h4>
						<div
							id='corporate_collapse'
							className='accordion-collapse collapse'
						>
							<div className='accordion-body'>
								<ol className=''>
									{corporateAdvisors.map((advisor, index) => {
										return (
											<li key={index}>
												<h5>{advisor.name}</h5>
												{advisor.desc && <p>{advisor.desc}</p>}
											</li>
										);
									})}
								</ol>
							</div>
						</div>
					</div>
				</div>

				
				{/* <div
					className='accordion accordion-custom mb-4'
					id='academic_accordion'
				>
					<div className='accordion-item border-0'>
						<h4 className='accordion-header'>
							<button
								className='accordion-button collapsed shadow-none fs-5'
								type='button'
								data-bs-toggle='collapse'
								data-bs-target='#subject_matter_experts'
							>
								<i className='fas fa-user-cog me-2'></i>Subject Matter Experts
							</button>
						</h4>
						<div
							id='subject_matter_experts'
							className='accordion-collapse collapse'
						>
							<div className='accordion-body'>
								<ol className=''>
									{subjectMatterExperts.map((advisor, index) => {
										return (
											<li key={index}>
												<h5>{advisor.name}</h5>
												{advisor.desc && <p>{advisor.desc}</p>}
											</li>
										);
									})}
								</ol>
							</div>
						</div>
					</div>
				</div>
				<div
					className='accordion accordion-custom mb-4'
					id='academic_accordion'
				>
					<div className='accordion-item border-0'>
						<h4 className='accordion-header'>
							<button
								className='accordion-button collapsed shadow-none fs-5'
								type='button'
								data-bs-toggle='collapse'
								data-bs-target='#cultural_director'
							>
								<i className='fas fa-users me-2'></i>Cultural Director
							</button>
						</h4>
						<div id='cultural_director' className='accordion-collapse collapse'>
							<div className='accordion-body'>
								<ol className=''>
									{culturalDirector.map((advisor, index) => {
										return (
											<li key={index}>
												<h5>{advisor.name}</h5>
												{advisor.desc && <p>{advisor.desc}</p>}
											</li>
										);
									})}
								</ol>
							</div>
						</div>
					</div>
				</div> */}
			</div>
		</section>
	);
}
