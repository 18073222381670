import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import ReCAPTCHA from 'react-google-recaptcha';
import axios from 'axios';
import { render } from 'react-dom';
import { useModal } from 'react-hooks-use-modal';


export default function Stp() {

	const [alertType, setalertType] = useState('info');
	const [imageFile, setimageFile] = useState('');
	const [initialValues, setinitialValues] = useState({
		name: '',
		gender:'',
		email: '',
		phone:'',
		stream:'',
		sort_term_course:''
	});

	const validationSchema = Yup.object().shape({
		name: Yup.string().required().label('Name'),
		gender: Yup.string().required().label('Gender'),
		email: Yup.string().email().required().label('Email'),
		phone: Yup.string().required().label('Mobile'),
		stream: Yup.string().required().label('Stream'),
		sort_term_course: Yup.string().required().label('Short Term Course'),
		responseKey: Yup.string()
		.required('Please verify you are not a robot')
		.label('Captcha'),
	});

	const formik = useFormik({
		initialValues,
		validationSchema,
		onSubmit: (values, { setStatus, setErrors }) => {
			let k = JSON.parse(JSON.stringify(values))
			var formData = new FormData();
			for (const [key, value] of Object.entries(k)) {
				formData.append(key, value);
			}
			return axios
				.post(`${process.env.REACT_APP_BASE_API_URL}/registerstp`,formData)
				.then((res) => {
					formik.handleReset();
					setalertType('success');
					setStatus(
						'Details saved successfully, our team will contact you shortly'
					);
				})
				.catch((err) => {
					if (err?.response?.data?.errors) {
						setErrors(err.response.data.errors);
					} else {
						setalertType('danger');
						setStatus(err?.response?.data?.message);
					}
				});
		},
	});

	return (
		<section className='mb-5 py-5'>
			<div className='container'>
				<div className='mb-5 mx-auto clearfix text-justify' style={{ maxWidth: '800px' }}>
					<h1 className='mb-5 text-center'>Short Term Programs</h1>
					<p>
					Post Graduate Certificate PGC [Short Term, Industry recognized with Paid Internships]
					</p>
					<p>1. Post Graduate Certificate program in Retail Management</p>
					<p>2. Post Graduate Certificate program in Financial Services</p>
					<p>BVB KMMIAS is launching one of the unique job oriented skill programs in collaboration with leading companies. Selected students will undergo 6 months paid internship with the company. They will also concurrently participate in the certification program from KMMIAS. On successful completion of course and internship, students will be absorbed in the company on full time basis. Participants will also get Post Graduate certificate from BVB KMMIAS.</p>
				</div>
			</div>
			<div className='container'>
				<form className='col-md-6  offset-xl-3 col-sm-12' id='form' onSubmit={formik.handleSubmit}>
				<div className="row">
					<div className='col-sm-12 col-xl-12 text-center'>
						<h4>Registration Form</h4>
					</div>
					<div className='col-sm-12 col-xl-12 text-justify'>
						{formik.status!=null && (
							<div className={`alert alert-${alertType}`} role='alert'>
								{formik.status}
							</div>
						)}
					</div>
					<div className='col-sm-12 col-xl-12 text-justify'>
						<label className='form-label' htmlFor='name'>
								Full Name
							</label>
							<input
								type='text'
								className={`form-control ${
									formik.errors.name && formik.touched.name && 'is-invalid'
								}`}
								{...formik.getFieldProps('name')}
							/>
							{formik.errors.name && formik.touched.name && (
								<div className='invalid-feedback'>{formik.errors.name}</div>
							)}
					</div>
					<div className='col-sm-12 col-xl-12 text-justify'>
						<label className='form-label' htmlFor='gender'>
								Gender
							</label>
							<select
								className={`form-control ${
									formik.errors.gender && formik.touched.gender && 'is-invalid'
								}`}
								{...formik.getFieldProps('gender')}
							>
								<option value="">Select Gender</option>
								<option value="male">Male</option>
								<option value="female">Female</option>
							</select>
							{formik.errors.gender && formik.touched.gender && (
								<div className='invalid-feedback'>{formik.errors.gender}</div>
							)}
					</div>
					<div className='col-sm-12 col-xl-12 text-justify'>
						<label className='form-label' htmlFor='email'>
								Email
							</label>
							<input
								type='email'
								className={`form-control ${
									formik.errors.email && formik.touched.email && 'is-invalid'
								}`}
								{...formik.getFieldProps('email')}
							/>
							{formik.errors.email && formik.touched.email && (
								<div className='invalid-feedback'>{formik.errors.email}</div>
							)}
					</div>
					<div className='col-sm-12 col-xl-12 text-justify'>
						<label className='form-label' htmlFor='mobile'>
								Mobile
							</label>
							<input
								type='text'
								className={`form-control ${
									formik.errors.phone && formik.touched.phone && 'is-invalid'
								}`}
								{...formik.getFieldProps('phone')}
							/>
							{formik.errors.phone && formik.touched.phone && (
								<div className='invalid-feedback'>{formik.errors.phone}</div>
							)}
					</div>
					<div className='col-sm-12 col-xl-12 text-justify'>
						<label className='form-label' htmlFor='stream'>
								Stream
							</label>
							<textarea
								className={`form-control ${
									formik.errors.stream && formik.touched.stream && 'is-invalid'
								}`}
								{...formik.getFieldProps('stream')}
							/>
							{formik.errors.stream && formik.touched.stream && (
								<div className='invalid-feedback'>{formik.errors.stream}</div>
							)}
					</div>
					<div className='col-sm-12 col-xl-12 text-justify'>
						<label className='form-label' htmlFor='stream'>
								Short Term Course
							</label>
							<select
								className={`form-control ${
									formik.errors.sort_term_course && formik.touched.sort_term_course && 'is-invalid'
								}`}
								{...formik.getFieldProps('sort_term_course')}
							>
								<option value="">Select Course</option>
								<option value="The Retail management">The Retail management executive program</option>
								<option value="The Financial services">The Financial services executive program</option>
								<option value="The Invester education">The Invester education program</option>
								<option value="The Cybersecurity">The Cybersecurity program</option>
							</select>
							{formik.errors.sort_term_course && formik.touched.sort_term_course && (
								<div className='invalid-feedback'>{formik.errors.sort_term_course}</div>
							)}
					</div>
					<div className='col-sm-12 col-xl-12 text-center pt-4 pb-4'>
							<ReCAPTCHA
								sitekey={process.env.REACT_APP_GOOGLE_CAPTCHA_SITEKEY}
								onChange={(val) => formik.setFieldValue('responseKey', val)}
								onExpired={() => formik.setFieldValue('responseKey', '')}
							/>
					</div>
					<div className='col-sm-12 col-xl-12 text-center'>
						{formik.errors.responseKey && (
							<div className='text-danger text-center'>
								{formik.errors.responseKey}
							</div>
						)}
					</div>
					<div class="d-grid gap-2">
						<button className='btn btn-success btn-block mt-3' disabled={formik.isSubmitting}>
							{formik.isSubmitting && (
								<span
									className='spinner-border spinner-border-sm'
									role='status'
									aria-hidden='true'
								></span>
							)}
							Submit <i className='fas fa-save'></i>
						</button>
					</div>
				  </div>
				</form>
			</div>
		</section>
	);
}
