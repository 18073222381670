import React from 'react';
import Headings from '../../../../utils/Headings';

export default function AboutBhavans() {
	return (
		<section className='mb-5 py-5' id='about_bhavans'>
			<div className='container'>
				<div className='row g-0'>
					<div className='col-lg-6'>
						<img
							src={`${process.env.PUBLIC_URL}/media/introduction/about_bhavans.jpg`}
							alt=''
							style={{ width: '100%', height: '100%', objectFit: 'cover' }}
						/>
					</div>
					<div className='col-lg-6 p-3 p-lg-5 d-flex flex-column justify-content-center'>
						<Headings className='mb-3' align='left' border='full'>
							About Bhavans
						</Headings>
						<p>
							Bharatiya Vidya Bhavan (BVB) has been one of the oldest and most
							cherished cultural and educational Institutes in the country. It
							was founded in 1938 by Kulapati Dr K M Munshi, a freedom fighter
							and educational leader, with blessings from Mahatma Gandhi. The
							motto of Bhavans is: “Let noble thoughts come to us from every
							side”, a quote from Rigveda. From humble beginning, the Bhavans
							has today grown into a comprehensive all-India intellectual,
							cultural and educational movement with 119 Centres all over India,
							7 Centres abroad and a large number of educational institutions
							imparting management, technical, college and school education to
							students at various levels. Many other leading institutions are
							there under the banner of Bharatiya Vidya Bhavan in different
							parts of the country.
						</p>
					</div>
				</div>
			</div>
		</section>
	);
}
