import React from 'react';

export default function Ffoi() {
	return (
		<section className='mb-5 py-5'>
			<div className='container'>
				<div
					className='mb-5 mx-auto clearfix text-justify'
					style={{ maxWidth: '800px' }}
				>
				<h1 className='text-center mb-5'>FFOI Certification</h1>
				<p>Finance Forum of India FFOI has developed special Skills Development program for those aspiring to be in the Accounts department of the companies these programs will be of about 45 hours duration and will give extensive skills for day to day functioning and success as accountants.</p>
				</div>
			</div>
		</section>
	);
}
