import React from 'react';
import Headings from '../../../utils/Headings';

export default function TeachingMethodology() {
	return (
		<section className='my-5 py-5'>
			<div className='container'>
				<Headings className='mb-5'>Teaching Methodology</Headings>

				<div
					style={{
						backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),url(${process.env.PUBLIC_URL}/media/home/achievements.jpg)`,
						padding: '5rem 0',
						position: 'relative',
					}}
				>
					<div className='mx-auto' style={{ width: '90%', maxWidth: '800px' }}>
						<div
							className='bg-white p-4'
							style={{ border: '10px solid #be7d0d' }}
						>
							<ul className='fa-ul fs-5'>
								<li className='mb-2'>
									<i className='fas fa-pencil-alt fa-li pt-1'></i>
									Accent on self-learning
								</li>
								<li className='mb-2'>
									<i className='fas fa-pencil-alt fa-li pt-1'></i>
									Challenging academic assignments
								</li>
								<li className='mb-2'>
									<i className='fas fa-pencil-alt fa-li pt-1'></i>
									Student centered and discovery methods of learning
								</li>
								<li className='mb-2'>
									<i className='fas fa-pencil-alt fa-li pt-1'></i>
									Peer to peer learning
								</li>
								<li className='mb-2'>
									<i className='fas fa-pencil-alt fa-li pt-1'></i>
									State-of-the-art technology
								</li>
								<li className='mb-2'>
									<i className='fas fa-pencil-alt fa-li pt-1'></i>
									Flipped classroom
								</li>
								<li className='mb-2'>
									<i className='fas fa-pencil-alt fa-li pt-1'></i>
									Case analysis
								</li>
								<li className='mb-2'>
									<i className='fas fa-pencil-alt fa-li pt-1'></i>
									Simulation games and exercises
								</li>
								<li className='mb-2'>
									<i className='fas fa-pencil-alt fa-li pt-1'></i>
									Syndicates group discussions and practical project work
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}
