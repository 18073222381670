import React from 'react';

export default function AimaTieUp() {
	return (
		<section className='mb-5 py-5'>
			<div className='container'>
				<h1 className='text-center mb-5'>AIMA Tie-up</h1>
				<div
					className='mb-5 mx-auto clearfix text-justify'
					style={{ maxWidth: '800px' }}
				>
					<h5 className='mb-4'>
						Background of All India Management Association (AIMA)
					</h5>
					<img
						src={`${process.env.PUBLIC_URL}/media/aima/aima_logo.png`}
						alt=''
						style={{ width: '100%', maxWidth: '180px', objectFit: 'contain' }}
						className='float-end ms-4 mb-3'
					/>
					<p>
						AIMA is the apex body of Management Professionals in India.
						Established in 1957, AIMA operates through 67 Local Management
						Associations in different parts of the country and also Qatar Indian
						Management Association and Mauritius Management Association. Located
						in Delhi, the prime objective of AIMA is to further the cause of
						Management Profession in India.
					</p>
					<p>
						AIMA is involved in various capacity building activities for
						management professionals in India through various academic programs
						including a doctoral program and training activities and promotion
						of research and consultancy in areas related to management and
						management education.
					</p>
					<p>
						AIMA conducts the Management Aptitude Test (MAT), the entrance test
						to enter PGDM programs of premier institutions. This test is used by
						more than 600 institutions in the country.
					</p>
					<p>
						AIMA is also represented on governing bodies of premier all India
						institutions like All India Council for Technical Education (AICTE),
						National Board of Accreditation (NBA), etc.
					</p>
				</div>
				<div
					className='mb-5 mx-auto clearfix text-justify'
					style={{ maxWidth: '800px' }}
				>
					<h5 className='mb-4'>Centre for Management Education (CME)</h5>
					<img
						src={`${process.env.PUBLIC_URL}/media/aima/aima_logo.png`}
						alt=''
						style={{ width: '100%', maxWidth: '180px', objectFit: 'contain' }}
						className='float-end ms-4 mb-3'
					/>
					<p>
						Under the aegis of CME, AIMA offers Post-Graduate Management
						Programs of different types. These programs are highly coveted by
						industry because of their relevance. These programs are approved by
						All India Council for Technical Education (AICTE) under ODL mode.
					</p>
					<p>The two unique programs in this context are:</p>
					<ol style={{ listStyleType: 'lower-alpha' }}>
						<li>
							Post-Graduate Diploma in Management (PGDM) of 24 months duration
							for fresh graduates, working professionals, entrepreneurs, owners
							of MSMEs. This will be offered in functional specializations.
						</li>
						<li>
							Post-Graduate Diploma in Management (Executive) (PGDM(E)) of 15
							months duration for working professionals, entrepreneurs, owners
							of MSMEs with minimum 5 years of work experience after graduation.
						</li>
					</ol>
				</div>
				<div
					className='mb-5 mx-auto clearfix text-justify'
					style={{ maxWidth: '800px' }}
				>
					<h5 className='mb-4'>
						Role of Bharatiya Vidya Bhavan’s K M Munshi Institute of Advanced
						Studies
					</h5>
					<img
						src={`${process.env.PUBLIC_URL}/media/bhavans.png`}
						alt=''
						style={{ width: '100%', maxWidth: '180px', objectFit: 'contain' }}
						className='float-end ms-4 mb-3'
					/>
					<p>
						BVB KMMIAS has partnered with AIMA to run the above two prestigious
						and coveted management programs at its Vashi campus on behalf of
						AIMA. In addition to the PGDM programs by AIMA, BVB KMMIAS will also
						provide its separate certifications to the admitted students as
						described in the program brochure.
					</p>
				</div>
			</div>
		</section>
	);
}
